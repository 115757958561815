import { Drawer } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import routes from "../../../routes";
import { NavLink } from "react-router-dom";
import { CompanyContactFooter } from "../Sidebar/CompanyContactFooter";

const Wrapper = styled.div`
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 40px;
  box-sizing: border-box;
  z-index: 100000;
  top: 0;
  background: white;
`;

const DrawerWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const StyledNavLink = styled(NavLink)`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  user-select: none;
  outline: unset;
  border: unset;
  line-height: 16px;

  color: #1b607b;
  padding-top: 20px;
  text-decoration: none;

  &.active {
    color: #33addc;
    user-select: none;
    outline: unset;
    border: unset;
  }
`;

function Menu({}: IMenu) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Wrapper>
        <img
          alt={"logo alpha"}
          width={166}
          height={40}
          src={"/static/img/logo_alpha.png"}
        />
        {open ? (
          <img
            onClick={(e) => setOpen(false)}
            alt={"x"}
            src={"/static/icon/x_big.png"}
          />
        ) : (
          <img
            onClick={(e) => setOpen(true)}
            alt={"ham"}
            src={"/static/icon/ham.png"}
          />
        )}
      </Wrapper>
      <Drawer anchor={"top"} open={open} onClose={(e) => setOpen(false)}>
        <DrawerWrapper>
          {routes
            .filter((r) => "label" in r)
            .map((route) => (
              <StyledNavLink onClick={() => setOpen(false)} to={route.to}>
                {route.label}
              </StyledNavLink>
            ))}
          <StyledNavLink onClick={() => setOpen(false)} to={"/panel/ecommerce"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                style={{ marginRight: "8px" }}
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.8 14.7H4.70005C4.50005 14.7 4.30005 14.6 4.20005 14.3L1.30005 4.99999C1.30005 4.79999 1.30005 4.69999 1.40005 4.59999C1.50005 4.49999 1.60005 4.39999 1.80005 4.39999H21.4C21.6 4.39999 21.7 4.49999 21.8 4.59999C21.9 4.69999 21.9 4.89999 21.9 4.99999L19.2 14.3C19.2 14.5 19 14.7 18.8 14.7ZM5.10005 13.7H18.4L20.8 5.39999H2.60005L5.10005 13.7Z"
                  fill="#1B607B"
                />
                <path
                  d="M18.7 17.3H5.5C5.3 17.3 5.1 17.2 5 16.9L0 0.599998C0 0.399998 3.7998e-07 0.299997 0.1 0.199997C0.2 0.0999969 0.3 0 0.5 0H3.3C3.6 0 3.8 0.2 3.8 0.5C3.8 0.8 3.6 1 3.3 1H1.2L5.9 16.3H18.7C19 16.3 19.2 16.5 19.2 16.8C19.2 17.1 19 17.3 18.7 17.3Z"
                  fill="#1B607B"
                />
                <path
                  d="M7.4 21.6C6.3 21.6 5.5 20.7 5.5 19.7C5.5 18.7 6.4 17.8 7.4 17.8C8.5 17.8 9.3 18.7 9.3 19.7C9.3 20.7 8.5 21.6 7.4 21.6ZM7.4 18.7C6.9 18.7 6.5 19.1 6.5 19.6C6.5 20.1 6.9 20.5 7.4 20.5C7.9 20.5 8.3 20.1 8.3 19.6C8.3 19.1 7.9 18.7 7.4 18.7Z"
                  fill="#1B607B"
                />
                <path
                  d="M16.9 21.6C15.8 21.6 15 20.7 15 19.7C15 18.7 15.9 17.8 16.9 17.8C17.9 17.8 18.8 18.7 18.8 19.7C18.8 20.7 17.9 21.6 16.9 21.6ZM16.9 18.7C16.4 18.7 16 19.1 16 19.6C16 20.1 16.4 20.5 16.9 20.5C17.4 20.5 17.8 20.1 17.8 19.6C17.8 19.1 17.4 18.7 16.9 18.7Z"
                  fill="#1B607B"
                />
              </svg>
              Sadzenie przez API
            </div>
          </StyledNavLink>
          <StyledNavLink onClick={() => setOpen(false)} to={"/logout"}>
            Wyloguj
          </StyledNavLink>
          <CompanyContactFooter />
        </DrawerWrapper>
      </Drawer>
    </>
  );
}

export interface IMenu {}

export default Menu;

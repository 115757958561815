import React, { useState } from "react";
import styled from "styled-components";
import { PlainLayout } from "../../components/Layout";
import { Card, HeaderLabel, Link, Links } from "./Login";
import { TextInput } from "../../components/Common/TextInput";
import { Button } from "../../components/Common";
import { defaultButton } from "../../components/Common/Button";
import { LoginForm } from "../../containers/Forms";
import { useAppDispatch } from "../../store";
import { useHistory, useParams } from "react-router";
import { resetPasswordToken } from "../../store/reducers/authReducer";

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  margin-top: 15px;
  width: 270px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #1b607b;
`;

const Info = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #e30613;
  padding-bottom: 5px;
`;

function ChangePassword({}: IChangePassword) {
  const [error, setError] = useState(false);
  const [formState, setFormState] = useState<any>({
    p: "",
    p2: "",
    e: false,
    e2: false,
  });

  const changeForm = (field: string) => (e: any) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
      e: false,
      e2: false,
    });
  };

  const dispatch = useAppDispatch();
  const { token } = useParams<any>();
  const history = useHistory();

  const validate = (value: string) => {
    const re = new RegExp(
      /(?=.*\d)(?=.*[a-z])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{6,}/
    );
    if (value === "" || value == null) {
      return true;
    }
    return re.test(value);
  };

  const resetPassword = () => {
    let errors: any = {};

    if (!formState.p || formState.p === "") {
      errors = { ...errors, e: "Uzupełnij pole" };
    }
    if (!formState.p2 || formState.p2 === "") {
      errors = { ...errors, e2: "Uzupełnij pole" };
    }

    if (!validate(formState.p)) {
      errors = {
        ...errors,
        e: "Hasło musi się składać z min 8 znaków, 1 specjalnego i cyfry",
      };
    }
    if (!validate(formState.p2)) {
      errors = {
        ...errors,
        e2: "Hasło musi się składać z min 8 znaków, 1 specjalnego i cyfry",
      };
    }

    if (errors.e || errors.e2) {
      return setFormState({ ...formState, ...errors });
    }

    if (formState.p === formState.p2) {
      dispatch(
        resetPasswordToken({
          resetToken: token,
          password: formState.p,
        })
      ).then((res) => {
        if (res?.payload?.status === 422) {
          return setError(true);
        }
        history.push("/logowanie");
      });
    } else {
      setFormState({
        ...formState,
        e: "Hasła nie są takie same",
        e2: "Hasła nie są takie same",
      });
    }
  };

  return (
    <PlainLayout>
      <Card>
        <HeaderLabel>Zmień hasło</HeaderLabel>
        <Label>Wprowadź nowe hasło.</Label>
        <TextInput
          value={formState.p}
          label={"Hasło"}
          error={formState.e}
          placeholder={"Hasło"}
          type={"password"}
          wrapperStyle={{ marginTop: "15px" }}
          onChange={changeForm("p")}
        />
        <TextInput
          value={formState.p2}
          error={formState.e2}
          label={"Powtórz hasło"}
          type={"password"}
          placeholder={"Powtórz hasło"}
          onChange={changeForm("p2")}
        />
        {error && <Info>Coś poszło nie tak</Info>}
        <Button
          style={{ maxHeight: "45px", minHeight: "45px", width: "100%" }}
          onClick={resetPassword}
          styles={defaultButton}
          variant={"contained"}
        >
          Zmień hasło
        </Button>
        <Links>
          <Link to={"/logowanie"}>Wróć do logowania</Link>
        </Links>
      </Card>
    </PlainLayout>
  );
}

export interface IChangePassword {}

export default ChangePassword;

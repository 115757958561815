import * as React from "react";
import styled from "styled-components";
import { IWidget } from "../index";
import { Badge } from "./Badge";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import API from "../../../API";
import { getTreeString } from "../../../utils/dict";

const Wrapper = styled.div`
  width: 252px;
  height: 80px;
  display: flex;
  align-items: center;
  background: #ffffff;
`;

const Img = styled.img`
  height: 80px;
  width: 80px;
`;

const InfoWrapper = styled.div`
  height: 80px;
  margin-left: 15px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;

  display: flex;
  align-items: center;

  color: #1b607b;
`;

const SubLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-top: 2px;
  display: flex;
  align-items: center;

  color: #1b607b;
`;

const Footer = styled.div`
  display: flex;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 130%;
  /* or 13px */
  margin-top: 6px;
  display: flex;
  align-items: center;
  text-align: right;

  color: #98b3ba;
`;

export function WidgetTypeTwo({ count = 0, style }: IWidget) {
  const [plants, setPlants] = useState(count);
  const params = useParams<{ userId: string | undefined }>();

  const { userId } = params;

  useEffect(() => {
    if (userId) {
      API.fetchWidget(Number(userId), 2).then((response) =>
        response.json().then((data) => {
          setPlants(data.data?.totalUserPlants);
        })
      );
    }
  }, [userId]);

  const label = `Nasza firma posadziła ${plants} ${getTreeString(
    plants
  )}. Sprawdź nas na stronie Posadzimy.pl`;
  return (
    <Wrapper style={style}>
      {/*<Badge hide={true} count={plants} />*/}
      <Img src={"/static/img/w_1.png"} alt={"widget logo 2"} />
      <InfoWrapper>
        <Label>{plants}</Label>
        <SubLabel>Liczba posadzonych drzew</SubLabel>
        <Footer>
          potwierdzone przez
          <img
            style={{ marginLeft: "9px" }}
            src={"/static/img/w_2.png"}
            alt={"widget logo 2 footer"}
          />
        </Footer>
      </InfoWrapper>
    </Wrapper>
  );
}

import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

const Input = styled.input`
  height: 40px;
  width: 100%;
  outline: unset;
  border: 1px solid #a4c3cb;
  margin-top: 10px;
  text-indent: 12.2px;
  color: #1b607b;
  box-sizing: border-box;
  &:focus {
    border-color: #1b607b;
  }

  &::placeholder {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    display: flex;
    align-items: center;

    color: #a4c3cb;
  }
`;

const Icon = styled.img`
  width: 18px;
  height: 10px;
  cursor: pointer;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 5px 0px;
  margin-left: 10px;
  margin-top: 10px;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  display: flex;
  align-items: center;

  color: #1b607b;
`;

export const Error = styled.div<{ error: boolean }>`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  min-height: 19px;
  display: flex;
  align-items: center;

  color: #e30613;

  visibility: ${(props) => (props.error ? "visible" : "hidden")};
`;

export function NumberInput({
  wrapperStyle,
  label,
  error,
  up,
  down,
  arrowWrapperStyle,
  ...rest
}: any) {
  return (
    <Wrapper style={wrapperStyle}>
      <Label>{label}</Label>
      <div style={{ display: "flex" }}>
        <Input type={"number"} min={1} pattern="\d*" step={1} {...rest}  />
        <ArrowWrapper style={arrowWrapperStyle}>
          <Icon
            onClick={up}
            src="/static/icon/chevron_up.png"
            alt={"chevron_up"}
          />
          <Icon
            onClick={down}
            src="/static/icon/chevron_down.png"
            alt={"chevron_down"}
          />
        </ArrowWrapper>
      </div>
      <Error error={!!error}>
        {error
          ? typeof error === "string"
            ? error
            : "Aby dokonać transakcji uzupełnij brakujące pola."
          : " "}
      </Error>
    </Wrapper>
  );
}

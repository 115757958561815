import React, { useEffect, useRef, useState } from "react";
import { ColLayout } from "../../components/Layout/ColLayout";
import { Card } from "../../components/Card";
import styled from "styled-components";
import {
  Alert,
  Button,
  Checkbox, SelectInput,
  TextAreaInput,
} from "../../components/Common";
import { defaultButton } from "../../components/Common/Button";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCertHTML, replaceInCert } from "../Cert";
// @ts-ignore
import Pdf from "react-to-pdf";
import { Error, NumberInput } from "../../components/Common/NumberInput";
import {
  clearCerts,
  incrementCertCount,
  requestCertPdf,
  requestCertZip,
  requestGenerateCert,
  resetCounter,
  setCertForm,
} from "../../store/reducers/certReducer";
import { fetchCompany } from "../../store/reducers/companyReducer";
import { fetchPublic } from "../../store/reducers/publicReducer";
import { CircularProgress } from "@material-ui/core";
import { fetchStats } from "../../store/reducers/userReducer";
import { useDesktop } from "../../components/Responsive";
import { NavLink } from "react-router-dom";
// @ts-ignore
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import API from "../../API";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = window.location.origin + "/pdf.worker.js";

const SideCard = styled.div`
  background: #ebf2f4;
  width: 16vw;
  max-width: 300px;
  padding: 18px 20px;
  margin-top: 20px;

  @media (max-width: 1024px) {
    max-width: unset;
  }
`;

const SideLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #1b607b;
  line-height: 130%;
`;

const SideCount = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  margin-top: 6px;
  color: #1b607b;
`;

const SideDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #1b607b;
  margin: 18px 0px;
`;

const Wrapper = styled.div`
  padding: 44px 56px 0px 18px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 20px 18px 0px 18px;
  }
`;
const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  margin-bottom: 10px;
  /* or 22px */

  color: #1b607b;
`;

const Label2 = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  /* or 18px */

  display: flex;
  align-items: center;
  width: 318px;
  color: #e30613;
  margin-top: 5px;
  @media (max-width: 1024px) {
    width: unset;
  }
`;

const Preview = styled.div`
  & > canvas {
    width: unset !important;
    height: unset !important;
  }
`;

const TextLabel = styled.div`
  font-family: Roboto, sans-serif;
  max-width: 350px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;

  & > a {
    color: #33addc;
    white-space: nowrap;
  }

  /* or 22px */
  @media (max-width: 1024px) {
    width: unset;
  }
  color: #1b607b;
`;

function NewCert({}: INewCert) {
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cert, setCert] = useState(null as any);
  const [width, setWidth] = useState(1920);
  const [tasks, setTasks] = useState<any>([]);
  const [certIndex, setCertIndex] = useState(0);
  const [generated, setGenerated] = useState(false);
  const ref = useRef((null as unknown) as HTMLDivElement);
  const imageRef = useRef((null as unknown) as HTMLDivElement);
  const certsMax = useAppSelector(
    (state) => state.user.user?.totalPlantedTrees || 0
  );
  const certsCount = useAppSelector(
    (state) => state.user.user?.totalGeneratedCertificates
  );
  const desktop = useDesktop();
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.auth.companyId);

  const company = useAppSelector((state) => state.company.company);
  const publicCompany = useAppSelector((state) => state.public.public);
  const generatedCerts = useAppSelector((state) => state.cert.generatedCerts);
  const certificateZipUrl = useAppSelector(
    (state) => state.cert.certificateZipUrl
  );

  const generatedCount = useAppSelector((state) => state.cert.generatedCount);
  const [SWindow, setSWindow] = useState(null);
  const certsAvailable = (certsMax || 0) - (certsCount || 0);
  const formState = useAppSelector((state) => state.cert.formState);
  const { errors } = formState;

  const [treeCountError, setTreeCountError] = useState(false);

  const certHTML = useCertHTML(0, API.token || "");
  const [pdfUrl, setPdfUrl] = useState<any>(undefined);
  const [refresh, setRefresh] = useState<any>(false);
  const [timeout, setTime] = useState<any>(null);

  useEffect(() => {



  }, [formState.lang, certHTML.htmlEnglishContent])

  useEffect(() => {
    if (certHTML && (formState.certCount != 1 || (formState.description !== undefined))) {
      API.createPreviewCert({
        type: certHTML.certType,
        lang: formState.lang,
        numberOfTrees: Number(formState.certTrees) || 1,
        description: formState.description || "",
        htmlContent: certHTML?.htmlContent?.b64encode(),
        htmlEnglishContent: certHTML?.htmlEnglishContent?.b64encode(),
      })
        .then((res) => {
          if(!res.ok){
            dispatch(setCertForm({errors: {...errors, lang: 'Wybrana wersja językowa nie jest w tym momencie dostępna'}}));
          }
          return res.blob()
        })
        .then((blob) => {
          setPdfUrl(URL.createObjectURL(blob));
        })
    }

    return () => {
      // URL.revokeObjectURL(pdfUrl);
      // setPdfUrl(undefined);
    };
  }, [refresh,certHTML.certType, formState.certTrees, certHTML.htmlContent, certHTML.htmlEnglishContent, formState.lang]);

  useEffect(() => {
    if (API.token) {
      API.createPreviewCert({
        type: certHTML.certType,
        lang: formState.lang,
        numberOfTrees: Number(formState.certTrees) || 1,
        description: formState.description || "",
        htmlContent: certHTML?.htmlContent?.b64encode(),
        htmlEnglishContent: certHTML?.htmlEnglishContent?.b64encode(),
      })
        .then((res) => {
          if(!res.ok){
              dispatch(setCertForm({errors: {...errors, lang: 'Wybrana wersja językowa nie jest w tym momencie dostępna'}}));
          }
          return res.blob()
        })
        .then((blob) => {
          setPdfUrl(URL.createObjectURL(blob));
        })
    }
  }, [certHTML.htmlContent,certHTML.certType, certHTML.htmlEnglishContent, API.token, formState.lang]);

  useEffect(() => {
    if (timeout) clearTimeout(timeout);
    setTime(
      setTimeout(() => {
        setRefresh(!refresh);
      }, 2000)
    );
  }, [formState]);

  useEffect(() => {
    setTreeCountError(false);
    setLoading(false);
    setValid(false);
    setCert(null);
    setGenerated(false);
    dispatch(clearCerts());
    setLoading(false);
    setCertIndex(0);
    dispatch(resetCounter());
    dispatch(fetchStats({}));
    const formState = localStorage.getItem('cert_form')

    try {
      if(formState) {
        dispatch(
          setCertForm(JSON.parse(formState || "{}"))
        );
      } else  {
        throw 'error'
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setCertForm({
          certCount: 1,
          certTrees: 1,
          lang: 'PL',
          description: "",
          errors: {
            lang: false,
            certCount: false,
            certTrees: false,
            description: false,
          },
        })
      );
    }

  }, []);

  useEffect(() => {
    return () => {
      if (tasks.length > 0) {
        dispatch(clearCerts());
        setLoading(false);
        setCertIndex(0);
        dispatch(resetCounter());
        tasks.forEach((task: any) => {
          if ("abort" in task) task.abort();
        });
      }
    };
  }, [tasks]);

  useEffect(() => {
    function sWidth() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", sWidth);

    return () => {
      window.removeEventListener("resize", sWidth);
    };
  }, []);

  useEffect(() => {
    if (certificateZipUrl) {
      setGenerated(true);
      let link = document.createElement("a");
      link.download = certificateZipUrl;
      link.href = process.env.REACT_APP_CERTS + "/" + certificateZipUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch(clearCerts());
      setLoading(false);
      setCertIndex(0);
      setCert(null);
      setTasks([]);
      dispatch(resetCounter());
      dispatch(fetchStats({}));
    }
  }, [certificateZipUrl]);

  useEffect(() => {
    if (generatedCerts?.length === 0) {
      setLoading(false);
      setCert(null);
      return;
    }
    // if (generatedCerts?.length === 1) {
    //   setCert({
    //     ...generatedCerts[0],
    //     companyName: company?.companyBillingName || "",
    //     companyLogo: publicCompany?.companyAvatar,
    //   });
    //   setTimeout(() => {
    //     ref.current?.click();
    //   }, 500);
    //   return;
    // }
    if (generatedCerts) {
      const promises = generatedCerts.map((cert) => {
        const promise = dispatch(requestCertPdf({ id: cert.id }));
        promise.then((_) => dispatch(incrementCertCount()));
        return promise;
      });
      setTasks(promises);
    }
  }, [generatedCerts]);

  useEffect(() => {
    if (
      generatedCerts &&
      generatedCerts?.length === 1 &&
      generatedCount === 1
    ) {
      tasks[0].then((data: any) => {
        const url = data.payload.data.certificatePDFUrl;

        if (navigator.userAgent.includes("iPhone") && SWindow) {
          (SWindow as any).location = url;
        } else {
          var link = document.createElement("a");

          link.href = url;

          link.download = generatedCerts[0].id + "cert.pdf";
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        dispatch(clearCerts());
        setLoading(false);
        setCertIndex(0);
        setCert(null);
        setTasks([]);
        dispatch(resetCounter());
        dispatch(fetchStats({}));
      });
    } else if (generatedCerts && generatedCerts?.length === generatedCount) {
      dispatch(
        requestCertZip({
          certIDs: generatedCerts.map((c) => c.id),
        })
      );
    }
  }, [generatedCount]);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchCompany({}));
      dispatch(fetchPublic({}));
    }
  }, [companyId]);

  const handleChange = (e: any) => {
    if (e.target.name !== "description") {
      setTreeCountError(false);
    }

    if(e.target.name === 'lang') {
      dispatch(setCertForm({errors: {...formState.errors, lang: false}}))
    }
    if (e.target.name === "description" && e.target.value.length > 150) {
      if ("preventDefault" in e) {
        e.preventDefault();
        e.stopPropagation();
      }

      return;
    }
    if (
      (e?.target?.value?.includes(".") ||
        e?.target?.value?.includes(",") ||
        e?.target?.value?.includes("-") ||
        e?.target?.value == 0 ||
        e?.target?.value <= 0 ||
        e?.target?.value === "") &&
      e.target.name !== "description"
    ) {
      if ("preventDefault" in e) {
        e.preventDefault();
        e.stopPropagation();
      }
      setTreeCountError(true);
      return;
    }

    if (
      e.target.name === "certCount" &&
      e.target.value * formState.certTrees > certsAvailable
    ) {
      setTreeCountError(true);
    }
    if (
      e.target.name === "certTrees" &&
      e.target.value * formState.certCount > certsAvailable
    ) {
      setTreeCountError(true);
    }

    dispatch(
      setCertForm({
        [e.target.name]:
          typeof e.target.value == "string"
            ? e.target.value?.replace(/<\/?[^>]+(>|$)/g, "")
            : e.target.value,
      })
    );
  };

  const handleArrow = (name: string, value: number) => () => {
    if (!(value < 1)) {
      handleChange({ target: { name, value: String(value) } });
    }
  };

  const generateCerts = () => {
    if (valid && !loading) {
      if (
        Number(formState.certCount) === 1 &&
        navigator.userAgent.includes("iPhone")
      ) {
        setSWindow(window.open() as any);
      }

      dispatch(
        requestGenerateCert({
          numberOfCertificates: Number(formState.certCount),
          numberOfTrees: Number(formState.certTrees),
          description: formState.description,
          lang: formState.lang,
        })
      ).then(() => {
        dispatch(
          setCertForm({
            errors: {
              lang: false,
              certCount: false,
              certTrees: false,
              description: false,
            },
          })
        );
        localStorage.setItem('cert_form', JSON.stringify(formState))
      });
      setLoading(true);
    }
  };

  const nextCert = () => {
    dispatch(clearCerts());
    setLoading(false);
    setCertIndex(0);
    setCert(null);
    dispatch(fetchStats({}));
  };

  const scale = (
    number: number,
    inMin: number,
    inMax: number,
    outMin: number,
    outMax: number
  ) => {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  };

  const obj = {
    createdAt: { date: new Date().toISOString().replace("T", " ") },
    id: "0000",
    companyName: company?.companyBillingName || "",
    totalUserBoughtTrees: certsMax,
    numberOfTrees: formState.certTrees,
    totalBroughtTrees: 1111,
    description: formState.description,
    certInfoPaper: 1,
    certInfoOxygen: 1,
    certInfoArea: 1,
    certInfoCO2: 1,
    companyLogo: publicCompany?.companyAvatar,
    wordpressURL: publicCompany?.companyWordpressURL,
  };

  return (
    <ColLayout
      colCount={1}
      cols={[
        <Card
          full={desktop}
          wrapperStyle={desktop ? {} : { height: "calc(100% + 30px)" }}
          horizontal={desktop}
          HeaderProps={{
            variant: "filled",
            label: (
              <div
                style={{
                  marginTop: "5px",
                }}
              >
                Wygeneruj certyfikat
                {desktop && (
                  <SideCard>
                    <SideLabel>Twoje wygenerowane certyfikaty:</SideLabel>
                    <SideCount>
                      {certsCount}/{certsMax}
                    </SideCount>
                    <SideDivider />
                    <Alert
                      labelStyle={{ fontSize: "15px" }}
                      style={{
                        alignItems: "flex-start",
                        paddingLeft: "0",
                        paddingRight: "0",
                      }}
                      label={
                        "Liczba certyfikatów odpowiada liczbie posadzonych przez ciebie drzew."
                      }
                    />
                  </SideCard>
                )}
              </div>
            ),
            center: !desktop,
            style: {
              borderRadius: "5px 0px 0px 5px",
            },
          }}
        >
          <Wrapper style={{ boxSizing: "border-box" }}>
            {!desktop && (
              <SideCard
                style={{
                  marginTop: "0px",
                  width: "100%",
                  boxSizing: "border-box",
                  marginBottom: "10px",
                }}
              >
                <SideLabel>Twoje wygenerowane certyfikaty:</SideLabel>
                <SideCount>
                  {certsCount}/{certsMax}
                </SideCount>
                <SideDivider />
                <Alert
                  labelStyle={{ fontSize: "15px" }}
                  style={{
                    alignItems: "flex-start",
                    paddingLeft: "0",
                    paddingRight: "0",
                  }}
                  label={
                    "Liczba certyfikatów odpowiada liczbie posadzonych przez ciebie drzew."
                  }
                />
              </SideCard>
            )}
            <div style={{ paddingRight: "10px" }}>
              <Label>Każdy certyfikat posiada swój indywidualny numer</Label>
              <NumberInput
                style={{ marginTop: "0", width: "80px" }}
                arrowWrapperStyle={{ marginTop: "0" }}
                label={"Liczba certyfikatów"}
                name={"certCount"}
                value={formState.certCount}
                placeholder={1}
                error={errors.certCount}
                onChange={handleChange}
                up={handleArrow("certCount", Number(formState.certCount) + 1)}
                down={handleArrow("certCount", Number(formState.certCount) - 1)}
              />
              <NumberInput
                style={{ marginTop: "0", width: "80px" }}
                arrowWrapperStyle={{ marginTop: "0" }}
                value={formState.certTrees}
                label={"Liczba drzew na certyfikacie"}
                name={"certTrees"}
                placeholder={1}
                error={errors.certTrees}
                onChange={handleChange}
                up={handleArrow("certTrees", Number(formState.certTrees) + 1)}
                down={handleArrow("certTrees", Number(formState.certTrees) - 1)}
              />
              {certHTML.isDefault && <SelectInput
                label={'Wybierz język'}
                name={"lang"}
                value={formState.lang}
                onChange={handleChange}
                error={errors.lang}
              >
                <option value="PL">Polski</option>
                <option value="EN">Angielski</option>
              </SelectInput>}
              <TextAreaInput
                label={"Twój tekst (maksymalnie 150 znaków)"}
                name={"description"}
                placeholder={"Gratulujemy"}
                error={errors.description}
                value={formState.description}
                onChange={handleChange}
              />

              {treeCountError && (
                <Error
                  style={{ maxWidth: "350px", marginBottom: "10px" }}
                  error={treeCountError}
                >
                  Nie można wygenerować certyfikatów dla{" "}
                  {formState.certTrees * formState.certCount} drzew. Liczba
                  drzew niewykorzystanych do certyfikatów to: {certsAvailable}
                </Error>
              )}
              <TextLabel>
                Nie widzisz logo na certyfikacie lub chcesz zmienić wyświetlaną
                nazwę firmy?{" "}
                <NavLink to={"/panel/info"}>Przejdź do zmian.</NavLink>
              </TextLabel>
              {/*<Button*/}
              {/*  style={{*/}
              {/*    width: "190px",*/}
              {/*    marginTop: "32px",*/}
              {/*    maxHeight: "33px",*/}
              {/*    minHeight: "33px",*/}
              {/*  }}*/}
              {/*  styles={outlinedStyle}*/}
              {/*  variant={"outlined"}*/}
              {/*>*/}
              {/*  ZOBACZ PODGLĄD*/}
              {/*</Button>*/}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: desktop ? "flex-start" : "center",
                padding: "0 27px",
                borderLeft: desktop ? "1px solid #EBF2F4" : "unset",
              }}
            >
              <Document
                noData={""}
                onSourceError={console.log}
                onLoadError={console.log}
                renderMode={"canvas"}
                file={pdfUrl}
              >
                <Page scale={0.4} pageNumber={1} />
              </Document>
              {/*<Preview ref={imageRef} />*/}

              {certsAvailable > 0 && (
                <>
                  <Checkbox
                    wrapperStyle={{ marginTop: "20px" }}
                    labelStyle={{ fontSize: "12px" }}
                    onChange={(e: any) => setValid(e.target.value)}
                    label={
                      "Potwierdzam poprawność wpisanych danych do certyfikatu"
                    }
                  />
                  <Button
                    style={{ marginTop: "10px", minWidth: "215px" }}
                    styles={defaultButton}
                    onClick={generateCerts}
                    variant={"contained"}
                    disabled={treeCountError || errors.lang}
                  >
                    {loading ? (
                      <CircularProgress size={24} color={"white" as any} />
                    ) : (
                      "WYGENERUJ I POBIERZ"
                    )}
                  </Button>
                </>
              )}
              {!valid && certsAvailable > 0 && (
                <Label2>
                  By pobrać certyfikat potwierdź poprawność wpisanych danych do
                  certyfikatu.
                </Label2>
              )}
              {generatedCerts && generatedCerts?.length > 1 && (
                <Label2 style={{ color: "#1b607b" }}>
                  Wygenerowano {generatedCount} z {generatedCerts.length}
                </Label2>
              )}

              {(!desktop || generated) && (
                <Button
                  style={{ marginTop: "10px" }}
                  variant={"text"}
                  component={NavLink}
                  to={"/panel/certs"}
                >
                  &lt; IDŹ DO LISTY CERTYFIKATÓW
                </Button>
              )}
            </div>
          </Wrapper>
        </Card>,
      ]}
    />
  );
}

export interface INewCert {}

export default NewCert;

import * as React from "react";
import styled from "styled-components";
import { IWidget } from "../index";
import { Badge } from "./Badge";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import API from "../../../API";
import { getTreeString } from "../../../utils/dict";
import { WrapItem } from "../../../containers/Widgets/StatWidget";

const Wrapper = styled.div`
  width: 908px;
  height: 273px;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 130%;
  /* or 32px */

  text-align: center;

  color: #62a30d;
`;

const Logo = styled.img`
  width: 118px;
  height: 29px;
  margin-left: 6px;
`;
const SubLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #98b3ba;
`;

const Divider = styled.div`
  border-bottom: 1px solid #d1dfe5;
  flex-grow: 1;
`;

export function WidgetTypeThree({
  style,
  stats = {
    totalUserPlants: 0,
    totalUserPlantsStatArea: 0,
    totalUserPlantsStatCO: 0,
    totalUserPlantsStatOxygen: 0,
    totalUserPlantsStatPaper: 0,
  },
  count = 0,
}: any) {
  const [plants, setPlants] = useState(count);
  const [dStats, setStats] = useState(stats);
  const params = useParams<{ userId: string | undefined }>();

  const { userId } = params;

  const {
    totalUserPlants,
    totalUserPlantsStatArea,
    totalUserPlantsStatCO,
    totalUserPlantsStatOxygen,
    totalUserPlantsStatPaper,
  } = dStats;

  useEffect(() => {
    if (userId) {
      API.fetchWidget(Number(userId), 3).then((response) =>
        response.json().then((data) => {
          setPlants(data.data?.totalUserPlants);
          setStats(data.data);
        })
      );
    }
  }, [userId]);

  return (
    <Wrapper style={style}>
      <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <Divider style={{ marginRight: "13px" }} />
        <Label>Nasze drzewa to nie tylko liczby</Label>
        <Divider style={{ marginLeft: "13px" }} />
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        <WrapItem
          widget={true}
          title={<>Powierzchnia lasu</>}
          surfix={
            <>
              m<sup>2</sup>
            </>
          }
          count={totalUserPlantsStatArea}
          icon={"/static/img/w_area.svg"}
        />
        <WrapItem
          widget={true}
          title={
            <>
              Pochłanianie CO<sup>2</sup>/rok
            </>
          }
          surfix={
            <>
              kg<sup>&nbsp;</sup>
            </>
          }
          count={totalUserPlantsStatCO}
          icon={"/static/img/w_co2.svg"}
        />
        <WrapItem
          widget={true}
          big={true}
          title={<>Liczba drzew</>}
          count={totalUserPlants}
          icon={"/static/img/w_tree.svg"}
        />
        <WrapItem
          widget={true}
          title={<>Tlen dla osób rocznie</>}
          count={totalUserPlantsStatOxygen}
          icon={"/static/img/w_people.svg"}
        />
        <WrapItem
          widget={true}
          title={<>Ilość zwróconego papieru</>}
          surfix={
            <>
              kg<sup>&nbsp;</sup>
            </>
          }
          count={totalUserPlantsStatPaper}
          style={{ borderRight: "unset" }}
          icon={"/static/img/w_paper.svg"}
        />
      </div>

      <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <Divider style={{ marginRight: "8px" }} />
        <SubLabel>potwierdzone przez</SubLabel>
        <Logo alt={"widget type three logo"} src={"/static/img/logo.png"} />
        <Divider style={{ marginLeft: "8px" }} />
      </div>
    </Wrapper>
  );
}

import * as React from "react";
import styled from "styled-components";
import { IWidget } from "../index";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import API from "../../../API";
import { getTreeString } from "../../../utils/dict";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  width: 270px;
  height: 184px;
  box-sizing: border-box;
`;

const Img = styled.img`
  height: 80px;
  width: 80px;
`;
const Label = styled.label`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  margin-top: 14px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #1b607b;
`;
const SubLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 8px 0px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #1b607b;
`;

export function WidgetTypeOne({ style, count = 0 }: IWidget) {
  const [plants, setPlants] = useState(count);
  const params = useParams<{ userId: string | undefined }>();

  const { userId } = params;

  useEffect(() => {
    if (userId) {
      API.fetchWidget(Number(userId), 1).then((response) =>
        response.json().then((data) => {
          setPlants(data.data?.totalUserPlants);
        })
      );
    }
  }, [userId]);

  return (
    <Wrapper style={style}>
      <Img src={"/static/img/w_1.png"} alt={"widget logo small"} />
      <Label>{plants}</Label>
      <SubLabel>Liczba posadzonych drzew</SubLabel>
      <img
        src={"/static/img/w_1_footer.png"}
        alt={"widget logo small footer"}
      />
    </Wrapper>
  );
}

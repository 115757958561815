import {
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";

const StepperConnector = withStyles({
  alternativeLabel: {
    top: "4px",
    left: "calc(-50% + 4px)",
    right: "calc(50% + 4px)",
  },
  active: {
    "& $line": {
      backgroundColor: "#8BC932",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#8BC932",
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: "#B0C0C5",
    borderRadius: 1,
  },
})(StepConnector);

const StyledStepLabel = styled(StepLabel)`
  font-family: Roboto, sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #b0c0c5 !important;

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 5px;
  }
  .MuiStepLabel-active {
    font-family: Roboto, sans-serif !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;

    color: #1b607b !important;
  }
`;

const StyledStepper = styled(Stepper)`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
`;

const StepBall = styled.div<{ done: boolean }>`
  width: 10px;
  height: 10px;
  min-height: 10px;
  min-width: 10px;
  border-radius: 50%;
  background: ${(props) => (props.done ? "#8bc932" : "#B0C0C5")};
`;

function StepIcon(props: any) {
  const { active, completed } = props;

  return <StepBall done={active || completed} />;
}

const Wrapper = styled.div`
  border: 1px solid #ebf2f4;
`;

export function PurchaseStepper({ activeStep = 0 }: IPurchaseStepper) {
  return (
    <Wrapper>
      <StyledStepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<StepperConnector />}
      >
        <Step completed={activeStep > 0}>
          <StyledStepLabel StepIconComponent={StepIcon}>
            Liczba drzew
          </StyledStepLabel>
        </Step>
        <Step>
          <StyledStepLabel StepIconComponent={StepIcon}>
            Dane i płatność
          </StyledStepLabel>
        </Step>
      </StyledStepper>
    </Wrapper>
  );
}

export interface IPurchaseStepper {
  activeStep?: number;
}

import React from "react";
import styled from "styled-components";
import { Card } from "../../../components/Card";
import { format } from "date-fns";
import pl from "date-fns/locale/pl";
import { useAppSelector } from "../../../store";

const Wrapper = styled.div`
  display: flex;
  padding-top: 17px;
  padding-bottom: 15px;
  justify-content: space-between;
`;

const FragmentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
`;

const Img = styled.img`
  width: 50px;
  height: 50px;
`;

const Label = styled.label`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;

  text-transform: capitalize;

  display: flex;
  align-items: center;
  text-align: center;
  margin: 13px 0px;
  color: #1b607b;
`;

const SubLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #1b607b;
`;

function EcommerceStatsWidget({}: IEcommerceStatsWidget) {
  const date = new Date();

  const user = useAppSelector((state) => state.user.user);

  return (
    <Card
      HeaderProps={{
        variant: "plain",
        label: "Aktualne dane",
        icon: "stats",
      }}
    >
      <Wrapper>
        <FragmentWrapper style={{ borderRight: "1px solid #EBF2F4" }}>
          <Img src={"/static/icon/calendar.svg"} alt={"ecommerce stats"} />
          <Label>{format(date, "LLLL", { locale: pl })}</Label>
          <SubLabel>Aktywny miesiąc</SubLabel>
        </FragmentWrapper>
        <FragmentWrapper>
          <Img src={"/static/icon/three.svg"} alt={"ecommerce stats"} />
          <Label>{user?.eCommerceSoldTreeInCurrentMonth || 0}</Label>
          <SubLabel>Liczba posadzonych drzew</SubLabel>
        </FragmentWrapper>
      </Wrapper>
    </Card>
  );
}

export interface IEcommerceStatsWidget {}

export default EcommerceStatsWidget;

import * as React from "react";
import styled from "styled-components";
import { Button } from "../../components/Common";
import { outlinedStyle } from "../../components/Common/Button";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../store";
import { useDesktop } from "../../components/Responsive";

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  display: flex;
  align-items: center;

  color: #1b607b;
`;

const Code = styled.div`
  width: fit-content;
  border: 1px solid #a4c3cb;
  box-sizing: border-box;
  padding: 20px 11px 20px 11px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  user-select: all;
  /* or 21px */
  display: flex;
  align-items: center;

  color: #1b607b;
`;

export function CodeFrame({ style, widgetType }: ICodeFrame) {
  const userId = useAppSelector((state) => state.auth.userId);
  const code = `<iframe
          src="${window.location.origin}/widgety/${widgetType}/${userId}"
          frameBorder="0"
          scrolling="no"
          ${
            widgetType == 1
              ? 'width="270px"'
              : widgetType == 2
              ? 'width="252px"'
              : 'width="908px"'
          }
          ${
            widgetType == 1
              ? 'height="184px"'
              : widgetType == 2
              ? 'height="80px"'
              : 'height="273px"'
          }
        ></iframe>`;

  const desktop = useDesktop();

  return (
    <div style={{ ...style, position: "relative" }}>
      <Label>Skopiuj ten kod i wstaw go na swoją stronę:</Label>
      <Code>{code}</Code>
      {/*<Button*/}
      {/*  style={{*/}
      {/*    width: desktop ? "140px" : "100%",*/}
      {/*    marginTop: "10px",*/}
      {/*    position: desktop ? "absolute" : "initial",*/}
      {/*    marginBottom: desktop ? "0" : "15px",*/}
      {/*    right: 0,*/}
      {/*    maxHeight: "33px",*/}
      {/*    minHeight: "33px",*/}
      {/*  }}*/}
      {/*  styles={outlinedStyle}*/}
      {/*  variant={"outlined"}*/}
      {/*  component={NavLink}*/}
      {/*  to={"/panel/faq?item=2"}*/}
      {/*>*/}
      {/*  ZOBACZ JAK*/}
      {/*</Button>*/}
    </div>
  );
}

export interface ICodeFrame {
  style?: React.CSSProperties;
  widgetType: number;
}

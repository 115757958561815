import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Card } from "../../../components/Card";
import {
  fetchTreesLogs,
  ITreesLog,
} from "../../../store/reducers/groupReducer";
import { useAppDispatch } from "../../../store";
import { format } from "date-fns";
import { Button } from "../../../components/Common";
import { outlinedStyle } from "../../../components/Common/Button";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  min-height: 145px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  border-bottom: 1px solid #ebf2f4;
`;

const Header = styled.div`
  height: 40px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background: #ebf2f4;
  color: #1b607b;
`;

const Item = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1b607b;
  padding: 11px 0 11px 17px;

  display: flex;
  align-items: center;
`;

function EcommerceLastWidget({}: IEcommerceLastWidget) {
  const [data, setData] = useState<ITreesLog[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTreesLogs({})).then(({ payload }: any) =>
      setData(payload.data.items)
    );
  }, []);

  return (
    <Card
      HeaderProps={{
        variant: "plain",
        label: "Ostatnie posadzenia",
        icon: "eseed",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Wrapper style={data && data.length > 0? {minHeight: 'unset'}:{} }>
          <Header>Liczba drzew</Header>
          <Header>Data zakupu</Header>
          <Header>Godzina zakupu</Header>
          {data.map((d) => {
            const date = new Date(d.createdAt.date.replace(" ", "T"));

            return (
              <>
                <Item>{d.treeQuantity}</Item>
                <Item style={{ borderLeft: "1px solid #EBF2F4" }}>
                  {format(date, "dd/MM/yyyy")}
                </Item>
                <Item style={{ borderLeft: "1px solid #EBF2F4" }}>
                  {format(date, "HH:mm:ss")}
                </Item>
              </>
            );
          })}
        </Wrapper>
        {data.length > 0 && (
          <Button
            style={{
              maxHeight: "33px",
              minHeight: "33px",
              margin: "15px 0px",
            }}
            styles={outlinedStyle}
            variant={"outlined"}
            component={NavLink}
            to={"/panel/list?tab=1"}
          >
            ZOBACZ WIECEJ
          </Button>
        )}
      </div>
    </Card>
  );
}

export interface IEcommerceLastWidget {}

export default EcommerceLastWidget;

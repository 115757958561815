import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import API from "../API";
import { getLogoImg } from "../utils/urls";
import { useDesktop } from "../components/Responsive";
import { useAppSelector } from "../store";

export const useCertHTML = (type: number, accessKey: string) => {
  const [certHTML, setCertHTML] = useState("");
  const [certHTMLEnglish, setCertHTMLEnglish] = useState("");
  const [isDefault, setIsDefault] = useState(true);
  const [certType, setType] = useState(0);

  useEffect(() => {
    if (accessKey) {
      API.fetchCertTemplateWithAuth(accessKey).then((response) =>
        response.json().then((data) => {
          setCertHTML(data.data?.item?.htmlContent);
          setCertHTMLEnglish(data.data?.item?.htmlEnglishContent);
          setIsDefault(data.data?.item?.isDefault);
          setType(data.data?.item?.type);
        })
      );
    }
  }, [accessKey, type]);

  return {certType: certType, isDefault: isDefault, htmlContent: certHTML?.b64decode(), htmlEnglishContent: certHTMLEnglish?.b64decode()};
};

export const replaceInCert = (certHTML: string, cert: any) => {
  let certCopy = certHTML;

  const obj = {
    "{createDate}": cert?.createdAt?.date?.split(" ")[0] || "",
    "{certId}": cert.publicId || cert?.id || "",
    "{companyName}": cert?.companyName || "",
    "{companyTreeSum}": cert?.totalUserBoughtTrees || 0,
    "{treeCount}": cert?.numberOfTrees || 0,
    "{treeSum}": cert?.totalBroughtTrees || 0,
    "{description}":
      (cert?.description || "").replace("\n", "</br>").replace("\n", "</br>") ||
      "",
    "{paper}": Number(cert?.certInfoPaper).toFixed(2).replace(/\.00$/, "") || 0,
    "{people}":
      Number(cert?.certInfoOxygen).toFixed(2).replace(/\.00$/, "") || 0,
    "{area}": Number(cert?.certInfoArea).toFixed(2).replace(/\.00$/, "") || 0,
    "{companyURL}": cert?.wordpressURL || "",
    "{co2}": Number(cert?.certInfoCO2).toFixed(2).replace(/\.00$/, "") || 0,
    "{companyLogo}": cert?.companyLogo?.imageBase64 || "",
    "{companyHeader}": cert?.companyLogo
      ? `<img style="max-height: 100%; width: auto; height: auto; object-fit: contain; max-width: 800px;" alt="companylogo" src="${cert?.companyLogo?.imageBase64}">`
      : cert?.companyName || "",
  };

  if (!Object.entries) {
    Object.entries = function (obj: any) {
      var ownProps = Object.keys(obj),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

      return resArray;
    };
  }

  Object.entries(obj).forEach(([key, value]) => {
    certCopy = certCopy.replace(key, value);
  });
  return certCopy;
};

function Cert({}: ICert) {
  const params = useParams<{ certId: string; accessToken: string }>();
  const { certId, accessToken } = params;
  const [cert, setCert] = useState({} as any);

  const desktop = useDesktop();

  const history = useHistory();

  const certHTML = useCertHTML(cert?.type, accessToken);

  useEffect(() => {
    if (!cert || !accessToken) {
      history.push("/dashboard");
    }
    if (certId) {
      API.fetchCertWithAuth(certId, accessToken).then((response) =>
        response.json().then((data) => {
          setCert(data.data.item);
          setTimeout(() => {
            window.status = "documentReady";
          }, 300);
        })
      );
    }
  }, [certId, accessToken]);

  useEffect(() => {
    let viewport = document.querySelector('meta[name="viewport"]');

    if (viewport && !desktop) {
      (viewport as any).content = "initial-scale=0.33";
    }
  }, [desktop]);

  return certHTML ? (
    <div
      dangerouslySetInnerHTML={{
        __html: replaceInCert(certHTML.htmlContent, cert),
      }}
    />
  ) : (
    <></>
  );
}

export interface ICert {}

export default Cert;

import * as React from "react";
import { ColLayout } from "../../components/Layout/ColLayout";
import { Card } from "../../components/Card";
import { Table } from "../../components/Table";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../store";
import { useEffect } from "react";
import { fetchCompany } from "../../store/reducers/companyReducer";
import { fetchPublic } from "../../store/reducers/publicReducer";
import { fetchCerts } from "../../store/reducers/certReducer";
import { Button } from "../../components/Common";
import styled, { css } from "styled-components";
import API from "../../API";
import { useHistory } from "react-router-dom";
import { useDesktop } from "../../components/Responsive";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { PaymentType } from "../../containers/Widgets/InvoicesWidget";
import { defaultButton } from "../../components/Common/Button";
import { RegisterForm } from "../../containers/Forms/RegisterForm";

const SubLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  @media (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
    padding: 10px 26px 0;
    & > span {
      display: contents;
    }
  }
`;

const AccLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  /* or 22px */

  display: flex;
  align-items: center;

  color: #1b607b;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const GLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: column;

  font-size: 14px;
  line-height: 150%;
  padding: 10px 0px;
  /* or 21px */

  color: #1b607b;
`;

const AccSummary = styled(AccordionSummary)`
  background-color: #ebf2f4 !important;
`;

export function CertsList({}) {
  const userId = useAppSelector((state) => state.auth.userId);
  const certs = useAppSelector((state) => state.cert.certs);
  const total = useAppSelector((state) => state.cert.total);
  const page = useAppSelector((state) => state.cert.page);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const certsMax = useAppSelector(
    (state) => state.user.user?.totalPlantedTrees || 0
  );
  const certsCount = useAppSelector(
    (state) => state.user.user?.totalGeneratedCertificates
  );

  useEffect(() => {
    if (userId) {
      dispatch(fetchCerts({}));
    }
  }, [userId]);

  const desktop = useDesktop();

  const onPageChange = (page: number) => {
    dispatch(fetchCerts({ page }));
  };

  return (
    <ColLayout
      colCount={1}
      cols={[
        <Card
          full={desktop}
          HeaderProps={{
            variant: "filled",
            label: "Certyfikaty",
            subLabel: (
              <SubLabel>
                Liczba wszystkich wygenerowanych certyfikatów:&nbsp;
                <span style={{ fontWeight: 700, display: "contents" }}>
                  {certsCount}/{certsMax}
                </span>
              </SubLabel>
            ),
            subLabelHeight: !desktop,
            center: !desktop,
            action: desktop
              ? {
                  name: "WYGENERUJ NOWY CERTYFIKAT",
                  invoke: () => {
                    history.push("/panel/certs/new");
                  },
                }
              : undefined,
            style: {
              borderRadius: "0",
            },
          }}
        >
          {desktop ? (
            <Table
              totalPages={total}
              page={page}
              onPageChange={onPageChange}
              header={[
                "Numer certyfikatu",
                "Data wygenerowania",
                "Liczba drzew",
                "Opis",
                "Podgląd",
              ]}
              body={
                certs &&
                certs.map((cert) => [
                  {
                    style: { borderLeft: "unset", paddingLeft: "0" },
                    content: cert.id,
                  },
                  {
                    content: format(
                      new Date(cert?.createdAt?.date?.slice(0, 10)),
                      "dd/MM/yyyy"
                    ),
                  },
                  {
                    content: cert?.numberOfTrees,
                  },
                  {
                    style: {
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "1100px",
                    },
                    content: cert?.description || "-",
                  },
                  {
                    content: (
                      <Button
                        component={"a"}
                        href={
                          cert.certificatePDFFile
                            ? process.env.REACT_APP_CERTS +
                              "/" +
                              cert.certificatePDFFile
                            : undefined
                          // : window.location.origin +
                          //   `/certd/${cert?.id}/${API.token}`
                        }
                        target={"_blank"}
                        styles={css`
                          min-height: 20px !important;
                          max-height: 20px !important;
                          & .MuiButton-label {
                            font-weight: 500;
                            font-size: 13px;
                            white-space: nowrap;
                          }
                        `}
                        color={"#33addc"}
                      >
                        {cert.certificatePDFFile
                          ? "ZOBACZ PODGLĄD"
                          : "TRWA GENEROWANIE..."}
                      </Button>
                    ),
                  },
                ])
              }
            />
          ) : (
            <div>
              {!!total && page !== 1 && (
                <div
                  onClick={() => onPageChange(page - 1)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50px",
                  }}
                >
                  <img
                    style={{ transform: "rotate(180deg)" }}
                    alt={"expand"}
                    src={"/static/icon/expand.png"}
                  />
                </div>
              )}
              {certs &&
                certs.map((cert, i) => (
                  <Accordion key={"cert" + i} elevation={0}>
                    <AccSummary
                      expandIcon={
                        <img alt={"expand"} src={"/static/icon/expand.png"} />
                      }
                    >
                      <AccLabel>{cert.id}</AccLabel>
                    </AccSummary>
                    <AccordionDetails>
                      <Grid>
                        <GLabel>Data wygenerowania</GLabel>
                        <GLabel style={{ fontWeight: 400 }}>
                          {format(
                            new Date(cert?.createdAt?.date?.slice(0, 10)),
                            "dd/MM/yyyy"
                          )}
                        </GLabel>
                        <GLabel>Liczba</GLabel>
                        <GLabel style={{ fontWeight: 400 }}>
                          {cert?.numberOfTrees}
                        </GLabel>
                        <GLabel>Opis</GLabel>
                        <GLabel
                          style={{ fontWeight: 400, wordBreak: "break-all" }}
                        >
                          {cert?.description}
                          <div>
                            <Button
                              component={"a"}
                              href={
                                cert.certificatePDFFile
                                  ? process.env.REACT_APP_CERTS +
                                    "/" +
                                    cert.certificatePDFFile
                                  : undefined
                              }
                              target={"_blank"}
                              style={{
                                marginLeft: "-8px",
                              }}
                              styles={css`
                                min-height: 20px !important;
                                max-height: 20px !important;

                                & .MuiButton-label {
                                  font-weight: 500;
                                  font-size: 13px;
                                }
                              `}
                              color={"#33addc"}
                            >
                              {cert.certificatePDFFile
                                ? "ZOBACZ PODGLĄD"
                                : "TRWA GENEROWANIE..."}
                            </Button>
                          </div>
                        </GLabel>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              {!!total && page !== total && (
                <div
                  onClick={() => onPageChange(page + 1)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50px",
                  }}
                >
                  <img alt={"expand"} src={"/static/icon/expand.png"} />
                </div>
              )}
            </div>
          )}
        </Card>,
        desktop ? undefined : (
          <Button
            style={{
              maxHeight: "40px",
              minHeight: "40px",
              width: "100%",
            }}
            styles={defaultButton}
            variant={"contained"}
            onClick={() => history.push("/panel/certs/new")}
          >
            WYGENERUJ CERTYFIKATY
          </Button>
        ),
      ]}
    />
  );
}

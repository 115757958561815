import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import API from "../../API";

function Logout({}: ILogout) {
  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    localStorage.removeItem("comp");
    localStorage.removeItem("logintime");
    API.token = null;
    API.refreshToken = null;
    window.location.href = "/logowanie";
  }, []);

  return <div></div>;
}

export interface ILogout {}

export default Logout;

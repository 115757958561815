import React, { useState } from "react";
import styled from "styled-components";
import { PlainLayout } from "../../components/Layout";
import { Card, HeaderLabel, Link, Links } from "./Login";
import { TextInput } from "../../components/Common/TextInput";
import { Button } from "../../components/Common";
import { defaultButton } from "../../components/Common/Button";
import { LoginForm } from "../../containers/Forms";
import { useAppDispatch } from "../../store";
import { resetPassword } from "../../store/reducers/authReducer";

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  margin-top: 15px;
  width: 270px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #1b607b;
`;

const Info = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #1b607b;
  padding-bottom: 5px;
`;

function ResetPassword({}: IResetPassword) {
  const [email, setValue] = useState("");
  const [error, setError] = useState(false);
  const [info, setInfo] = useState(false);
  const dispatch = useAppDispatch();

  const sumbitPassword = () => {
    if (!email) {
      return setError(true);
    }
    dispatch(
      resetPassword({
        email: email,
        returnUrl: window.location.origin + "/change-password",
      })
    ).then((p) => {
      setInfo(true);
      setValue("");
    });
  };
  return (
    <PlainLayout>
      <Card>
        <HeaderLabel>Zapomniałeś hasła?</HeaderLabel>
        <Label>
          Podaj adres e-mail użyty do rejestracji. Wyślemy wiadomość z
          instrukcjami dotyczącymi resetowania hasła.
        </Label>
        <TextInput
          label={"Adres e-mail"}
          placeholder={"Adres e-mail"}
          wrapperStyle={{ marginTop: "15px" }}
          value={email}
          error={error && "Uzupełnij pole"}
          onChange={(e: any) => setValue(e.target.value)}
        />
        {info && <Info>Sprawdź swoją skrzynkę mailową.</Info>}
        <Button
          style={{ maxHeight: "45px", minHeight: "45px", width: "100%" }}
          styles={defaultButton}
          variant={"contained"}
          onClick={sumbitPassword}
        >
          RESETUJ HASŁO
        </Button>
        <Links>
          <Link to={"/rejestracja"}>Zarejestruj się</Link>
          <Link to={"/logowanie"}>Wróć do logowania</Link>
        </Links>
      </Card>
    </PlainLayout>
  );
}

export interface IResetPassword {}

export default ResetPassword;

import * as React from "react";
import { ColLayout } from "../../components/Layout/ColLayout";
import { Card } from "../../components/Card";
import { FAQ as FAQC } from "../../components/Widgets/FAQ";
import { useDesktop } from "../../components/Responsive";
import { Button } from "../../components/Common";
import { defaultButton } from "../../components/Common/Button";
import { setOrderModal } from "../../store/reducers/orderReducer";
import { useAppDispatch } from "../../store";

export function FAQ({}) {
  const desktop = useDesktop();
  const dispatch = useAppDispatch();
  return (
    <ColLayout
      colCount={2}
      cols={[
        <Card
          full={desktop}
          HeaderProps={{
            variant: "filled",
            label: desktop ? "FAQ" : "Najważniejsze pytania",
            center: !desktop,
            style: {
              borderRadius: "0",
            },
          }}
        >
          <div style={{ width: desktop ? "450px" : "100%" }}>
            <FAQC id={0} />
          </div>
        </Card>,
        desktop ? undefined : (
          <Button
            style={{
              maxHeight: "40px",
              minHeight: "40px",
              width: "100%",
            }}
            styles={defaultButton}
            variant={"contained"}
            onClick={() => dispatch(setOrderModal({ open: true }))}
          >
            KUP NOWE DRZEWA
          </Button>
        ),
      ]}
    />
  );
}

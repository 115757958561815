import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import API from "../../API";
import { Button } from "../../components/Common";
import { outlinedStyle } from "../../components/Common/Button";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  deletePublicLogo,
  fetchPublic,
  updatePublic,
} from "../../store/reducers/publicReducer";
import { getLogoImg } from "../../utils/urls";
import { useDesktop } from "../../components/Responsive";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;

  color: #1b607b;
`;

const Img = styled.img`
  margin-top: 15px;
  max-width: 250px;
  max-height: 110px;
  object-fit: contain;
`;

const SubLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-top: 8px;
  color: #1b607b;
`;

const Header = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  color: #1b607b;
`;

const ButtonWrapper = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

function LogoForm({ label, refresh = true, setLoading }: ILogoForm) {
  const [logo, setLogo] = useState("");
  const inputRef = useRef((null as unknown) as HTMLInputElement);

  const companyId = useAppSelector((state) => state.auth.companyId);
  const publicData = useAppSelector((state) => state.public.public);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (companyId) {
      dispatch(fetchPublic({}));
    }
  }, [companyId]);

  const onFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e?.target?.files[0]) {
      const file = e?.target?.files[0];
      const formData = new FormData();
      formData.append("logo", e?.target?.files[0]);
      if (setLoading) {
        setLoading(true);
      }
      API.addLogo(formData).then(async (r) => {
        const response = await r.json();
        if (r.ok) {
          setLogo(URL.createObjectURL(file));
          dispatch(
            updatePublic({
              id: publicData?.id,
              logo: response?.data?.id,
              companyName: publicData?.companyName,
              companyDescription: publicData?.companyDescription,
              companyHeading: publicData?.companyHeading,
            } as any)
          ).then(() => {
            if (refresh) {
              dispatch(fetchPublic({})).then(() => {
                if (setLoading) {
                  setLoading(false);
                }
              });
            }
          });
        } else {
          if (refresh) {
            dispatch(fetchPublic({})).then(() => {
              if (setLoading) {
                setLoading(false);
              }
            });
          }
        }
      });
    }
  };

  const choseFile = () => {
    inputRef?.current?.click();
  };
  const deleteLogo = () => {
    setLogo("");
    // @ts-ignore
    inputRef.current.value = "";
    if (setLoading) {
      setLoading(true);
    }
    dispatch(
      updatePublic({
        id: publicData?.id,
        companyName: publicData?.companyName,
        companyDescription: publicData?.companyDescription,
        companyHeading: publicData?.companyHeading,
        logo: 0,
      } as any)
    ).then(() => {
      dispatch(fetchPublic({})).then(() => {
        if (setLoading) {
          setLoading(false);
        }
      });
    });
    dispatch(deletePublicLogo());
  };

  const desktop = useDesktop();

  return (
    <Wrapper>
      <Header>Logo firmowe</Header>
      <Label dangerouslySetInnerHTML={{ __html: label }} />
      {logo ? (
        <Img src={logo} alt={"logotype"} />
      ) : publicData?.companyAvatar ? (
        <Img
          src={getLogoImg(publicData?.companyAvatar?.file)}
          alt={"logotype"}
        />
      ) : (
        <></>
      )}
      <input
        onChange={onFile}
        accept="image/x-png,image/jpeg,image/png"
        ref={inputRef}
        style={{ visibility: "hidden", height: "0" }}
        type={"file"}
      />
      <ButtonWrapper>
        {(logo || publicData?.companyAvatar) && (
          <Button
            style={{
              width: desktop ? "155px" : "100%",
              marginTop: "15px",
              maxHeight: "33px",
              minHeight: "33px",
            }}
            variant={"text"}
            styles={css`
              & .MuiButton-label {
                font-weight: 500;
                font-size: 14px;
              }
            `}
            color={"#33addc"}
            onClick={deleteLogo}
          >
            USUŃ LOGOTYP
          </Button>
        )}
        <Button
          style={{
            width: desktop ? "155px" : "100%",
            marginTop: "15px",
            maxHeight: "33px",
            minHeight: "33px",
          }}
          styles={outlinedStyle}
          variant={"outlined"}
          onClick={choseFile}
        >
          {logo || publicData?.companyAvatar ? "ZMIEŃ PLIK" : "WYBIERZ PLIK"}
        </Button>
      </ButtonWrapper>
      <SubLabel>
        Zalecamy wgranie pliku bez tła w kształcie prostokąta.
      </SubLabel>
    </Wrapper>
  );
}

export interface ILogoForm {
  label: string;
  refresh?: boolean;
  setLoading?: (val: boolean) => void;
}

export default LogoForm;

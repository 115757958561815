import * as React from "react";
import { Button as MUIButton, ButtonProps } from "@material-ui/core";
import styled, { css } from "styled-components";

export const defaultButton = css`
  min-height: 40px;
  border-radius: 0;
  && {
    background-color: #33addc;
    &:hover {
      background-color: #1b607b;
    }
  }

  & .MuiButton-label {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    text-align: center;
    letter-spacing: 0.041em;
    text-transform: uppercase;

    color: #ffffff;
  }
`;

export const outlinedStyle = css`
  min-height: 40px;

  && {
    border: 2px solid #33addc !important;
    &:hover {
      border: 2px solid #1b607b !important;
    }
  }

  & .MuiButton-label {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.041em;
    white-space: nowrap;
    text-transform: uppercase;

    color: #33addc;
  }
`;

const StyledButton = styled(MUIButton)<ButtonProps>`
  border-radius: 0 !important;
  & .MuiButton-label {
    font-family: Roboto, sans-serif;
    font-style: normal;
    white-space: nowrap;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;

    color: ${(props) => (props.color ? props.color : "#1b617b")};
  }
  ${(props: any) => props.styles};
  text-transform: none !important;
`;

export function Button(props: any) {
  return <StyledButton {...props} />;
}

import * as React from "react";
import { useForm } from "react-hook-form";
import { IForm } from "./index";
import { TextInput } from "../../components/Common/TextInput";
import { Button, Checkbox } from "../../components/Common";
import { useAppSelector } from "../../store";
import { defaultButton } from "../../components/Common/Button";
import { Link } from "../../pages/Login/Login";
import styled from "styled-components";


const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 127%;
  /* identical to box height, or 32px */

  text-align: center;

  color: #1b607b;
`;

const Label2 = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 10px;
  line-height: 150%;
  /* or 18px */

  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 7px;
  color: #1b607b;
`;


const fields = [
  {
    name: "companyName",
    label: "Nazwa firmy",
    type: "text",
    placeholder: "Nazwa użytkownika",
    rules: {
      required: true,
    },
  },
  {
    name: "email",
    label: "Firmowy adres email",
    type: "text",
    placeholder: "Firmowy adres email",
    rules: {
      required: true,
    },
  },
  {
    name: "phone",
    label: "Numer telefonu",
    type: "text",
    placeholder: "Numer telefonu",
    rules: {
      required: true,
    },
  },
  {
    name: "password",
    label: "Hasło",
    type: "text",
    placeholder: "Hasło",
    rules: {
      required: true,
      validate: (value: string) => {
        const re = new RegExp(
          /(?=.*\d)(?=.*[a-z])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{6,}/
        );
        if (value === "" || value == null) {
          return true;
        }
        return re.test(value);
      },
    },
  },
  {
    name: "agreement",
    label:
      'Potwierdzam, że zapoznałem się i akceptuję <a onclick="(function(e) { e.stopPropagation(); return true;} )(arguments[0]); return true;" href="https://posadzimy.pl/wp-content/uploads/2021/08/Regulamin-serwisu-internetowego-1.pdf" target="_blank">regulamin serwisu</a> internetowego oraz jego <a onclick="(function(e) { e.stopPropagation(); return true;} )(arguments[0]); return true;" href="https://posadzimy.pl/wp-content/uploads/2021/08/Polityka-prywatnosci-serwisu-internetowego.pdf" target="_blank">politykę prywatności.</a>',
    type: "checkbox",
    rules: {
      required: true,
    },
  },
  {
    name: "isAgreementForNewsletter",
    label:
      "Chcę otrzymywać Newsletter z najciekawszymi informacjami dotyczącymi projektu, promocjami i treściami edukacyjnymi. W każdej chwili możesz się z niego wpisać.",
    type: "checkbox",
  },
];

export function RegisterForm({
  children,
  onSubmit,
  initial = undefined,
}: IRegisterForm) {
  const { register, handleSubmit, setValue, errors, watch, reset } = useForm();
  const error = useAppSelector((state) => state.auth.failedRegister);

  const handleChange = (e: any) => {
    setValue(e.target.name, e.target.value);
  };

  React.useEffect(() => {
    fields.forEach((field) => {
      register(field.name, field.rules);
    });
  }, []);

  React.useEffect(() => {
    if (initial) {
      reset(initial);
    }
  }, [initial]);

  return (
    <div style={{display: 'flex', justifyContent: 'center', flexDirection:'column', alignItems: 'center'}}>
      <Label>ZAREJESTRUJ SIĘ ZA DARMO</Label>
      <Label2>Potwierdzenie rejestracji otrzymasz przez email.</Label2>

      <form
        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        onSubmit={handleSubmit(watch("agreement") ? onSubmit : () => {})}
      >
        {fields.map((field, i) =>
          field.type === "text" ? (
            <TextInput
              wrapperStyle={
                i === 0 ? { marginTop: "0", width: "300px" } : { width: "300px" }
              }
              label={field.label}
              name={field.name}
              placeholder={field.placeholder}
              type={i == 3 ? "password" : "text"}
              error={
                (errors[field.name] &&
                  (i == 3
                    ? "Hasło musi się składać z min 8 znaków, 1 specjalnego i cyfry"
                    : "Uzupełnij pole")) ||
                (error &&
                  i === 1 &&
                  ((error as any)?.message?.includes("exists")
                    ? "Ten adres email jest zajęty"
                    : "Ten adres email jest nieprawidłowy"))
              }
              key={field.name}
              onChange={handleChange}
            />
          ) : (
            <Checkbox
              name={field.name}
              key={field.name}
              label={field.label}
              error={errors[field.name]}
              onChange={handleChange}
              defaultValue={false}
              labelStyle={{ fontSize: "11px" }}
              wrapperStyle={{ marginTop: "25px", maxWidth: "300px" }}
            />
          )
        )}
        <Button
            style={ {
              maxHeight: "45px",
              minHeight: "45px",
              marginTop: "25px",
              width: "350px",
            } }
            type={ "submit" }
            styles={ defaultButton }
            variant={ "contained" }
        >
          ZAREJESTRUJ SIĘ ZA DARMO
        </Button>
        <Link to={ "/logowanie" }>Masz już konto? Zaloguj się.</Link>
      </form>
    </div>
  );
}

export interface IRegisterForm extends IForm {}

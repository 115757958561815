import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
`;
const Logo = styled.img`
  width: 250px;
  height: 60px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/static/img/bg_img.jpg");
  background-repeat: no-repeat;
  height: 100%;
`;

function PlainLayout({ children }: IPlainLayout) {
  return (
    <Wrapper>
      <Header>
        <Logo src={"/static/img/logo_alpha.png"} />
      </Header>
      <Container>{children}</Container>
    </Wrapper>
  );
}

export interface IPlainLayout {
  children: React.ReactNode;
}

export default PlainLayout;

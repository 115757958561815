import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import styled, { css } from "styled-components";
import { ChangeEvent, useEffect } from "react";
import { AccordionProps } from "@material-ui/core/Accordion/Accordion";
import { useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "../../store";
import { fetchFAQ } from "../../store/reducers/faqReducer";

const StyledAccordion = styled(Accordion)<AccordionProps>`
  box-shadow: none;

  & .MuiButtonBase-root {
    padding: 0;
  }

  &:before {
    display: none;
  }

  &.Mui-expanded {
    margin: 0 !important;
    min-height: 44px !important;
  }

  & .MuiAccordionDetails-root {
    background-color: #ebf2f4;
    padding: 16px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #1b607b;
  }

  & .MuiAccordionSummary-content {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin: 10px 0px !important;
    display: flex;
    align-items: center;

    color: #1b607b;

    ${(props) =>
      props.expanded
        ? css`
            font-weight: 500;
            font-size: 14px;
          `
        : ""}
  }
`;

const mockFAQ = [
  {
    q: "Ile kosztuje posadzenie drzewa",
    a: `<div style="display: flex; color: #1B607B; font-family: Roboto, sans-serif; font-style: normal;">
        <style>
        ul {
  list-style: none;
  font-family: Roboto,sans-serif;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 150%;
}

ul li:before {
  content: '✓';
  margin-right: 5px;
}
</style>
        <div style="margin-right: 25px">
        <div style="font-weight: 900; font-size: 40px;">9.90<span style="font-size: 20px;">zł</span></div>
        <div style="font-size: 18px;">netto/sztuka</div>
        <div style="font-size: 13px;">+23% VAT</div>
</div>
    <div>
      <ul>
        <li>przygotowanie terenu</li>
        <li>sadzonka</li>
        <li>posadzenie</li>
        <li>certyfikat</li>
      </ul>
    </div>  
</div>`,
  },
  {
    q: "Ile lat będą rosły moje drzewa?",
    a:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ornare mi odio, sed blandit nunc efficitur a. Quisque orci justo, venenatis eu lectus sit amet, consequat volutpat lorem.",
  },
  {
    q: "Jakie gatunki będą sadzone?",
    a:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ornare mi odio, sed blandit nunc efficitur a. Quisque orci justo, venenatis eu lectus sit amet, consequat volutpat lorem.",
  },
  {
    q: "Czy mogę odwiedzić las który wsparłem?",
    a:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ornare mi odio, sed blandit nunc efficitur a. Quisque orci justo, venenatis eu lectus sit amet, consequat volutpat lorem.",
  },
  {
    q: "Czy mogę odwiedzić las który wsparłem?",
    a:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ornare mi odio, sed blandit nunc efficitur a. Quisque orci justo, venenatis eu lectus sit amet, consequat volutpat lorem.",
  },
];

const Wrapper = styled.div`
  display: flex;
  padding: 0 15px 15px;
  flex-direction: column;
  @media (max-width: 1024px) {
    padding: 0 15px 30px;
  }
`;

export function FAQ({ id, defaultOpen = true, data = mockFAQ }: any) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const fags = useAppSelector((state) => state.faq.faqs);
  const auth = useAppSelector((state) => state.auth.auth);

  useEffect(() => {
    if (auth) {
      dispatch(fetchFAQ({}));
    }
  }, [auth]);

  const params = new URLSearchParams(location.search);

  const [expanded, setExpanded] = React.useState<string | boolean>(
    "faq" + (params.get("item") || defaultOpen ? "0" : "-1")
  );

  const handleChange = (faq: string) => (
    event: ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? faq : false);
  };

  return (
    <Wrapper>
      {(id === 0
        ? fags.reduce((a, b) => [...a, ...b.faqs] as any, [])
        : fags.find((f) => f.id == id)?.faqs
      )?.map((faq: any, i: number) => (
        <StyledAccordion
          square={true}
          elevation={0}
          expanded={expanded === "faq" + i}
          onChange={handleChange("faq" + i)}
        >
          <AccordionSummary>
            {expanded === "faq" + i ? (
              <Remove style={{ marginRight: "5px" }} />
            ) : (
              <Add style={{ marginRight: "5px" }} />
            )}{" "}
            {faq.question}
          </AccordionSummary>
          <AccordionDetails
            dangerouslySetInnerHTML={{ __html: faq.answer.b64decode() }}
          />
        </StyledAccordion>
      ))}
    </Wrapper>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { IResponse } from "../../API";
import { RootState } from "../index";
import { PaymentType } from "../../containers/Widgets/InvoicesWidget";
import { saveAs } from "file-saver";
export interface IInvoice {
  id: number;
  invoiceNumber: number;
  invoiceRealNumber: string;
  invoiceType: PaymentType;
  isPaid: boolean;
  orderID: number;
  createdAt: BDate;
  maturity: BDate;
}

export interface BDate {
  date: string;
  timezone: string;
  timezone_type: number;
}

export const fetchInvoice = API.getRequestThunk<IResponse<IInvoice>>(
  "invoice/fetchInvoice",
  "/user/{id}/invoices",
  (state: RootState) => state.auth.userId || ""
);

export const getInvoicePdf = createAsyncThunk<any, IInvoice>(
  "invoice/getPDF",
  async (invoice, ThunkAPI) => {
    const response = await API.getInvoicePDF(invoice);
    const json = await response.blob();
    const url = window.URL.createObjectURL(json);

    // const linkSource = `data:application/pdf;base64,${json.data?.invoicePDFFile}`;
    const fileName = invoice.invoiceRealNumber.toString();

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.target = "_blank";
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    // } else {
    //   saveAs(linkSource, fileName);
    // }
  }
);

interface InvoiceState {
  invoices: IInvoice[] | null;
  loading: boolean;
  requested: boolean;
  page: number;
  total: number;
}

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    invoices: null,
    page: 1,
    total: 1,
    loading: false,
    requested: false,
  } as InvoiceState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInvoice.fulfilled, (state, action) => {
      state.invoices = action.payload.data.items;
      state.total = action.payload.data.totalPages;
      state.page = action.payload.data.currentPage;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = invoiceSlice.actions;

export default invoiceSlice.reducer;

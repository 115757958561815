import * as React from "react";
import { Card } from "../../components/Card";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../store";
import { getLogoImg } from "../../utils/urls";
import { useEffect } from "react";
import { fetchPlant } from "../../store/reducers/groupReducer";

const Wrapper = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #1b607b;
`;
const SubTitle = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #1b617b;
`;
const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  margin-top: 25px;
  display: flex;
  align-items: center;

  color: #1b607b;
`;
const Divider = styled.div`
  margin-top: 17px;
  margin-bottom: 14px;
  border-bottom: 1px solid #ebf2f4;
`;
const Img = styled.img`
  width: 236.86px;
  height: 112.04px;
`;
const Bold = styled.span`
  font-weight: bold;
  font-size: 18px;
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const plantMock = {
  title: "Wiosna 2021",
  subTitle: "Województwo Śląskie - gmina Ogrodzieniec",
  img: "/static/img/plant_map.png",
};

export function PlantWidget({ treeCount = 0 }: IPlantWidget) {
  const data = useAppSelector((state) => state.group.plant);
  const auth = useAppSelector((state) => state.auth.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth) {
      dispatch(fetchPlant({}));
    }
  }, [auth]);

  return (
    <Card
      HeaderProps={{
        variant: "plain",
        label: "Najbliższe sadzenia",
        icon: "location",
      }}
    >
      <Wrapper>
        <Title>{data?.name}</Title>
        <SubTitle
          dangerouslySetInnerHTML={{ __html: data?.description || "" }}
        />
        <Label>
          Liczba twoich drzew:&nbsp;
          <Bold
            className={"counter number"}
            style={{ "--percent": treeCount } as any}
          />
        </Label>
        <Divider></Divider>
        <ImgWrapper>
          {data?.plantImage?.file && (
            <Img alt={"plant img"} src={getLogoImg(data?.plantImage?.file)} />
          )}
        </ImgWrapper>
      </Wrapper>
    </Card>
  );
}

export interface IPlantWidget {
  data?: PlantNews;
  treeCount?: number;
}

export interface PlantNews {
  title: string;
  subTitle: string;
  img: string;
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API";
import { ICompany } from "./companyReducer";
import { IInvoice } from "./invoiceReducer";
import { PaymentType } from "../../containers/Widgets/InvoicesWidget";

export interface IOrder {
  customerName: string;
  customerAddress: string;
  customerZipCode: string;
  customerCity: string;
  customerVatNumber: string;
  paymentMethod: "payu_company" | "cash";
  quantity: number;
  paymentReturnURL: string;
  treeGroupId?: number;
}

interface OrderState {
  treeCount: number;
  countType: CountType;
  paymentRedirectURL: string;
  invoiceId?: number | null;
  orderId?: number | null;
  paymentId?: number | null;
  paymentType?: PaymentType;
  orderModal: boolean;
  orderGroup?: null | string | number;
}

export enum CountType {
  Select,
  Input,
}

export const createOrder = API.createRequestThunk<IOrder>(
  "order/createOrder",
  "/order/"
);

export const retryPayment = createAsyncThunk<
  any,
  { invoice: IInvoice; returnUrl: string }
>("order/retryPayment", async ({ invoice, returnUrl }, ThunkAPI) => {
  const response = await API.retryPayment(invoice, returnUrl);
  return await response.json();
});

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    treeCount: 20,
    countType: CountType.Select,
    paymentRedirectURL: "",
    invoiceId: null,
    orderId: null,
    paymentId: null,
    paymentType: 0,
    orderModal: false,
    orderGroup: null,
  } as OrderState,
  reducers: {
    setTreeCount: (state, action) => {
      if (action.payload.count > 0) {
        state.treeCount = action.payload.count;
        state.countType = action.payload.type;
      }
    },
    setOrder: (state, action) => {
      state.paymentType = action.payload.paymentType;
    },
    setOrderModal: (state, action) => {
      state.orderModal = action.payload.open;
      state.orderGroup = action.payload.group;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.paymentRedirectURL = action.payload.data?.paymentRedirect;
      state.invoiceId = action.payload.data?.invoiceId;
      state.orderId = action.payload.data?.orderId;
      state.paymentId = action.payload.data?.paymentId;
    });
    builder.addCase(retryPayment.fulfilled, (state, action) => {
      state.paymentRedirectURL = action.payload.data?.paymentRedirect;
    });
  },
});

export const { setTreeCount, setOrder, setOrderModal } = orderSlice.actions;

export default orderSlice.reducer;

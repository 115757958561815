import React, { useEffect, useState } from "react";
import { formatPrice, toNumber } from "../../utils/price";
import { Button } from "../Common";
import { defaultButton } from "../Common/Button";
import styled, { css } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../store";
import { fetchTreeStats } from "../../store/reducers/userReducer";

const Price = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  color: #1b607b;
  min-width: 300px;
`;

const Payment = styled.div`
  display: flex;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  align-items: flex-end;
  margin-top: 18px;
  color: #1b607b;
`;

const Icon = styled.img`
  margin-left: 12px;
  width: 35px;
  height: 18px;
`;

const Wrapper = styled.div`
  background: #ebf2f4;
  display: flex;
  padding: 7px 14px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  flex-direction: column;
  /* or 16px */
  margin-top: 5px;
  color: #1b607b;
`;

function PurchaseFromInfo({
  modal,
  ecommerce = false,
  error = false,
  submitting,
  submit = () => {}
}: IPurchaseFromInfo) {
  const priceNet = useAppSelector((state) =>
    toNumber(state.user.user?.currentNetTreePrice?.amount || "0")
  );

  const dispatch = useAppDispatch();

  const [stats, setStats] = useState({
    totalTreesStatArea: 0,
    totalTreesStatCO: 0,
    totalTrees: 0,
    totalTreesStatOxygen: 0,
    totalTreesStatPaper: 0,
  });

  const count = useAppSelector((state) => state.order.treeCount);

  useEffect(() => {
    dispatch(fetchTreeStats({ treeCount: count })).then((data) => {
      setStats(data.payload.data);
    });
  }, [count]);

  return (
    <>
      <Wrapper>
        <div>Liczba drzew:&nbsp;{count}</div>
        <div>
          Powierzchnia lasu:&nbsp;
          {Number(stats.totalTreesStatArea).toFixed(2).replace(/\.00$/, "")} m2
        </div>
        <div>
          Tlen dla:{" "}
          {Number(stats.totalTreesStatOxygen).toFixed(2).replace(/\.00$/, "")}{" "}
          osób rocznie
        </div>
        <div>
          Zwrócisz:{" "}
          {Number(stats.totalTreesStatPaper).toFixed(2).replace(/\.00$/, "")} kg
          papieru
        </div>
        <div style={{ fontWeight: 700 }}>
          Cena: {formatPrice(priceNet * count)} netto /{" "}
          {formatPrice(priceNet * count * 1.23)} brutto
        </div>
      </Wrapper>
      {/*<Price style={modal ? { marginTop: "10px" } : {}}>*/}
      {/*  Suma: */}
      {/*</Price>*/}
      <Payment>
        Płatności obsługuje: <Icon src={"/static/icon/payu.png"} />
        <Icon src={"/static/icon/blik.png"} />
      </Payment>

      <Button
        style={{ marginTop: "32px", width: "100%" }}
        styles={defaultButton}
        variant={"contained"}
        type={"submit"}
        onClick={submit("payu_company")}
        name={"payu_company"}
        disabled={error}
      >
        {ecommerce ? "KUP DRZEWA PRZEZ PAYU" : "KUP DRZEWA"}
      </Button>
      <Button
        style={{ width: "100%", height: "40px" }}
        styles={css`
          & .MuiButton-label {
            font-weight: 500;
            font-size: 14px;
          }
        `}
        color={"#33addc"}
        name={"cash"}
        onClick={submit("cash")}
        type={"submit"}
        disabled={error || submitting}
      >
        LUB WYGENERUJ PROFORMĘ
      </Button>
    </>
  );
}

export interface IPurchaseFromInfo {
  modal?: boolean;
  submit?: any;
  error?: boolean;
  ecommerce?: boolean;
  submitting?: boolean;
}

export default PurchaseFromInfo;

import * as React from "react";
import styled, { css } from "styled-components";
import { useDesktop } from "../Responsive";
import { useAppSelector } from "../../store";
import { use100vh } from "react-div-100vh";

const Wrapper = styled.div<IColLayoutStyle>`
  box-sizing: border-box;

  display: grid;
  grid-template-columns: ${(props) =>
    props.overrideCol
      ? props.overrideCol
      : Array.from(new Array(props.colCount))
          .map((_) => "1fr")
          .join(" ")};
  padding-right: 13px;

  column-gap: ${(props) => (props.withoutSpacing ? "0" : "17px")};

  ${(props) => props.hideScroll ? css`
  
    &::-webkit-scrollbar {
      display: none;
    }
  
  `:''}
  
  ${(props) =>
    !props.nested
      ? css`
          padding-left: 21px;
          top: 58px;
          overflow-y: auto;
          width: calc(100% - 200px);
          position: fixed;
          height: calc(${props.height ? props.height + "px" : "100vh"} - 58px);
        `
      : ``};

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    width: 100%;
    left: 0;
    padding-left: 13px;
    overflow-y: auto;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    height: calc(
      ${(props) => (props.height ? props.height + "px" : "100vh")} - 75px
    );

    ${(props) =>
      props.nested
        ? css`
            padding: 0px;
          `
        : ``};
  }
`;

const TreesWidget = styled.div`
  background: #1b607b;
  border-radius: 5px;
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Count = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 130.27%;
  /* or 45px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  /* or 24px */

  display: flex;
  align-items: center;

  color: #ffffff;
`;

export function ColLayout({
  cols,
  colCount,
  withoutSpacing = false,
  disableWidget = false,
  overrideCol = undefined,
  nested = false,
  hideScroll = false,
  wrapperStyle = {},
}: IColLayout) {
  const desktop = useDesktop();
  const trees = useAppSelector((state) => state.user.user?.totalPlantedTrees);
  const height = use100vh();
  return (
    <Wrapper
      height={height}
      style={wrapperStyle}
      withoutSpacing={withoutSpacing}
      colCount={colCount}
      overrideCol={overrideCol}
      nested={nested}
      hideScroll={hideScroll}
    >
      {!desktop && !disableWidget && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TreesWidget>
              <Count>{trees}</Count>
              <Label>posadzonych drzew</Label>
            </TreesWidget>
            <div>
              <img
                style={{
                  marginTop: "-5px",
                  marginBottom: "10px",
                }}
                alt={"tirangle"}
                src={"/static/img/triangle.png"}
              />
            </div>
          </div>
        </div>
      )}
      {cols.map((c) => (
        <div>{c}</div>
      ))}
    </Wrapper>
  );
}

export interface IColLayoutStyle {
  withoutSpacing?: boolean;
  colCount: number;
  height?: number | null;
  overrideCol?: string;
  nested?: boolean;
  hideScroll?: boolean;
}

export interface IColLayout extends IColLayoutStyle {
  wrapperStyle?: React.CSSProperties;
  cols: React.ReactNode[];
  disableWidget?: boolean;
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { IResponse } from "../../API";
import { RootState } from "../index";
import { BDate } from "./invoiceReducer";

export interface ICert {
  id: number;
  numberOfTrees: string;
  description: string;
  createdAt: BDate;
  companyName: string;
  totalUserBoughtTrees: string;
  totalBroughtTrees: string;
  certInfoPaper: string;
  certInfoOxygen: string;
  certInfoArea: string;
  certInfoCO2: string;
  companyLogo: string;
  certificatePDFFile?: string;
}

export interface CertTemplate {
  htmlContent: string;
  htmlContentIndividual: string;
}

export const fetchCerts = API.getRequestThunk<IResponse<ICert>>(
  "cert/fetchCerts",
  "/user/{id}/certificates",
  (state) => state.auth.userId || ""
);

export const fetchCertTemplate = API.getRequestThunk<IResponse<CertTemplate>>(
  "cert/fetchCertTemplate",
  "/certificate_template/current_active"
);

export const requestGenerateCert = API.createRequestThunk(
  "cert/generateCert",
  "/certificate/"
);

export const requestCertZip = API.createRequestThunk(
  "cert/requestZip",
  "/certificate/make_zip"
);

export const requestCertPdf = API.createRequestThunk(
  "cert/requestPdf",
  "/certificate/{id}/generate_pdf",
  (_) => 0
);

interface CertState {
  certs: ICert[] | null;
  loading: boolean;
  requested: boolean;
  generatedCount: number;
  certificateZipUrl: null | string;
  generatedCerts: ICert[] | null;
  page: number;
  total: number;
  certTemplate: CertTemplate | null;
  formState: {
    lang: string;
    certCount: number;
    certTrees: number;
    description: string;
    errors: {
      lang: boolean | string;
      certCount: boolean | string;
      certTrees: boolean | string;
      description: boolean | string;
    };
  };
}

const formState = localStorage.getItem('cert_form')

export const certSlice = createSlice({
  name: "cert",
  initialState: {
    certs: null,
    certTemplate: null,
    loading: false,
    requested: false,
    page: 1,
    total: 1,
    generatedCount: 0,
    generatedCerts: null,
    certificateZipUrl: null,
    formState: formState? JSON.parse(formState) : {
      certCount: 1,
      certTrees: 1,
      description: "",
      lang: "PL",
      errors: {
        lang: false,
        certCount: false,
        certTrees: false,
        description: false,
      },
    },
  } as CertState,
  reducers: {
    setCertForm: (state, action) => {
      state.formState = { ...state.formState, ...action.payload };
    },
    clearCerts: (state) => {
      state.generatedCerts = null;
      state.certificateZipUrl = null;
    },
    incrementCertCount: (state) => {
      state.generatedCount = state.generatedCount + 1;
    },
    resetCounter: (state) => {
      state.generatedCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCerts.fulfilled, (state, action) => {
      state.certs = action.payload.data.items;
      state.total = action.payload.data.totalPages;
      state.page = action.payload.data.currentPage;
    });
    builder.addCase(requestGenerateCert.fulfilled, (state, action) => {
      state.generatedCerts = action.payload?.data?.items;
    });
    builder.addCase(requestCertZip.fulfilled, (state, action) => {
      state.certificateZipUrl = action.payload.data?.certificateZipUrl;
    });
    builder.addCase(fetchCertTemplate.fulfilled, (state, action) => {
      state.certTemplate = action.payload.data?.item;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setCertForm,
  clearCerts,
  incrementCertCount,
  resetCounter,
} = certSlice.actions;

export default certSlice.reducer;

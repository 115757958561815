import React from "react";
import { useForm } from "react-hook-form";
import { TextInput } from "../../components/Common/TextInput";
import { TextAreaInput } from "../../components/Common";
import { IForm } from "./index";
import { NumberInput } from "../../components/Common/NumberInput";

const fields = [
  {
    name: "count",
    label: "Liczba certyfikatów",
    type: "number",
    placeholder: 1,
    rules: {
      required: true,
    },
  },
  {
    name: "count",
    label: "Liczba drzew na certyfikacie",
    type: "number",
    placeholder: 1,
    rules: {
      required: true,
    },
  },
  {
    name: "description",
    label: "Twój tekst (maksymalnie 150 znaków)",
    type: "textarea",
    placeholder: "Gratulujemy",
    rules: {
      required: true,
    },
  },
];

function NewCertForm({ children, onSubmit }: INewCertForm) {
  const { register, handleSubmit, setValue, errors } = useForm();
  const handleChange = (e: any) => {
    setValue(e.target.name, e.target.value);
  };

  React.useEffect(() => {
    fields.forEach((field) => {
      register(field.name, field.rules);
    });
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field, i) =>
        field.type === "number" ? (
          <NumberInput
            style={{ marginTop: "0" }}
            label={field.label}
            name={field.name}
            placeholder={field.placeholder}
            error={errors[field.name]}
            ref={register(field.rules)}
            key={field.name}
            onChange={handleChange}
          />
        ) : (
          <TextAreaInput
            wrapperStyle={i === 0 ? { marginTop: "0" } : {}}
            label={field.label}
            name={field.name}
            placeholder={field.placeholder}
            error={errors[field.name]}
            ref={register(field.rules)}
            key={field.name}
            onChange={handleChange}
          />
        )
      )}
      {children}
    </form>
  );
}

export interface INewCertForm extends IForm {}

export default NewCertForm;

import { CircularProgress } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div<IAlertStyle>`
  display: flex;
  align-items: center;
  background-color: #ebf2f4;
  padding: 10px 6px;
  justify-content: ${(props) => (props.loading ? "center" : "unset")};
`;

const Icon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

const Label = styled.div<IAlertStyle>`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  /* or 16px */

  color: ${(props) => props.color};
`;

export function Alert({
  label,
  style,
  loading,
  iconStyle,
  labelStyle,
  color = "#e30613",
}: IAlert) {
  return (
    <Wrapper style={style} loading={loading}>
      {loading ? (
        <CircularProgress size={16} />
      ) : (
        <>
          <Icon
            style={iconStyle}
            src={
              color === "#62A30D"
                ? "/static/icon/alert3.svg"
                : "/static/icon/alert.svg"
            }
            alt={"alert icon"}
          />
          <Label
            style={labelStyle}
            color={color}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </>
      )}
    </Wrapper>
  );
}

export interface IAlertStyle {
  color?: string;
  loading?: boolean;
}

export interface IAlert extends IAlertStyle {
  label: string;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
}

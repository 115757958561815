import * as React from "react";
import { ChangeEvent, useEffect, useState } from "react";
import { ColLayout } from "../../components/Layout/ColLayout";
import { Table } from "../../components/Table";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../store";
import { Button } from "../../components/Common";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { createGroup, fetchGroups, fetchGroupsEcommerce, updateGroup, } from "../../store/reducers/groupReducer";
import { Accordion, AccordionDetails, AccordionSummary, IconButton, } from "@material-ui/core";
import { TextInput } from "../../components/Common/TextInput";
import { defaultButton, outlinedStyle } from "../../components/Common/Button";
import { setOrderModal } from "../../store/reducers/orderReducer";
import { useDesktop } from "../../components/Responsive";
import { TabbedCard } from "../../components/Card/TabbedCard";
import GroupRow from "../../components/Table/GroupRow";
import { fetchUser } from "../../store/reducers/userReducer";

const AccLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  /* or 22px */
  width: 100%;
  color: #1b607b;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const GLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: column;

  font-size: 14px;
  line-height: 150%;
  padding: 10px 0px;
  /* or 21px */

  border-bottom: 1px solid #ebf2f4;
  color: #1b607b;
`;

const AccSummary = styled(AccordionSummary)`
  background-color: #ebf2f4 !important;
`;

export function GroupList({}) {
  const userId = useAppSelector((state) => state.auth.userId);
  const groups = useAppSelector((state) => state.group.groups);
  const groupsEcommerce = useAppSelector(
    (state) => state.group.groupsEcommerce
  );
  const total = useAppSelector((state) => state.group.total);
  const page = useAppSelector((state) => state.group.page);

  const user = useAppSelector((state) => state.user.userData);

  const [activeTab, setActiveTab] = useState(0);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(fetchGroups({}));
      dispatch(fetchUser({}));
    }
  }, [userId]);

  useEffect(() => {
    if (activeTab) {
      dispatch(fetchGroupsEcommerce({}));
    } else {
      dispatch(fetchGroups({}));
    }
  }, [activeTab]);

  useEffect(() => {
    const tab = params.get("tab");
    if (tab) {
      setActiveTab(Number(tab));
    }
  }, []);

  const addTree = (id: any) => () =>
    dispatch(setOrderModal({ open: true, group: id }));

  const desktop = useDesktop();

  const onPageChange = (page: number) => {
    if (activeTab) {
      dispatch(fetchGroupsEcommerce({ page }));
    } else {
      dispatch(fetchGroups({ page }));
    }
  };

  return (
    <ColLayout
      colCount={1}
      cols={[
        <TabbedCard
          full={desktop}
          activeTab={activeTab}
          tabs={
            user?.isECommerceModuleEnable
              ? [
                  {
                    label: "Lista sadzeń firmowych",
                    active: activeTab === 0,
                    onClick: () => {
                      setActiveTab(0);
                    },
                    action: desktop
                      ? {
                          name: "KUP NOWE DRZEWA",
                          invoke: () => {
                            dispatch(setOrderModal({ open: true }));
                          },
                        }
                      : undefined,
                  },
                  {
                    label: "Lista sadzeń przez API",
                    active: activeTab === 1,
                    onClick: () => {
                      setActiveTab(1);
                    },
                    action: desktop
                      ? {
                          name: "UTWÓRZ NOWE SADZENIE",
                          invoke: () => {
                            dispatch(createGroup({ type: 1 })).then(() => {
                              onPageChange(page);
                            });
                          },
                        }
                      : undefined,
                  },
                ]
              : [
                  {
                    label: "Lista sadzeń firmowych",
                    active: activeTab === 0,
                    onClick: () => {
                      setActiveTab(0);
                    },
                    action: desktop
                      ? {
                          name: "KUP NOWE DRZEWA",
                          invoke: () => {
                            dispatch(setOrderModal({ open: true }));
                          },
                        }
                      : undefined,
                  },
                ]
          }
          HeaderProps={{
            variant: "filled",
            label: "Lista posadzeń",
            center: !desktop,
            style: {
              borderRadius: "0",
            },
          }}
        >
          <div
            style={{
              height: "100%",
              boxShadow: "0px -11px 20px -16px rgb(0 0 0 / 25%)",
            }}
          >
            {!desktop && (!groups || groups.length === 0) && (
              <div style={{ padding: "5px 10px" }}>Brak posadzeń</div>
            )}
            {desktop ? (
              <Table
                totalPages={total}
                page={page}
                onPageChange={onPageChange}
                list={true}
                body={
                  groups &&
                  groups.map(
                    (group) => <GroupRow key={group.id} group={group} />
                    //   [
                    //   {
                    //     style: { borderLeft: "unset", paddingLeft: "0" },
                    //     content: <EditableCell key={group.id} group={group} />,
                    //   },
                    //   {
                    //     content: (
                    //       <div>
                    //         {group.orders.map((o) => (
                    //           <>
                    //             <div>{o.treeQuantity}</div>
                    //           </>
                    //         ))}
                    //       </div>
                    //     ),
                    //   },
                    //   {
                    //     content: (
                    //       <div>
                    //         {group.orders.map((o) => (
                    //           <div>
                    //             {format(
                    //               new Date(o?.createdAt?.date?.slice(0, 10)),
                    //               "dd/MM/yyyy"
                    //             )}
                    //           </div>
                    //         ))}
                    //       </div>
                    //     ),
                    //   },
                    //   {
                    //     content: (
                    //       <div>
                    //         {group.orders.map((o) => (
                    //           <div>
                    //             {o.paymentStatus == 2
                    //               ? "zapłacone"
                    //               : "do rozliczenia"}
                    //           </div>
                    //         ))}
                    //       </div>
                    //     ),
                    //   },
                    //   {
                    //     style: {
                    //       display: "flex",
                    //       justifyContent: "flex-end",
                    //       paddingTop: "7px",
                    //       paddingBottom: "7px",
                    //     },
                    //     content: (
                    //       <Button
                    //         style={{
                    //           minHeight: "26px",
                    //           maxHeight: "26px",
                    //         }}
                    //         styles={outlinedStyle}
                    //         variant={"outlined"}
                    //         onClick={addTree(group.id)}
                    //       >
                    //         DODAJ DRZEWA
                    //       </Button>
                    //     ),
                    //   },
                    // ]
                  )
                }
              />
            ) : (
              <div>
                {!!total && total !== 1 && !!page && page !== 1 && (
                  <div
                    onClick={() => onPageChange(page - 1)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "50px",
                    }}
                  >
                    <img
                      style={{ transform: "rotate(180deg)" }}
                      alt={"expand"}
                      src={"/static/icon/expand.png"}
                    />
                  </div>
                )}
                {groups &&
                  groups.map((group) => (
                    <Accordion elevation={0}>
                      <AccSummary
                        expandIcon={
                          <img alt={"expand"} src={"/static/icon/expand.png"} />
                        }
                      >
                        <AccLabel>
                          <EditableCell full={true} group={group} />
                        </AccLabel>
                        {/*{group.name}</AccLabel>*/}
                      </AccSummary>
                      <AccordionDetails>
                        <div style={{ width: "100%" }}>
                          {group.orders.map((order) => (
                            <>
                              <Grid key={order.id}>
                                <GLabel>Data zakupu</GLabel>
                                <GLabel style={{ fontWeight: 400 }}>
                                  {format(
                                    new Date(
                                      order?.createdAt?.date?.slice(0, 10)
                                    ),
                                    "dd/MM/yyyy"
                                  )}
                                </GLabel>
                                <GLabel>Liczba drzew</GLabel>
                                <GLabel style={{ fontWeight: 400 }}>
                                  {order.treeQuantity}
                                </GLabel>
                                <GLabel style={{ borderBottom: "none" }}>
                                  Status
                                </GLabel>
                                <GLabel
                                  style={{
                                    fontWeight: 400,
                                    borderBottom: "none",
                                  }}
                                >
                                  {order.paymentStatus == 2
                                    ? "zapłacone"
                                    : "do rozliczenia"}
                                </GLabel>
                              </Grid>
                              <Button
                                style={{
                                  minHeight: "40px",
                                  maxHeight: "40px",
                                  margin: "10px 0px",
                                  width: "100%",
                                }}
                                styles={outlinedStyle}
                                variant={"outlined"}
                                onClick={addTree(group.id)}
                              >
                                DODAJ DRZEWA
                              </Button>
                            </>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                {!!total && total !== 1 && !!page && page !== total && (
                  <div
                    onClick={() => onPageChange(page + 1)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "50px",
                    }}
                  >
                    <img alt={"expand"} src={"/static/icon/expand.png"} />
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            style={{
              height: "100%",
              boxShadow: "0px -11px 20px -16px rgb(0 0 0 / 25%)",
            }}
          >
            {!desktop && (!groupsEcommerce || groupsEcommerce.length === 0) && (
              <div style={{ padding: "5px 10px" }}>Brak posadzeń</div>
            )}
            {desktop ? (
              <Table
                totalPages={total}
                page={page}
                onPageChange={onPageChange}
                list={true}
                body={
                  groupsEcommerce &&
                  groupsEcommerce.map((group) => (
                    <GroupRow key={group.id} group={group} ecommerce={true} />
                  ))
                }
              />
            ) : (
              <div>
                {!!total && total !== 1 && !!page && page !== 1 && (
                  <div
                    onClick={() => onPageChange(page - 1)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "50px",
                    }}
                  >
                    <img
                      style={{ transform: "rotate(180deg)" }}
                      alt={"expand"}
                      src={"/static/icon/expand.png"}
                    />
                  </div>
                )}
                {groupsEcommerce &&
                  groupsEcommerce.map((group) => (
                    <Accordion elevation={0}>
                      <AccSummary
                        expandIcon={
                          <img alt={"expand"} src={"/static/icon/expand.png"} />
                        }
                      >
                        <AccLabel>
                          <EditableCell full={true} group={group} />
                        </AccLabel>
                      </AccSummary>
                      <AccordionDetails>
                        <div style={{ width: "100%" }}>
                          {group.eCommerceOrders.map((order: any) => (
                            <>
                              <Grid key={order.id}>
                                <GLabel>Data zakupu</GLabel>
                                <GLabel style={{ fontWeight: 400 }}>
                                  {format(
                                    new Date(
                                      order?.createdAt?.date?.slice(0, 10)
                                    ),
                                    "dd/MM/yyyy"
                                  )}
                                </GLabel>
                                <GLabel>Liczba drzew</GLabel>
                                <GLabel style={{ fontWeight: 400 }}>
                                  {order.treeQuantity}
                                </GLabel>
                                <GLabel style={{ borderBottom: "none" }}>
                                  Godzina zakupu
                                </GLabel>
                                <GLabel
                                  style={{
                                    fontWeight: 400,
                                    borderBottom: "none",
                                  }}
                                >
                                  {format(
                                    new Date(
                                      order?.createdAt?.date?.replace(" ", "T")
                                    ),
                                    "HH:mm:ss"
                                  )}
                                </GLabel>
                              </Grid>
                            </>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                {!!total && total !== 1 && !!page && page !== total && (
                  <div
                    onClick={() => onPageChange(page + 1)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "50px",
                    }}
                  >
                    <img alt={"expand"} src={"/static/icon/expand.png"} />
                  </div>
                )}
              </div>
            )}
          </div>
        </TabbedCard>,
        desktop ? undefined : (
          <Button
            style={{
              maxHeight: "40px",
              minHeight: "40px",
              width: "100%",
            }}
            styles={defaultButton}
            variant={"contained"}
            onClick={() =>
              activeTab
                ? dispatch(createGroup({ type: 1 })).then(() => {
                    onPageChange(page);
                  })
                : dispatch(setOrderModal({ open: true }))
            }
          >
            {activeTab ? "UTWÓRZ NOWE SADZENIE" : "KUP NOWE DRZEWA"}
          </Button>
        ),
      ]}
    />
  );
}

const RawText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;

export const EditableCell = ({
  group,
  full = false,
  afterEdit = () => {},
  desc = "",
}: any) => {
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState(group?.name);
  const dispatch = useAppDispatch();
  const change = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && e.target.value.length > 250) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    setValue(e.target.value);
  };

  const cancelEdit = () => {
    setValue(group?.name);
    setEditable(false);
  };
  const onEdit = (e: any) => {
    setEditable(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const update = () => {
    dispatch(
      updateGroup({
        name: value,
        description: desc || group?.description,
        id: group?.id,
      } as any)
    ).then(afterEdit);
    setEditable(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        flexWrap: full || !editable ? "unset" : "wrap",
      }}
    >
      {editable ? (
        <TextInput
          style={{
            width: full ? "95%" : "fit-content",
          }}
          wrapperStyle={{
            marginTop: "0",
            justifyContent: full ? "center" : "unset",
          }}
          onChange={change}
          value={value}
          group={true}
        />
      ) : full ? (
        <div>{value}</div>
      ) : (
        <RawText>{value}</RawText>
      )}
      {editable ? (
        <Controls cancelEdit={cancelEdit} updateGroup={update} />
      ) : (
        <div>
          <IconButton style={{ padding: "3px" }} onClick={onEdit}>
            <img alt={"icon edit2"} src={"/static/icon/edit2.png"} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export const Controls = ({ cancelEdit, updateGroup, style = {} }: any) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      style={{ display: "flex" }}
    >
      <div>
        <IconButton style={style} onClick={cancelEdit}>
          <img alt={"cancel group"} src={"/static/icon/x2.png"} />
        </IconButton>
      </div>
      <div style={{ margin: "0px 5px", borderLeft: "1px solid #EBF2F4" }} />
      <div>
        <IconButton onClick={updateGroup}>
          <img alt={"cancel group"} src={"/static/icon/check2.svg"} />
        </IconButton>
      </div>
    </div>
  );
};

import React from "react";
import { useForm } from "react-hook-form";
import { Error, TextInput } from "../../components/Common/TextInput";
import styled from "styled-components";
import { IForm } from "./index";
import { isValidNip } from "../../utils/nip";
import { ICompany } from "../../store/reducers/companyReducer";

const GroupWrapper = styled.div`
  display: flex;
`;

const fields = [
  {
    name: "companyBillingName",
    label: "Nazwa firmy",
    type: "text",
    placeholder: "Nazwa firmy",
    rules: {
      required: true,
    },
  },
  {
    name: "companyBillingAddress",
    label: "Ulica i nr domu/lokalu",
    type: "text",
    placeholder: "Ulica i nr domu/lokalu",
    rules: {
      required: true,
    },
  },
  {
    group: [
      {
        name: "companyBillingZipCode",
        label: "Kod pocztowy",
        type: "text",
        placeholder: "00-000",
        rules: {
          required: true,
        },
      },
      {
        name: "companyBillingCity",
        label: "Miasto",
        type: "text",
        placeholder: "Miasto",
        rules: {
          required: true,
        },
      },
    ],
  },
  {
    name: "companyBillingVATNumber",
    label: "NIP",
    type: "text",
    placeholder: "NIP",
    rules: {
      required: true,
      validate: (value: string) => isValidNip(value),
    },
  },
];

function CompanyInfoForm({
  children,
  onSubmit,
  initial,
  externalErrors = "",
}: ICompanyInfoForm) {
  const { register, handleSubmit, setValue, errors, reset } = useForm();
  const handleChange = (e: any) => {
    setValue(e.target.name, e.target.value);
  };

  React.useEffect(() => {
    fields.forEach((field) => {
      if ("group" in field) {
        field?.group?.forEach((f) => register(f.name, f.rules));
      } else {
        register(field.name, field.rules);
      }
    });
  });

  React.useEffect(() => {
    if (initial) {
      reset(initial);
    }
  }, [initial]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field, i) =>
        "group" in field ? (
          <div key={"field" + i}>
            <GroupWrapper>
              {field?.group?.map((f, i) => (
                <TextInput
                  wrapperStyle={
                    i === 0
                      ? { marginRight: "16px", width: "40%", minWidth: "90px" }
                      : {}
                  }
                  label={f.label}
                  name={f.name}
                  placeholder={f.placeholder}
                  error={errors[f.name]}
                  ref={register(f.rules)}
                  key={f.name}
                  onChange={handleChange}
                  group={true}
                  defaultValue={initial && initial[f.name]}
                />
              ))}
            </GroupWrapper>
            <Error
              error={
                !!errors["companyBillingZipCode"] ||
                !!errors["companyBillingCity"] ||
                externalErrors?.includes("Kod pocztowy")
              }
            >
              {errors["companyBillingZipCode"] || errors["companyBillingCity"]
                ? "Aby dokonać transakcji uzupełnij brakujące pola."
                : externalErrors?.includes("Kod pocztowy")
                ? "Nieprawidłowy kod pocztowy"
                : " "}
            </Error>
          </div>
        ) : (
          <TextInput
            wrapperStyle={i === 0 ? { marginTop: "0" } : {}}
            label={field.label}
            name={field.name}
            placeholder={field.placeholder}
            error={
              errors[field.name] ||
              (field.name === "companyBillingVATNumber" &&
                externalErrors?.includes("VAT") &&
                "Niepoprawny numer VAT")
            }
            ref={register(field.rules)}
            key={field.name}
            onChange={handleChange}
            defaultValue={initial && initial[field.name]}
          />
        )
      )}
      {children}
    </form>
  );
}

export interface ICompanyInfoForm extends IForm {}

export default CompanyInfoForm;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { IResponse } from "../../API";
import { RootState } from "../index";

export interface IPublic {
  companyName: string;
  companyDescription: string;
  companyWordpressURL?: string;
  companyHeading?: string;
  id: number;
  companyAvatar: {
    id: number;
    name: string;
    file: string;
    type: string;
  } | null;
  logo?: number;
}

export const fetchPublic = API.getRequestThunk<IResponse<IPublic>>(
  "public/fetchPublic",
  "/user_company/public/",
  (state: RootState) => state.auth.companyId || ""
);

export const updatePublic = API.updateRequestThunk<IPublic>(
  "public/updatePublic",
  "/user_company/public/"
);

interface PublicState {
  public: IPublic | null;
  loading: boolean;
  requested: boolean;
}

export const publicSlice = createSlice({
  name: "public",
  initialState: {
    public: null,
    loading: false,
    requested: false,
  } as PublicState,
  reducers: {
    deletePublicLogo: (state) => {
      if (state.public) {
        state.public.companyAvatar = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePublic.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePublic.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updatePublic.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchPublic.fulfilled, (state, action) => {
      state.loading = false;
      state.public = action.payload.data.item;
    });
  },
});

// Action creators are generated for each case reducer function
export const { deletePublicLogo } = publicSlice.actions;

export default publicSlice.reducer;

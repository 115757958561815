import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { IResponse } from "../../API";
import { RootState } from "../index";

export interface IUser {
  totalPlantedTrees: number;
  totalPlantedTreesInCurrentPlantAction: number;
  totalGeneratedCertificates: number;
  currentGrossTreePrice: Price;
  currentNetTreePrice: Price;
  totalPlantedTreesStatArea: number;
  totalPlantedTreesStatCO: number;
  totalPlantedTreesStatOxygen: number;
  totalPlantedTreesStatPaper: number;
  eCommerceCredits: number;
  eCommerceSoldTreeInCurrentMonth: string;
}

export interface IUserData {
  id: number;
  isECommerceModuleEnable: boolean;
  group: { id: number };
  apiToken: string;
}

export interface Price {
  amount: string;
  currency: string;
}

export const fetchStats = API.getRequestThunk<{ data: IUser }>(
  "user/fetchStats",
  "/user/{id}/stats",
  (state: RootState) => state.auth.userId || ""
);

export const fetchUser = API.getRequestThunk<{ data: IUserData }>(
  "user/fetchUser",
  "/user/{id}",
  (state: RootState) => state.auth.userId || ""
);

export const updateUser = API.updateRequestThunk<IUserData>(
  "user/updateUser",
  "/user"
);

export const updateEcommerceState = API.updateStatus();

export const fetchTreeStats = API.createRequestThunk<{ treeCount: number }>(
  "user/fetchTreeStats",
  "/statistics/calculate_tree_statistics"
);

interface UserState {
  user: IUser | null;
  userData: IUserData | null;
  loading: boolean;
  requested: boolean;
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    userData: null,
    loading: false,
    requested: false,
  } as UserState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStats.fulfilled, (state, action) => {
      state.user = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.userData = (action?.payload?.data as any)?.item;
      state.loading = false;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchUser.pending, (state, action) => {
      state.loading = true;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = userSlice.actions;

export default userSlice.reducer;

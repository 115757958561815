import { Dashboard } from "./pages/Dashboard";
import React from "react";
import { FAQ } from "./pages/FAQ";
import { PaymentList, Thankyou } from "./pages/Payment";
import { CompanyInfo, CompanySite } from "./pages/Company";
import { Widgets } from "./pages/Widgets";
import { CertsList, NewCert } from "./pages/Certs";
import { GroupList } from "./pages/Group";

const routes: Route[] = [
  {
    to: "/panel/dashboard",
    label: "Główna",
    component: <Dashboard />,
  },
  {
    to: "/panel/list",
    label: "Lista posadzeń",
    component: <GroupList />,
  },
  {
    to: "/panel/company",
    label: "Twoja strona firmowa",
    component: <CompanySite />,
  },
  {
    to: "/panel/widget",
    label: "Widgety",
    component: <Widgets />,
  },
  {
    to: "/panel/info",
    label: "Dane firmowe",
    component: <CompanyInfo />,
  },
  {
    to: "/panel/certs",
    label: "Certyfikaty",
    component: <CertsList />,
  },
  {
    to: "/panel/certs/new",
    component: <NewCert />,
  },
  {
    to: "/panel/payments",
    label: "Płatności",
    component: <PaymentList />,
  },
  {
    to: "/panel/faq",
    label: "FAQ",
    component: <FAQ />,
  },
];
export interface Route {
  to: string;
  label?: string;
  component?: React.ReactNode;
}
export default routes;

import React, { useRef, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Box = styled.div<{ checked: boolean }>`
  width: 15px;
  min-width: 15px;
  height: 15px;
  min-height: 15px;
  border: 1px solid #a4c3cb;
  box-sizing: border-box;
  margin-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background: ${(props) => (props.checked ? "#1B607B" : "unset")};
`;
const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  user-select: none;

  color: #1b607b;
`;

function Checkbox({
  wrapperStyle,
  label,
  onChange,
  name,
  labelStyle,
  error,
  ...rest
}: any) {
  const inputRef = useRef((null as unknown) as HTMLInputElement);
  const [checked, setChecked] = useState(false);

  const click = () => {
    const formInput = inputRef.current as HTMLInputElement;
    const event = new MouseEvent("click");
    formInput.dispatchEvent(event);
    onChange({ target: { name, value: !checked } });
    setChecked(!checked);
  };

  return (
    <Wrapper style={wrapperStyle}>
      <Box checked={checked} onClick={click}>
        {checked && (
          <img alt={"checkbox icon"} src={"/static/icon/checkbox.png"} />
        )}
      </Box>
      <Label style={labelStyle} onClick={click}>
        <span dangerouslySetInnerHTML={{ __html: label }} />{" "}
        {error && (
          <span style={{ color: "#e30613", fontWeight: "bold" }}>*</span>
        )}
      </Label>
      <input
        name={name}
        ref={inputRef}
        style={{ visibility: "hidden" }}
        type={"checkbox"}
        defaultChecked={false}
        {...rest}
      />
    </Wrapper>
  );
}

export default Checkbox;

import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { PaymentType } from "../../containers/Widgets/InvoicesWidget";
import { Button } from "../../components/Common";
import { defaultButton, outlinedStyle } from "../../components/Common/Button";
import { NavLink, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { getTreeString } from "../../utils/dict";
import { getInvoicePdf, IInvoice } from "../../store/reducers/invoiceReducer";
import { Layout } from "../../components/Layout";
import { useCertHTML, replaceInCert } from "../Cert";
// @ts-ignore
import Pdf from "react-to-pdf";
import {
  clearCerts,
  requestCertPdf,
  requestCertZip,
  requestGenerateCert,
  resetCounter,
} from "../../store/reducers/certReducer";
import { fetchStats } from "../../store/reducers/userReducer";
import { useDesktop } from "../../components/Responsive";
import { fetchCompany } from "../../store/reducers/companyReducer";
import { fetchPublic } from "../../store/reducers/publicReducer";
import { retryPayment } from "../../store/reducers/orderReducer";
import API from "../../API";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 58px);
  bottom: 0;
  position: absolute;
  background-image: url("/static/img/bg_payment.jpg");
  background-repeat: no-repeat;
  background-size: cover;
`;

const ContentWrapper = styled.div`
  padding: 90px 40px 0;
  @media (max-width: 1024px) {
    padding-top: 25px;
  }
`;

const Header = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 25px;

  color: #1b607b;
`;

const Icon = styled.img`
  width: 105px;
  height: 105px;
  margin-top: 20px;
`;

const Info = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #1b607b;
  margin-top: 30px;
  max-width: 480px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`;

const paymentSummary = {
  trees: 0,
  totaltrees: 400,
  paymentType: 0,
  info: `<span style="font-weight: 700;">Posadziłeś 1000 drzew!</span> Już niedługo na Twoich drzewach pojawią się pierwsze liście, a za jakiś czas możesz usiąść w ich cieniu.
<span style="font-weight: 700;">Teraz możesz odebrać swój certyfikat lub go spersonalizować</span> (nadać nazwę lub określić ilość drzew).`,
};

export function Thankyou({}: IThankyou) {
  const paymentType = useAppSelector((state) => state.order.paymentType);
  const invoiceId = useAppSelector((state) => state.order.invoiceId);
  const ref = useRef((null as unknown) as HTMLDivElement);
  const [cert, setCert] = useState({} as any);
  const [generated, setGenerated] = useState(false);
  const generatedCerts = useAppSelector((state) => state.cert.generatedCerts);
  const dispatch = useAppDispatch();
  const localtion = useLocation();
  const params = new URLSearchParams(localtion.search);
  const total = Number(params.get("total"));
  const trees = Number(params.get("trees"));
  const companyId = useAppSelector((state) => state.auth.companyId);
  const company = useAppSelector((state) => state.company.company);
  const publicCompany = useAppSelector((state) => state.public.public);
  const certificateZipUrl = useAppSelector(
    (state) => state.cert.certificateZipUrl
  );
  const paymentRedirect = useAppSelector(
    (state) => state.order.paymentRedirectURL
  );

  const certHTML = useCertHTML(0, localStorage.getItem("token") || "");

  // useEffect(() => {
  //   if (certificateZipUrl) {
  //     let link = document.createElement("a");
  //     link.download = certificateZipUrl;
  //     link.href = process.env.REACT_APP_CERTS + "/" + certificateZipUrl;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //
  //     dispatch(clearCerts());
  //     dispatch(fetchStats({}));
  //   }
  // }, [certificateZipUrl]);

  const error = params.get("error");
  const orderId = params.get("orderId");
  const type = params.get("orderType");

  const info =
    type == "credits"
      ? `Kupiłeś ${trees} kredytów!`
      : paymentType == PaymentType.Transfer || (orderId && !total)
      ? `Już niedługo posadzisz ${trees} ${getTreeString(trees)}!`
      : `<span style="font-weight: 700;">Posadziłeś ${total} ${getTreeString(
          total
        )}!</span> Już niedługo na Twoich drzewach pojawią się pierwsze liście, a za jakiś czas możesz usiąść w ich cieniu.
<span style="font-weight: 700;">Teraz możesz odebrać swój certyfikat lub go spersonalizować</span> (nadać nazwę lub określić ilość drzew).`;

  useEffect(() => {
    if (companyId) {
      dispatch(fetchCompany({}));
      dispatch(fetchPublic({}));
    }
  }, [companyId]);

  const desktop = useDesktop();

  const requestPDF = () => {
    dispatch(
      getInvoicePdf({
        invoiceRealNumber: invoiceId,
        invoiceNumber: invoiceId,
        id: invoiceId,
      } as any)
    );
  };

  useEffect(() => {
    if (paymentRedirect && !paymentRedirect.includes(window.location.origin)) {
      window.location.href = paymentRedirect;
    }
  }, [paymentRedirect]);

  // useEffect(() => {
  //   if (generatedCerts?.length === 0) {
  //     return;
  //   }
  //   if (generatedCerts) {
  //     dispatch(
  //       requestCertZip({
  //         certIDs: generatedCerts.map((c) => c.id),
  //       })
  //     );
  //   }
  // }, [generatedCerts]);

  const generateCerts = () => {
    if (!generated) {
      setGenerated(true);
      dispatch(
        requestGenerateCert({
          numberOfCertificates: Number(1),
          numberOfTrees: Number(trees),
          description: "",
        })
      );
    }
  };

  useEffect(() => {
    if (generatedCerts?.length === 0) {
      return;
    }
    if (generatedCerts) {
      if (generatedCerts[0]) {
        setCert({
          ...generatedCerts[0],
          companyName: company?.companyBillingName || "",
          companyLogo: publicCompany?.companyAvatar,
        });

        dispatch(requestCertPdf({ id: generatedCerts[0].id })).then(
          (data: any) => {
            const url = data.payload.data.certificatePDFUrl;

            var link = document.createElement("a");

            link.href = url;
            link.download = generatedCerts[0].id + "cert.pdf";
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            dispatch(clearCerts());
            dispatch(fetchStats({}));
          }
        );
      } else {
        dispatch(clearCerts());
        dispatch(fetchStats({}));
      }
    }
  }, [generatedCerts]);

  const clearCertsxD = () => {
    dispatch(clearCerts());
    dispatch(fetchStats({}));
  };

  const retry = (invoice: IInvoice) => () => {
    dispatch(
      retryPayment({
        invoice,
        returnUrl:
          window.location.origin + `/thank-you?total=${total}&trees=${trees}`,
      })
    );
  };

  return (
    <Layout>
      <div style={{ position: "absolute", left: "-3000px" }}>
        <Pdf
          onComplete={clearCertsxD}
          scale={0.64}
          targetRef={ref}
          filename={cert?.id}
        >
          {({ toPdf }: any) => (
            <div
              ref={ref as any}
              onClick={toPdf}
              dangerouslySetInnerHTML={{
                __html: replaceInCert(certHTML.htmlContent || "", cert),
              }}
            />
          )}
        </Pdf>
      </div>
      <Wrapper>
        <ContentWrapper>
          <Header>{error ? "Niepowodzenie" : "Gratulujemy!"}</Header>
          <Icon src={"/static/icon/seed.svg"} alt={"seed icon"} />
          <Info
            dangerouslySetInnerHTML={{
              __html: error ? "Twoja płatność nie powiodła się." : info,
            }}
          />
          <ButtonWrapper>
            {error ? (
              <>
                <Button
                  style={{
                    marginRight: "25px",
                    width: desktop ? "210px" : "100%",
                    maxHeight: "33px",
                    minHeight: "33px",
                  }}
                  styles={defaultButton}
                  variant={"contained"}
                  onClick={retry({ orderID: orderId } as any)}
                >
                  Ponów płatność
                </Button>
              </>
            ) : paymentType === PaymentType.Online && type !== "credits" ? (
              <>
                <Button
                  style={{
                    marginRight: "25px",
                    width: desktop ? "210px" : "100%",
                    maxHeight: "33px",
                    minHeight: "33px",
                  }}
                  styles={defaultButton}
                  variant={"contained"}
                  onClick={generateCerts}
                >
                  POBIERZ DLA SIEBIE
                </Button>
                <Button
                  component={NavLink}
                  to={"/panel/certs/new"}
                  style={{
                    width: desktop ? "210px" : "100%",
                    marginTop: desktop ? "0" : "15px",
                    maxHeight: "33px",
                    minHeight: "33px",
                  }}
                  styles={outlinedStyle}
                  variant={"outlined"}
                >
                  PERSONALIZUJ
                </Button>
              </>
            ) : paymentType === PaymentType.Online ? (
              <></>
            ) : (
              <>
                <Button
                  style={{
                    marginRight: "25px",
                    width: desktop ? "210px" : "100%",
                    maxHeight: "33px",
                    minHeight: "33px",
                  }}
                  styles={defaultButton}
                  variant={"contained"}
                  onClick={requestPDF}
                >
                  POBIERZ FAKTURĘ
                </Button>
              </>
            )}
          </ButtonWrapper>
          {type == "credits" ? (
            <>
              <Button
                style={{ marginTop: "10px" }}
                variant={"text"}
                component={NavLink}
                to={"/panel/ecommerce"}
              >
                &lt; IDŹ DO PANELU ECOMMERCE
              </Button>
            </>
          ) : paymentType === PaymentType.Online && !error ? (
            <>
              <Button
                style={{ marginTop: "10px" }}
                variant={"text"}
                component={NavLink}
                to={"/panel/certs"}
              >
                &lt; IDŹ DO LISTY CERTYFIKATÓW
              </Button>
            </>
          ) : (
            <>
              <Button
                style={{ marginTop: "10px" }}
                variant={"text"}
                component={NavLink}
                to={"/panel/list"}
              >
                &lt; IDŹ DO LISTY POSADZIEŃ
              </Button>
            </>
          )}
        </ContentWrapper>
      </Wrapper>
    </Layout>
  );
}

export interface IThankyou {}

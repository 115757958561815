import React from "react";
import styled from "styled-components";
import { LoginForm } from "../../containers/Forms";
import { Button } from "../../components/Common";
import { defaultButton } from "../../components/Common/Button";
import { NavLink } from "react-router-dom";
import { PlainLayout } from "../../components/Layout";
import { TextInput } from "../../components/Common/TextInput";
import { useAppDispatch } from "../../store";
import { loginUser } from "../../store/reducers/authReducer";

export const Card = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 100px 40px;

  @media (max-width: 1024px) {
    padding: 28px 20px 40px;
  }
`;

export const HeaderLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 127%;
  /* or 32px */

  text-align: center;

  color: #1b607b;
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Link = styled(NavLink)`
  text-decoration: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  display: flex;
  align-items: center;
  text-align: right;
  margin-top: 5px;
  color: #1b607b;

  &:hover {
    text-decoration: underline;
  }
`;

function Login({}: ILogin) {
  const dispatch = useAppDispatch();
  const onSubmit = (values: any) => {
    dispatch(loginUser(values));
  };

  return (
    <PlainLayout>
      <Card>
        <HeaderLabel>Zaloguj się</HeaderLabel>
        <LoginForm onSubmit={onSubmit}>
          <Button
            style={{ maxHeight: "45px", minHeight: "45px", width: "100%" }}
            type={"submit"}
            styles={defaultButton}
            variant={"contained"}
          >
            ZALOGUJ
          </Button>
          <Links>
            <Link to={"/rejestracja"}>Zarejestruj się</Link>
            <Link to={"/zapomniane-haslo"}>Zapomniałeś hasła?</Link>
          </Links>
        </LoginForm>
      </Card>
    </PlainLayout>
  );
}

export interface ILogin {}

export default Login;

import * as React from "react";
import { useForm } from "react-hook-form";
import { IForm } from "./index";
import { TextInput } from "../../components/Common/TextInput";
import { TextAreaInput } from "../../components/Common";

const fields = [
  {
    name: "companyName",
    label: "Nazwa firmy",
    type: "text",
    placeholder: "Nazwa firmy",
    rules: {
      required: true,
    },
  },
  {
    name: "companyHeading",
    label: "Tytuł (nagłówek) widoczny nad opisem firmy",
    type: "text",
    placeholder: "Nagłówek",
    rules: {
      required: false,
    },
  },
  {
    name: "companyDescription",
    label: "Opis twojej firmy lub podejmowanych działań",
    type: "textarea",
    placeholder: "Opis",
  },
];

export function CompanySiteForm({
  children,
  onSubmit,
  initial,
}: ICompanySiteForm) {
  const { register, handleSubmit, watch, setValue, errors, reset } = useForm();
  const handleChange = (e: any) => {
    setValue(e.target.name, e.target.value);
  };

  React.useEffect(() => {
    fields.forEach((field) => {
      register(field.name, field.rules);
    });
  });

  React.useEffect(() => {
    if (initial) {
      reset(initial);
    }
  }, [initial]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field, i) =>
        field.type === "text" ? (
          <TextInput
            wrapperStyle={i === 0 ? { marginTop: "0" } : {}}
            label={field.label}
            value={watch(field.name)}
            name={field.name}
            placeholder={field.placeholder}
            error={
              errors[field.name] ? "Aby zapisać zmiany uzupełnij pole" : false
            }
            ref={register(field.rules)}
            key={field.name}
            onChange={handleChange}
            defaultValue={initial && initial[field.name]}
          />
        ) : (
          <TextAreaInput
            wrapperStyle={i === 0 ? { marginTop: "0" } : {}}
            label={field.label}
            name={field.name}
            value={watch(field.name)}
            placeholder={field.placeholder}
            error={
              errors[field.name] ? "Aby zapisać zmiany uzupełnij pole" : false
            }
            ref={register(field.rules)}
            key={field.name}
            onChange={handleChange}
            defaultValue={initial && initial[field.name]}
          />
        )
      )}
      {children}
    </form>
  );
}

export interface ICompanySiteForm extends IForm {}

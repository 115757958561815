import React from "react";
import { Card } from "../../components/Card";
import styled, { css } from "styled-components";
import { useAppSelector } from "../../store";
import { useDesktop } from "../../components/Responsive";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 8px 0;

  &:last-child {
    border-right: unset;
  }
`;

const ItemWapper = styled.div<{ widget?: boolean }>`
  flex-basis: 145px;
  border-right: ${({ widget }) => (widget ? "unset" : "1px solid #ebf2f4")};
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Img = styled.img<{ widget?: boolean; big?: boolean }>`
  margin-top: 5px;

  ${({ widget, big }) => {
    if (big)
      return css`
        width: 90px;
        height: 90px;
      `;
    if (widget)
      return css`
        width: 80px;
        height: 80px;
      `;

    return css`
      width: 50px;
      height: 50px;
    `;
  }};
`;

const Counter = styled.div<{ big?: boolean }>`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 130%;
  /* or 32px */
  margin-top: ${({ big }) => (big ? "5" : "10")}px;
  margin-bottom: 10px;
  color: #1b607b;
`;

const Surfix = styled.p<{ big?: boolean }>`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  margin-top: ${({ big }) => (big ? "5" : "10")}px;
  margin-bottom: 10px;
  display: block;
  /* or 32px */
  & > sup {
    vertical-align: super;
    font-size: smaller;
  }
  color: #1b607b;
`;

const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */
  margin-bottom: 10px;
  text-align: center;
  max-width: 95px;
  color: #1b607b;
  & > sup {
    vertical-align: super;
    font-size: smaller;
  }
`;

export const WrapItem = ({
  icon,
  title,
  count,
  surfix,
  style,
  widget,
  big,
}: any) => {
  return (
    <ItemWapper widget={widget} style={style}>
      <Img widget={widget} big={big} src={icon} alt={title} />
      <div style={{ display: "flex" }}>
        <Counter big={big}>
          {Number(count).toFixed(2).replace(/\.00$/, "")}
        </Counter>
        <Surfix big={big}>{surfix}</Surfix>
      </div>
      <Title>{title}</Title>
    </ItemWapper>
  );
};

function StatWidget({}: IStatWidget) {
  const stats = useAppSelector((state) => state.user.user);

  const {
    totalPlantedTreesStatArea,
    totalPlantedTreesStatCO,
    totalPlantedTrees,
    totalPlantedTreesStatOxygen,
    totalPlantedTreesStatPaper,
  } = stats || {
    totalPlantedTreesStatArea: 0,
    totalPlantedTreesStatCO: 0,
    totalPlantedTrees: 0,
    totalPlantedTreesStatOxygen: 0,
    totalPlantedTreesStatPaper: 0,
  };

  const dekstop = useDesktop();

  return (
    <Card
      HeaderProps={{
        variant: "plain",
        label: "Twoje drzewa w liczbach:",
        centerLabel: true,
      }}
      wrapperStyle={{
        marginRight: dekstop ? "13px" : "0px",
        boxShadow: "0px 4px 18px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Wrapper>
        <WrapItem
          title={<>Powierzchnia lasu</>}
          surfix={
            <>
              m<sup>2</sup>
            </>
          }
          count={totalPlantedTreesStatArea}
          icon={"/static/img/w_area.svg"}
        />
        <WrapItem
          title={
            <>
              Pochłanianie CO<sup>2</sup>/rok
            </>
          }
          surfix={
            <>
              kg<sup>&nbsp;</sup>
            </>
          }
          count={totalPlantedTreesStatCO}
          icon={"/static/img/w_co2.svg"}
        />
        <WrapItem
          title={<>Liczba drzew</>}
          count={totalPlantedTrees}
          icon={"/static/img/w_tree.svg"}
        />
        <WrapItem
          title={<>Tlen dla osób rocznie</>}
          count={totalPlantedTreesStatOxygen}
          icon={"/static/img/w_people.svg"}
        />
        <WrapItem
          title={<>Ilość zwróconego papieru</>}
          surfix={
            <>
              kg<sup>&nbsp;</sup>
            </>
          }
          count={totalPlantedTreesStatPaper}
          style={{ borderRight: "unset" }}
          icon={"/static/img/w_paper.svg"}
        />
      </Wrapper>
    </Card>
  );
}

export interface IStatWidget {}

export default StatWidget;

import * as React from "react";
import styled from "styled-components";
import { Card } from "../../components/Card";
import { Button } from "../../components/Common";
import { outlinedStyle } from "../../components/Common/Button";
import CompanyNameForm from "../../components/Forms/CompanyNameForm";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  padding: 15px 20px 15px 20px;
  align-items: center;
  justify-content: space-between;
`;
const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;

  display: flex;
  align-items: center;

  color: #1b607b;
`;

const Counter = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;

  display: flex;
  align-items: center;

  color: #1b607b;
  margin-left: 5px;
`;

export function CertsWidgets({ certCount = 0, certMax = 0 }: ICertsWidgets) {
  return (
    <Card
      HeaderProps={{
        variant: "plain",
        label: "Certyfikaty",
        icon: "cert",
      }}
    >
      <Wrapper>
        <div style={{ display: "flex" }}>
          <Label>Wygenerowane:</Label>
          <Counter>{`${certCount}/${certMax}`}</Counter>
        </div>
        {Number(certMax) - Number(certCount) > 0 && (
          <Button
            component={NavLink}
            to={"/panel/certs/new"}
            style={{
              width: "117px",
              maxHeight: "33px",
              minHeight: "33px",
            }}
            styles={outlinedStyle}
            variant={"outlined"}
            type={"submit"}
          >
            Wygeneruj
          </Button>
        )}
      </Wrapper>
    </Card>
  );
}

export interface ICertsWidgets {
  certCount?: number;
  certMax?: number;
}

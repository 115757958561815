import * as React from "react";
import { Card } from "../../../components/Card";
import styled from "styled-components";
import { PurchaseFirstStep } from "../../../components/Widgets/PurchaseFirstStep";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useDesktop } from "../../../components/Responsive";

const Wrapper = styled.div`
  display: flex;
  padding-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  color: #e30613;
`;

const HeaderWrapper = styled.div`
  width: 100%;
`;

const CreditWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 20px;
  margin-bottom: 12px;
  width: 100%;
  border-bottom: 1px solid #ebf2f4;
`;

const Credit = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;

  display: flex;
  align-items: center;

  color: #1b607b;
`;

const CreditInfo = styled.div`
  width: 106px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  /* or 16px */

  display: flex;
  align-items: center;

  color: #1b607b;
`;

export function EcommmercePurchaseWidget({}: any) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const desktop = useDesktop();

  return (
    <Card
      HeaderProps={{
        variant: "filled",
        label: "Twoje konto API",
        style: {},
      }}
    >
      <Wrapper style={desktop ? { minWidth: "390px" } : {}}>
        <HeaderWrapper>
          {!user?.eCommerceCredits && (
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
                marginTop: "10px",
                alignItems: "center",
                padding: "10px 20px",
                background: "#EBF2F4",
              }}
            >
              <img
                style={{
                  marginRight: "6px",
                  minWidth: "15px",
                  minHeight: "15px",
                  height: "15px",
                  width: "15px",
                }}
                src={"/static/icon/alert2.png"}
                alt={"alert icon"}
              />
              <Label>Doładuj konto żeby korzystać z modułu</Label>
            </div>
          )}
          <CreditWrapper>
            <Credit>{user?.eCommerceCredits ?? 0}</Credit>
            <CreditInfo>Liczba drzew, które możesz zasadzić </CreditInfo>
          </CreditWrapper>
        </HeaderWrapper>
        <PurchaseFirstStep ecommerce={true} modal={true} />
      </Wrapper>
    </Card>
  );
}

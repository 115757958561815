import * as React from "react";
import { ColLayout } from "../../components/Layout/ColLayout";
import { Card } from "../../components/Card";
import { WidgetTypeOne } from "./Widget/WidgetTypeOne";
import { WidgetTypeTwo } from "./Widget/WidgetTypeTwo";
import { WidgetTypeThree } from "./Widget/WidgetTypeThree";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { CodeFrame } from "./CodeFrame";
import { useAppDispatch, useAppSelector } from "../../store";
import { useDesktop } from "../../components/Responsive";
import { useMediaQuery } from "@material-ui/core";
import styled, { css } from "styled-components";
import { Button } from "../../components/Common";
import { outlinedStyle } from "../../components/Common/Button";
import { CompanySiteForm } from "../../containers/Forms/CompanySiteForm";
import { useEffect } from "react";
import { fetchStats, fetchUser } from "../../store/reducers/userReducer";

const DownloadWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 37px 20px;
`;

const PWidgets = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 420px 1fr;
  padding: 0 25px;
  margin-top: 10px;
  row-gap: 50px;
  margin-bottom: 75px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const PWidget = ({ id }: any) => {
  const download = () => {
    var link = document.createElement("a");
    link.href = `/static/download/${id}.svg`;
    link.download = id + ".svg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      style={{
        display: "flex",
        padding: "0px 6px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        width={40}
        height={53}
        src={`https://posadzimy-api.dev-effectivity.pl/assets/images/${id}.svg`}
        alt={"widget img " + id}
      />
      <Button
        onClick={download}
        styles={css`
          margin-top: 10px;
          & .MuiButton-label {
            font-weight: 500;
            font-size: 14px;
          }
        `}
        color={"#33addc"}
      >
        POBIERZ
      </Button>
    </div>
  );
};

export function Widgets({}) {
  const location = useLocation();
  const history = useHistory();
  const stats = useAppSelector((state) => state.user.user);
  const count = useAppSelector((state) => state.user.user?.totalPlantedTrees);

  let params = new URLSearchParams(location.search);

  const redirect = (type: number) => {
    history.push(`/widgety/${params.get("id")}/${type}`);
  };

  const desktop = useDesktop();
  const tablet = useMediaQuery(
    "(max-width:1250px) and (orientation: landscape)"
  );

  const Wids = (
    <ColLayout
      colCount={1}
      overrideCol={"1fr"}
      hideScroll={true}
      cols={[
        <Card
          HeaderProps={{
            variant: "filled",
            center: !desktop,
            label: "Widgety",
          }}
        >
          <DownloadWrapper>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <PWidgets>
                {Array.from(new Array(6)).map((_, i) => (
                  <PWidget key={i} id={i + 1} />
                ))}
              </PWidgets>
              <Button
                component={"a"}
                href={"/static/download/pack.zip"}
                download
                style={{
                  width: desktop ? "160px" : "100%",
                  maxHeight: "33px",
                  minHeight: "33px",
                  marginTop: "32px",
                  marginBottom: desktop ? "0" : "25px",
                }}
                styles={outlinedStyle}
                variant={"outlined"}
              >
                POBIERZ PAKIET
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: "48px",
                alignItems: "center",
              }}
            >
              <img
                src={
                  "https://posadzimy-api.dev-effectivity.pl/assets/images/big.svg"
                }
                alt={"widget img big"}
              />
              <Button
                style={{
                  width: desktop ? "140px" : "100%",
                  maxHeight: "33px",
                  minHeight: "33px",
                  marginTop: "32px",
                  marginBottom: desktop ? "0" : "25px",
                }}
                styles={outlinedStyle}
                variant={"outlined"}
                component={"a"}
                download
                href={"/static/download/big.svg"}
              >
                POBIERZ
              </Button>
            </div>
          </DownloadWrapper>
          <Grid>
            <WidgetTypeOne key={count} count={count} />
            <CodeFrame widgetType={1} />
            <WidgetTypeTwo key={count} count={count} />
            <CodeFrame widgetType={2} />
          </Grid>
          <div
            style={{
              margin: "0 25px",
              marginTop: "35px",
              marginBottom: "60px",
              overflow: "auto",
            }}
          >
            <WidgetTypeThree
              key={count}
              stats={
                stats === null
                  ? undefined
                  : {
                      totalUserPlants: stats.totalPlantedTrees,
                      totalUserPlantsStatArea: stats.totalPlantedTreesStatArea,
                      totalUserPlantsStatCO: stats.totalPlantedTreesStatCO,
                      totalUserPlantsStatOxygen:
                        stats.totalPlantedTreesStatOxygen,
                      totalUserPlantsStatPaper:
                        stats.totalPlantedTreesStatPaper,
                    }
              }
              count={count}
            />
            <CodeFrame style={{ marginTop: "40px" }} widgetType={3} />
          </div>
        </Card>,
      ]}
    />
  );

  if (!params.get("id") || !params.get("type")) {
    return Wids;
  }

  redirect(Number(params.get("type")));

  return <></>;
}

import React from "react";
import { useForm } from "react-hook-form";
import { IForm } from "./index";
import { TextInput } from "../../components/Common/TextInput";
import { Checkbox } from "../../components/Common";
import { useAppSelector } from "../../store";

const fields = [
  {
    name: "login",
    label: "Adres e-mail",
    type: "text",
    placeholder: "Adres e-mail",
    rules: {
      required: true,
    },
  },
  {
    name: "password",
    label: "Hasło",
    type: "text",
    placeholder: "Hasło",
    rules: {
      required: true,
    },
  },
  {
    name: "remember",
    label: "Zapamiętaj mnie",
    type: "checkbox",
  },
];

function LoginForm({ onSubmit, children, initial = undefined }: ILoginForm) {
  const failed = useAppSelector((state) => state.auth?.failed);
  const failedMessage = useAppSelector((state) => state.auth?.failedMessage);
  const { register, handleSubmit, setValue, errors, reset } = useForm();
  const handleChange = (e: any) => {
    setValue(e.target.name, e.target.value);
  };

  React.useEffect(() => {
    fields.forEach((field) => {
      register(field.name, field.rules);
    });
  }, []);

  React.useEffect(() => {
    if (initial) {
      reset(initial);
    }
  }, [initial]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field, i) =>
        field.type === "text" ? (
          <TextInput
            wrapperStyle={
              i === 0
                ? { marginTop: "20px", width: "300px" }
                : { marginTop: "0", width: "300px" }
            }
            label={field.label}
            name={field.name}
            placeholder={field.placeholder}
            error={
              failed
                ? failedMessage != ""
                  ? failedMessage
                  : "Nieprawidłowy login lub hasło"
                : errors[field.name]
                ? "Uzupełnij pole"
                : false
            }
            ref={register(field.rules)}
            key={field.name}
            type={i === 1 ? "password" : "text"}
            onChange={handleChange}
            defaultValue={initial && initial[field.name]}
          />
        ) : (
          <Checkbox
            ref={register(field.rules)}
            name={field.name}
            key={field.name}
            label={field.label}
            onChange={handleChange}
            defaultValue={false}
            wrapperStyle={{ marginBottom: "15px" }}
          />
        )
      )}
      {children}
    </form>
  );
}

export interface ILoginForm extends IForm {}

export default LoginForm;

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./reset.css";
import { Provider } from "react-redux";
import { store } from "./store";
import "./utils/b64";
import { Router } from "react-router-dom";
import history from "./history";
import LogRocket from "logrocket";
import TagManager from "react-gtm-module";

const isWidget = window.location.pathname.indexOf('/widgety') > -1;

if (!isWidget) {
  LogRocket.init("zhfkby/posadzimy");

  const gtmId = process.env.REACT_APP_GTM_ID;

  if (gtmId) {
      TagManager.initialize({gtmId});
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

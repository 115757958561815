import * as React from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  display: flex;
`;

const NumberElement = styled.div<{ active?: boolean; last: boolean }>`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  height: 40px;
  font-size: 14px;
  border: 1px solid #a4c3cb;
  border-right: ${(props) => (props.last ? "1px solid #a4c3cb" : "0")};
  box-sizing: border-box;

  cursor: pointer;

  ${(props) =>
    props.active
      ? css`
          color: #ffffff;
          background-color: #1b607b;
        `
      : css`
          color: #a4c3cb;
        `}
`;

export function NumberPicker({
  range = [],
  value = 0,
  onChange = () => {},
}: INumberPicker) {
  return (
    <Wrapper>
      {range.map((r, i) => (
        <NumberElement
          last={i === range.length - 1}
          onClick={(e) => onChange(r)}
          active={value === r}
        >
          {r}
        </NumberElement>
      ))}
    </Wrapper>
  );
}

export interface INumberPicker {
  range: number[];
  value: number;
  onChange?: (value: number) => void;
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { IInvoice } from "./store/reducers/invoiceReducer";
// @ts-ignore
import { fetch as fetchPolyfill } from "whatwg-fetch";
import { addHours } from "date-fns";

type IdSelector = undefined | ((state: RootState) => string | number);
type HasId = { id: number | string };
class API {
  get refreshToken(): string | null {
    return this._refreshToken;
  }

  set refreshToken(value: string | null) {
    this._refreshToken = value;
  }
  get token(): string | null {
    return this._token;
  }

  set token(value: string | null) {
    this._token = value;
  }

  get instance() {
    if (!this._instance) this._instance = new API();
    return this._instance;
  }

  getRequestThunk<T>(
    type: string,
    resource: string,
    idSelector: IdSelector = undefined
  ) {
    return createAsyncThunk<T, any, { state: RootState }>(
      type,
      async (_, thunkAPI) => {
        const url = this.buildURL(
          idSelector
            ? this.mapResourceWithId(resource, idSelector(thunkAPI.getState()))
            : resource,
          _.page
        );

        const response = await this.authorizedFetch(url, {
          method: "GET",
        });
        return await response.json();
      }
    );
  }

  createRequestThunk<T>(
    type: string,
    resource: string,
    idSelector: IdSelector = undefined
  ) {
    return createAsyncThunk<any, T>(type, async (values) => {
      const url = this.buildURL(
        idSelector
          ? this.mapResourceWithId(
              resource,
              "id" in values ? ((values as unknown) as HasId).id : ""
            )
          : resource
      );
      const response = await this.authorizedFetch(url, {
        method: "POST",
        body: JSON.stringify(values),
      });
      return await response.json();
    });
  }

  updateRequestThunk<T extends HasId>(type: string, resource: string) {
    return createAsyncThunk<any, T>(type, async (values) => {
      const url = this.buildURL(resource) + values.id;
      const r = await this.authorizedFetch(url, {
        method: "PUT",
        body: JSON.stringify(clean(values)),
      });
      return {...values, r:r};
    });
  }

  async addLogo(formData: FormData): Promise<Response> {
    return await this.authorizedFetch(this.buildURL("/upload/company_logo"), {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + this._token,
      },
    });
  }

  async getInvoicePDF(invoice: IInvoice): Promise<Response> {
    return await this.authorizedFetch(
      this.buildURL(`/invoice/${invoice.id}/pdf`),
      { method: "GET" }
    );
  }

  async getOrder(id: any): Promise<Response> {
    return await this.authorizedFetch(this.buildURL(`/order/${id}/`), {
      method: "GET",
    });
  }

  async retryPayment(invoice: IInvoice, returnUrl: string): Promise<Response> {
    return await this.authorizedFetch(
      this.buildURL(`/order/${invoice.orderID}/retry_payment`),
      {
        method: "POST",
        body: JSON.stringify({
          paymentReturnURL: returnUrl,
        }),
      }
    );
  }

  updateStatus() {
    return createAsyncThunk<any, any>(
      "user/updateEcommerceState",
      async (values) => {
        const url = this.buildURL(
          `/user/${values.id}/change_ecommerce_module_status`
        );
        const response = await this.authorizedFetch(url, {
          method: "PUT",
          body: JSON.stringify(clean(values)),
        });
        return values;
      }
    );
  }

  updateGroupStatus() {
    return createAsyncThunk<any, any>(
      "group/changeGroupStatus",
      async (values) => {
        const url = this.buildURL(
          `/tree_group​/${String(values.id)}​/change_ecommerce_status`
        );
        const response = await this.authorizedFetch(url, {
          method: "PUT",
          body: JSON.stringify(clean(values)),
        });
        return values;
      }
    );
  }

  async fetchWidget(userId: number, type: number): Promise<Response> {
    return await fetch(this.buildURL(`/widget/user_total_plants/${userId}`), {
      method: "POST",
      body: JSON.stringify({
        type: type,
        origin: window?.location?.ancestorOrigins
          ? window?.location?.ancestorOrigins[0]
          : window.location.origin,
      }),
    });
  }

  async tryAuth(): Promise<Response> {
    const refreshToken = localStorage.getItem("refreshToken");
    const logintime = localStorage.getItem("logintime");
    const date = new Date(Number(logintime));
    if (!refreshToken || (logintime && !(addHours(date, 1) > new Date()))) {
      throw new Error("Refresh token not found");
    }
    return await fetch(this.buildURL(`/auth/refresh_token`), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh_token: refreshToken,
      }),
    });
  }

  fetchCertWithAuth(certId: string, accessToken: string): Promise<Response> {
    return fetchPolyfill(this.buildURL(`/certificate/${certId}`), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*",
      },
    });
  }

  fetchCertTemplateWithAuth(accessToken: string): Promise<Response> {
    return fetchPolyfill(this.buildURL(`/certificate_template/current/0`), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Origin": "*",
      },
    });
  }

  public async createPreviewCert(data: any) {
    return await this.authorizedFetch(this.buildURL(`/certificate/preview`), {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  mapResourceWithId(resource: string, id: number | string): string {
    if (resource.includes("{id}")) {
      return resource.replace("{id}", id.toString());
    }
    return resource + id;
  }

  authorizedFetch(
    input: RequestInfo,
    init: RequestInit | undefined = undefined
  ) {
    if (!this._token) {
      throw new Error("Not authorized");
    }

    return fetch(input, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this._token,
        // "Access-Control-Allow-Origin": window.location.hostname,
      },
      ...init,
    });
  }

  buildURL(resource: string, page: number | undefined = undefined): string {
    if (page) {
      return (
        (
          process.env.REACT_APP_API +
          resource +
          (resource.includes("?") ? "&page=" : "?page=") +
          page
        )
          // eslint-disable-next-line no-control-regex
          .replace(/[^\x00-\x7F]/g, "")
      );
    }
    // eslint-disable-next-line no-control-regex
    return (process.env.REACT_APP_API + resource).replace(/[^\x00-\x7F]/g, "");
  }

  private _instance: API | null = null;
  private _token: string | null = null;
  private _refreshToken: string | null = null;
}

const _ = new API();

function clean(obj: any) {
  for (let propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      obj[propName] = "";
    }
  }
  return obj;
}

export interface IResponse<T> {
  status: number;
  data: Datum<T>;
}

export interface Datum<T> {
  currentPage: number;
  totalPages: number;
  items: T[];
  item: T;
}

export default _.instance;

import React from "react";
import { useForm } from "react-hook-form";
import { IForm } from "../../containers/Forms";
import { TextInput } from "../Common/TextInput";

const fields = [
  {
    name: "companyName",
    label:
      "Nazwa firmy /projektu \n" + "( wyświetlana na stronie i certyfikatach)",
    type: "text",
    placeholder: "Nazwa firmy/ projektu",
    rules: {
      required: true,
    },
  },
];

function CompanyNameForm({ children, initial, onSubmit }: ICompanyNameForm) {
  const { register, handleSubmit, setValue, reset, errors } = useForm();
  const handleChange = (e: any) => {
    setValue(e.target.name, e.target.value);
  };

  React.useEffect(() => {
    fields.forEach((field) => {
      register(field.name, field.rules);
    });
  });

  React.useEffect(() => {
    if (initial) {
      reset(initial);
    }
  }, [initial]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field, i) => (
        <TextInput
          wrapperStyle={i === 0 ? { marginTop: "0" } : {}}
          label={field.label}
          name={field.name}
          placeholder={field.placeholder}
          error={errors[field.name] && "Uzupełnij pole"}
          ref={register(field.rules)}
          key={field.name}
          type={field.type}
          onChange={handleChange}
          defaultValue={initial && initial[field.name]}
        />
      ))}
      {children}
    </form>
  );
}

export interface ICompanyNameForm extends IForm {}

export default CompanyNameForm;

import React, { useEffect, useState } from "react";
import { Card } from "../../../components/Card";
import styled from "styled-components";
import { Button } from "../../../components/Common";
import { defaultButton } from "../../../components/Common/Button";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  fetchUser,
  updateEcommerceState,
  updateUser,
} from "../../../store/reducers/userReducer";
import { fetchPublic } from "../../../store/reducers/publicReducer";
import {
  fetchCompany,
  ICompany,
  updateCompany,
} from "../../../store/reducers/companyReducer";
import { CompanyInfoForm } from "../../Forms";
import PurchaseFromInfo from "../../../components/Widgets/PurchaseFromInfo";

const Wrapper = styled.div`
  padding: 10px 15px 20px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  /* or 22px */

  color: #1b607b;

  /* Inside Auto Layout */

  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  color: #e30613;
`;

const WrapperCompany = styled.div`
  min-width: 60%;
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 10px 15px 25px;
  @media (max-width: 1024px) {
    width: 90%;
  }
`;

function EcommerceActivateWidget({}: IEcommerceActivateWidget) {
  const dispatch = useAppDispatch();
  const [companyInfo, setCompanyInfo] = useState(false);
  const company = useAppSelector((state) => state.company.company);
  const user = useAppSelector((state) => state.user.userData);
  const [errors, setErrors] = useState("");
  const isModal = company?.companyBillingAddress && company?.companyBillingCity;

  const activateEcommerce = () => {
    if (!isModal) {
      return setCompanyInfo(true);
    }

    if (user) {
      dispatch(
        updateEcommerceState({
          id: user.id,
          isActive: true,
        } as any)
      ).then(() => dispatch(fetchUser({})));
    }
  };

  const onSubmitForm = (values: Partial<ICompany>, e: any) => {
    if (user) {
      dispatch(updateCompany(Object.assign({}, company, values))).then(() => {
        dispatch(
          updateEcommerceState({
            id: user.id,
            isActive: true,
          } as any)
        ).then(() => dispatch(fetchUser({})));
      });
    }
  };

  return (
    <Card
      HeaderProps={{
        variant: "filled",
        label: "Sadzenie przez API",
      }}
    >
      {companyInfo ? (
        <WrapperCompany>
          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              marginTop: "10px",
              padding: "10px 20px",
              background: "#EBF2F4",
            }}
          >
            <img
              style={{
                marginRight: "6px",
                minWidth: "15px",
                minHeight: "15px",
                height: "15px",
                width: "15px",
              }}
              src={"/static/icon/alert2.png"}
              alt={"alert icon"}
            />
            <Label>Aby uruchomić moduł ecommerce uzupełnij dane firmy</Label>
          </div>
          <CompanyInfoForm
            externalErrors={errors}
            initial={company}
            onSubmit={onSubmitForm}
          >
            <Button
              style={{ marginTop: "15px", width: "100%" }}
              styles={defaultButton}
              variant={"contained"}
              type={"submit"}
            >
              URUCHOM
            </Button>
          </CompanyInfoForm>
        </WrapperCompany>
      ) : (
        <Wrapper>
          {/*<div>*/}
          {/*  <span style={{ fontWeight: "bold" }}>*/}
          {/*    Uruchom moduł ecommerce i ciesz się większą ilością zasadzonych*/}
          {/*    drzew.&nbsp;*/}
          {/*  </span>*/}
          {/*  Nunc posuere purus rhoncus pulvinar aliquam. Ut aliquet tristique*/}
          {/*  nisl vitae volutpat.*/}
          {/*</div>*/}
          <Button
            style={{
              margin: "0 auto",
              marginTop: "15px",
              maxWidth: "281px",
              minWidth: "281px",
            }}
            styles={defaultButton}
            variant={"contained"}
            onClick={activateEcommerce}
          >
            URUCHOM
          </Button>
        </Wrapper>
      )}
    </Card>
  );
}

export interface IEcommerceActivateWidget {}

export default EcommerceActivateWidget;

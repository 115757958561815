import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 11px;
`;

const Input = styled.input<{ error?: boolean }>`
  height: 40px;
  width: 100%;
  outline: unset;
  border: 1px solid ${(props) => (props.error ? "red" : "#a4c3cb")};
  text-indent: 12.2px;
  color: #1b607b;
  box-sizing: border-box;

  &:focus {
    border-color: #1b607b;
  }

  &::placeholder {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    display: flex;
    align-items: center;

    color: #a4c3cb;
  }
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  display: flex;
  align-items: center;

  color: #1b607b;
`;

export const Error = styled.div<{ error: boolean }>`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  min-height: 19px;
  display: flex;
  align-items: center;

  color: #e30613;

  visibility: ${(props) => (props.error ? "visible" : "hidden")};
`;

export function TextInput({ label, group, wrapperStyle, error, ...rest }: any) {
  return (
    <Wrapper style={wrapperStyle}>
      {label && <Label>{label}</Label>}
      <Input error={!!error} {...rest} />
      {!group && (
        <Error error={!!error}>
          {error
            ? typeof error === "string"
              ? error
              : "Aby dokonać transakcji uzupełnij brakujące pola."
            : " "}
        </Error>
      )}
    </Wrapper>
  );
}

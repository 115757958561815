import * as React from "react";
import { ColLayout } from "../../components/Layout/ColLayout";
import { FAQWidget } from "../../containers/Widgets/FAQWidget";
import { PurchaseWidget } from "../../containers/Widgets/PurchaseWidget";
import { WebsiteWidget } from "../../containers/Widgets/WebsiteWidget";
import { PlantWidget } from "../../containers/Widgets/PlantWidget";
import { CertsWidgets } from "../../containers/Widgets/CertsWidgets";
import { InvoicesWidget } from "../../containers/Widgets/InvoicesWidget";
import { useAppDispatch, useAppSelector } from "../../store";
import { useEffect } from "react";
import { fetchPublic } from "../../store/reducers/publicReducer";
import { useMediaQuery } from "@material-ui/core";
import StatWidget from "../../containers/Widgets/StatWidget";
import { useDesktop } from "../../components/Responsive";

export function Dashboard() {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.auth.companyId);
  const publicCompany = useAppSelector((state) => state.public.public);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchPublic({}));
    }
  }, [companyId]);

  const count = useAppSelector(
    (state) => state.user.user?.totalPlantedTreesInCurrentPlantAction || 0
  );
  const certsMax = useAppSelector(
    (state) => state.user.user?.totalPlantedTrees || 0
  );
  const certs = useAppSelector(
    (state) => state.user.user?.totalGeneratedCertificates
  );

  const tablet = useMediaQuery(
    "(max-width:1300px) and (orientation: landscape)"
  );

  const desktop = useDesktop();


  return (
    <ColLayout
      wrapperStyle={!desktop ? {} : { paddingTop: "10px", marginTop: "-10px" }}
      colCount={2}
      overrideCol={tablet ? "1fr 1fr" : "1fr 2fr"}
      cols={[
        <>
          <PurchaseWidget />
        </>,
        <>
          <StatWidget />
          <ColLayout
            nested={true}
            disableWidget={true}
            colCount={tablet ? 1 : 2}
            key={String(publicCompany !== null && (!publicCompany?.companyName || !publicCompany.companyDescription || !publicCompany.companyHeading || !publicCompany.companyAvatar)) + 'grid' }
            cols={
              tablet
                ? [
                    <>
                      {publicCompany !== null && (!publicCompany?.companyName || !publicCompany.companyDescription || !publicCompany.companyHeading || !publicCompany.companyAvatar)  && <WebsiteWidget />}
                      <CertsWidgets certCount={certs} certMax={certsMax} />
                      <InvoicesWidget />
                      <FAQWidget id={1} />
                      <PlantWidget treeCount={count} />
                    </>,
                  ]
                : [
                    <>
                      {publicCompany !== null && (!publicCompany?.companyName || !publicCompany.companyDescription || !publicCompany.companyHeading || !publicCompany.companyAvatar) && <WebsiteWidget />}
                      <CertsWidgets certCount={certs} certMax={certsMax} />
                      <InvoicesWidget />
                    </>,
                    <>
                      <FAQWidget id={1} />
                      <PlantWidget treeCount={count} />
                    </>,
                  ]
            }
          />
        </>,
      ]}
    />
  );
}

import React from "react";
import styled from "styled-components";
import { Card } from "../../../components/Card";
import { Button } from "../../../components/Common";
import { outlinedStyle } from "../../../components/Common/Button";
import { CompanyInfoForm } from "../../Forms";
import {
  fetchUser,
  updateEcommerceState,
} from "../../../store/reducers/userReducer";
import { useAppDispatch, useAppSelector } from "../../../store";

const Wrapper = styled.div`
  padding: 17px 13px 17px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;

  color: #1b607b;
`;

function EcommerceActionWidget({}: IEcommerceActionWidget) {
  const user = useAppSelector((state) => state.user.userData);

  const dispatch = useAppDispatch();

  const disableEcommerce = () => {
    if (user) {
      dispatch(
        updateEcommerceState({
          id: user.id,
          isActive: false,
        } as any)
      ).then(() => dispatch(fetchUser({})));
    }
  };

  return (
    <Card
      HeaderProps={{
        variant: "plain",
        label: "Akcje",
        icon: "action",
      }}
    >
      <Wrapper>
        <div>Sadzenie przez API</div>
        <Button
          style={{
            maxHeight: "33px",
            minHeight: "33px",
          }}
          styles={outlinedStyle}
          variant={"outlined"}
          onClick={disableEcommerce}
        >
          WYŁĄCZ
        </Button>
      </Wrapper>
    </Card>
  );
}

export interface IEcommerceActionWidget {}

export default EcommerceActionWidget;

import React, { useEffect } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { Layout } from "./components/Layout";
import routes from "./routes";
import { WidgetTypeOne } from "./pages/Widgets";
import { WidgetTypeTwo } from "./pages/Widgets/Widget/WidgetTypeTwo";
import { WidgetTypeThree } from "./pages/Widgets/Widget/WidgetTypeThree";
import { useAppDispatch, useAppSelector } from "./store";
import { tryAuth } from "./store/reducers/authReducer";
import { ChangePassword, Login, Logout, ResetPassword } from "./pages/Login";
import Cert from "./pages/Cert";
import Register from "./pages/Login/Register";
import history from "./history";
import { Thankyou } from "./pages/Payment";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router";
import Ecommerce from "./pages/Ecommerce/Ecommerce";
import { fetchUser } from "./store/reducers/userReducer";

const NotFound = ({ location }: any) => {
  const appRoutes = [
    ...routes.map((r) => r.to),
    "widgety",
    "logowanie",
    "zapomniane-haslo",
    "register",
    "change-password",
    "certd",
    "logout",
  ];
  if (appRoutes.find((key) => location.pathname.includes(key))) {
    return <></>;
  }
  return <Redirect to={"/"} />;
};

function App() {
  const auth = useAppSelector((state) => state.auth.auth);
  const failedAuth = useAppSelector((state) => state.auth.failedAuth);
  const dispatch = useAppDispatch();

  const location = useLocation();

  useEffect(() => {
    if (
      !auth &&
      !history.location.pathname.includes("certd") &&
      !history.location.pathname.includes("widgety") &&
      !history.location.pathname.includes("change-password") &&
      !history.location.pathname.includes("zapomniane-haslo") &&
      !history.location.pathname.includes("rejestracja")
    ) {
      const params = new URLSearchParams(location.search);

      if (params.get("accessToken")) {
        dispatch(
          tryAuth({
            accessToken: params.get("accessToken") as any,
            user: params.get("user") as any,
            refreshToken: params.get("refreshToken") as any,
            userId: params.get("userId") as any,
            companyId: params.get("companyId") as any,
          })
        );
      } else {
        dispatch(tryAuth({})).then(() => {
          dispatch(fetchUser({}));
        });
      }
    }
  }, [auth]);

  useEffect(() => {
    if (failedAuth) {
      history.push("/logowanie");
    }
  }, [failedAuth]);

  return (
    <>
      <Switch>
        <Route
          path={"/widgety/1/:userId"}
          component={WidgetTypeOne}
          exact={true}
        />
        <Route
          path={"/widgety/2/:userId"}
          component={WidgetTypeTwo}
          exact={true}
        />
        <Route
          path={"/widgety/3/:userId"}
          component={WidgetTypeThree}
          exact={true}
        />
        <Route path={"/logowanie"} component={Login} exact={true} />
        <Route
          path={"/zapomniane-haslo"}
          component={ResetPassword}
          exact={true}
        />
        <Route path={"/rejestracja"} component={Register} exact={true} />
        <Route
          path={"/change-password/:token"}
          component={ChangePassword}
          exact={true}
        />
        <Route
          path={"/certd/:certId/:accessToken"}
          component={Cert}
          exact={true}
        />
        <Route path={"/logout"} component={Logout} exact={true} />
        <Route exact={true} path={"/thank-you"} component={Thankyou} />

        <Route path={"/panel"} component={Dashboard} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
}

function Dashboard() {
  const location = useLocation();
  return (
    <Layout>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.key}>
          {routes.map((route, i) => (
            <Route
              path={route.to}
              exact={true}
              render={() => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  {route.component}
                </motion.div>
              )}
            />
          ))}
          <Route
            path={"/panel/ecommerce"}
            exact={true}
            render={() => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <Ecommerce />
              </motion.div>
            )}
          />
          <Route component={NotFound} />
        </Switch>
      </AnimatePresence>
    </Layout>
  );
}

export default App;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API";
import history from "../../history";
import { fetchStats } from "./userReducer";
import jwt_decode from "jwt-decode";
import LogRocket from "logrocket";

export const loginUser = createAsyncThunk<
  any,
  { login: string; password: string; remember: boolean }
>("auth/loginWithPassword", async ({ login, password, remember }, thunkAPI) => {
  const response = await fetch(
    process.env.REACT_APP_API + "/auth/login_check",
    {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        login: login,
        password: password,
      }),
    }
  );

  const { refresh_token, token, data } = await response.json();

  if ("isActive" in data && !data.isActive) {
    return thunkAPI.rejectWithValue("BLOCKED");
  }

  API.token = token;
  API.refreshToken = refresh_token;

  localStorage.setItem("token", token);
  localStorage.setItem("refreshToken", refresh_token);
  localStorage.setItem("user", login);

  if (!remember) {
    localStorage.setItem("logintime", new Date().getTime().toString());
  }

  return {
    user: login,
    auth: true,
    userId: data.userID,
    companyId: data.userCompanyID,
  };
});

export const tryAuth = createAsyncThunk<
  any,
  {
    accessToken?: string;
    user?: string;
    refreshToken?: string;
    userId?: string;
    companyId?: string;
  }
>("auth/tryAuth", async (values, api) => {
  if (values.accessToken && values.refreshToken) {
    API.token = values.accessToken;
    API.refreshToken = values.refreshToken;

    localStorage.setItem("token", values.accessToken);
    localStorage.setItem("refreshToken", values.refreshToken);
    localStorage.setItem("user", values.user || "");

    return {
      user: values.user,
      auth: true,
      userId: values.userId,
      companyId: values.companyId,
    };
  }

  const response = await API.tryAuth();

  const { refresh_token, token, data } = await response.json();

  if ("isActive" in data && !data.isActive) {
    return api.rejectWithValue("BLOCKED");
  }

  API.token = token;
  API.refreshToken = refresh_token;

  const decoded = jwt_decode(token) as any;

  LogRocket.identify(decoded.email, {
    name: localStorage.getItem("user") || decoded.email,
    email: decoded.email,
  });

  return {
    user: localStorage.getItem("user"),
    auth: true,
    userId: data.userID,
    companyId: data.userCompanyID,
  };
});

export const registerUser = createAsyncThunk(
  "auth/register",
  async (values: any, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_API + "/auth/register", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(values),
    });
    const json = await response.json();
    try {
      const { refreshToken, token, userId, companyId } = json.data;
      const { status } = json;

      API.token = token;
      API.refreshToken = refreshToken;

      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("user", values.email);
      localStorage.setItem("logintime", new Date().getTime().toString());

      return {
        user: values.email,
        auth: true,
        userId: userId,
        companyId: companyId,
        status: status,
      };
    } catch (e) {
      return rejectWithValue(json);
    }
  }
);

export const resetPassword = createAsyncThunk<any, any>(
  "auth/resetPassword",
  async (values, ThunkAPI) => {
    const response = await fetch(
      process.env.REACT_APP_API + "/user/reset_password/",
      {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(values),
      }
    );
    return await response.json();
  }
);

export const resetPasswordToken = createAsyncThunk<any, any>(
  "auth/resetWithToken",
  async (values) => {
    const response = await fetch(
      process.env.REACT_APP_API + "/user/reset_password/process",
      {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(values),
      }
    );
    return await response.json();
  }
);

export const authReducer = createSlice({
  name: "auth",
  initialState: {
    user: "",
    auth: false,
    userId: null,
    companyId: null,
    failed: false,
    failedMessage: "",
    failedRegister: false,
    failedAuth: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      Object.assign(state, action.payload);
      state.failed = false;
      state.failedAuth = false;
      setTimeout(() => history.push("/panel/dashboard"), 100);
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      if (action.payload == "BLOCKED") {
        state.failedMessage =
          "Nie można się zalogować. Twoje konto jest zablokowane";
      }
      state.failed = true;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      Object.assign(state, action.payload);
      state.failed = false;
      state.failedAuth = false;
      setTimeout(() => history.push("/panel/dashboard"), 100);
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.failedRegister = action.payload as any;
    });
    builder.addCase(tryAuth.fulfilled, (state, action) => {
      Object.assign(state, action.payload);
      state.failed = false;
      if (
        history.location.pathname.includes("logowanie") ||
        history.location.pathname === "/"
      ) {
        setTimeout(() => history.push("/panel/dashboard"), 100);
      }
    });
    builder.addCase(tryAuth.rejected, (state, action) => {
      if (action.payload == "BLOCKED") {
        state.failedMessage =
          "Nie można się zalogować. Twoje konto jest zablokowane";
      }
      state.failedAuth = true;
    });
  },
});
export const {} = authReducer.actions;

export default authReducer.reducer;

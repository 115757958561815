import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { IResponse } from "../../API";

interface IFAQ {
  id: number;
  question: string;
  answer: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  deleted: boolean;
  faqs: any;
}

export const fetchFAQ = API.getRequestThunk<IResponse<IFAQ>>(
  "faq/fetchFaq",
  "/faq_group/"
);

interface FaqState {
  faqs: IFAQ[];
  loading: boolean;
  requested: boolean;
}

export const faqSlice = createSlice({
  name: "faq",
  initialState: {
    faqs: [],
    loading: false,
    requested: false,
  } as FaqState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFAQ.fulfilled, (state, action) => {
      state.requested = true;
      state.loading = false;
      state.faqs = action.payload?.data?.items;
    });
    builder.addCase(fetchFAQ.pending, (state) => {
      state.loading = true;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = faqSlice.actions;

export default faqSlice.reducer;

import * as React from "react";
import { Card } from "../../components/Card";
import { FAQ } from "../../components/Widgets/FAQ";

export function FAQWidget({ id, defaultOpen = true }: IFAQWidget) {
  return (
    <Card
      HeaderProps={{
        variant: "plain",
        label: "Najważniejsze informacje",
        icon: "faq",
      }}
    >
      <FAQ defaultOpen={defaultOpen} id={id} />
    </Card>
  );
}

export interface IFAQWidget {
  id: number;
  defaultOpen?: boolean;
}

export interface FAQ {
  q: string;
  a: string;
}

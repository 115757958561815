import { takeRight } from "lodash";
import * as React from "react";
import styled, { css } from "styled-components";
import { Card } from "../../components/Card";
import { InvoiceWidgetItem } from "../../components/Widgets/InvoiceWidgetItem";
import { Button } from "../../components/Common";
import { useAppDispatch, useAppSelector } from "../../store";
import { useEffect } from "react";
import { fetchInvoice } from "../../store/reducers/invoiceReducer";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  min-height: 145px;
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #1b607b;
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
`;

export function InvoicesWidget({}: IInvoicesWidget) {
  const invoices = useAppSelector((state) => state.invoice.invoices);
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.auth.userId);

  useEffect(() => {
    if (userId) {
      dispatch(fetchInvoice({}));
    }
  }, [userId]);

  const invoicesCropped = takeRight(invoices, 5);

  return (
    <Card
      HeaderProps={{
        variant: "plain",
        label: "Faktury",
        icon: "invoice",
      }}
    >
      <Wrapper>
        {invoicesCropped.length === 0 ? (
          <Label>Jeszcze nie masz żadnych wygenerowanych faktur.</Label>
        ) : (
          <>
            {invoicesCropped.map((invoice) => (
              <InvoiceWidgetItem invoice={invoice} />
            ))}
            <ButtonWrapper>
              <Button
                component={NavLink}
                to={"/panel/payments"}
                styles={css`
                  & .MuiButton-label {
                    font-weight: 500;
                    font-size: 14px;
                  }
                `}
                color={"#33addc"}
              >
                ZOBACZ WSZYSTKIE
              </Button>
            </ButtonWrapper>
          </>
        )}
      </Wrapper>
    </Card>
  );
}

export interface IInvoicesWidget {}

export enum PaymentType {
  Online = 0,
  Transfer = 1,
}

export enum InvoiceStatus {
  Unpaid = 0,
  Paid = 1,
}

import React, { useEffect } from "react";
import { Card } from "../../../components/Card";
import styled, { css } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  fetchGroups,
  fetchGroupsEcommerce,
  updateGroupStatus,
} from "../../../store/reducers/groupReducer";
import { Button } from "../../../components/Common";
import { NavLink } from "react-router-dom";
import { EditableCell } from "../../../pages/Group/GroupList";
import { useDesktop } from "../../../components/Responsive";
import ReactPaginate from "react-paginate";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(215px, 2fr) 1fr 1fr 1fr 1fr;
  width: 100%;
  border-bottom: 1px solid #ebf2f4;
`;

const Header = styled.div`
  height: 40px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background: #ebf2f4;
  color: #1b607b;
`;

const Item = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1b607b;
  white-space: nowrap;
  padding: 11px 15px 11px 17px;
  border-bottom: 1px solid #ebf2f4;
  display: flex;
  align-items: center;
`;

const PaginWrapper = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
  box-sizing: border-box;
  margin-top: auto;
`;

function EcommerceGroupsWidget({}: IEcommerceGroupsWidget) {
  const dispatch = useAppDispatch();
  const totalPages = useAppSelector((state) => state.group.total);
  const page = useAppSelector((state) => state.group.page);

  const groups = useAppSelector((state) => state.group.groupsEcommerce);
  const user = useAppSelector((state) => state.user.userData);
  useEffect(() => {
    dispatch(fetchGroupsEcommerce({}));
  }, []);

  const disableGroup = (id: number, status: boolean) => () => {
    dispatch(updateGroupStatus({ id: id, isActive: !status })).then(() => {
      dispatch(fetchGroupsEcommerce({}));
    });
  };

  const desktop = useDesktop();

  const onPageChange = (page: number) => {
    dispatch(fetchGroupsEcommerce({ page }));
  };

  const changePage = ({ selected }: any) => {
    onPageChange(selected + 1);
  };

  return (
    <Card
      HeaderProps={{
        variant: "plain",
        label: "Twoje sadzenia",
        icon: "eseed",
      }}
      wrapperStyle={{
        marginRight: desktop ? "13px" : "0px",
        overflowX: "auto",
      }}
    >
      <Wrapper key={JSON.stringify(groups)}>
        <Header>Tytuł (edytowalny)</Header>
        <Header>Liczba drzew</Header>
        <Header>Klucz API</Header>
        <Header>ID sadzenia</Header>
        <Header></Header>
        {groups &&
          groups.map((g) => (
            <>
              <Item
                style={{
                  fontWeight: "bold",

                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  minWidth: 0,
                }}
              >
                <EditableCell
                  afterEdit={() => {
                    dispatch(fetchGroupsEcommerce({}));
                  }}
                  key={g.id}
                  group={g}
                />
              </Item>
              <Item style={{ borderLeft: "1px solid #EBF2F4" }}>
                {g.countBoughtTrees}
              </Item>
              <Item style={{ borderLeft: "1px solid #EBF2F4" }}>
                {user?.apiToken}
              </Item>
              <Item style={{ borderLeft: "1px solid #EBF2F4" }}>{g.APIID}</Item>
              <Item style={{ borderLeft: "1px solid #EBF2F4" }}>
                <Button
                  onClick={disableGroup(g.id, g.eCommerceStatus)}
                  styles={css`
                    & .MuiButton-label {
                      font-weight: 500;
                      font-size: 14px;
                    }
                  `}
                  color={"#33addc"}
                >
                  {g.eCommerceStatus ? "WYŁĄCZ" : "WŁĄCZ"}
                </Button>
              </Item>
            </>
          ))}
      </Wrapper>
      {!!totalPages && totalPages !== 1 && (
        <PaginWrapper>
          <ReactPaginate
            forcePage={(page || 1) - 1}
            onPageChange={changePage}
            pageCount={totalPages}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            previousLabel={"<"}
            nextLabel={">"}
            containerClassName={"p-container"}
            previousClassName={"p-label"}
            nextClassName={"p-label"}
            activeClassName={"p-active"}
          />
        </PaginWrapper>
      )}
    </Card>
  );
}

export interface IEcommerceGroupsWidget {}

export default EcommerceGroupsWidget;

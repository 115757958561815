import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/pl";
let strings = {
  many: "drzew",
  one: "drzewo",
  few: "drzewa",
} as any;

export const getTreeString = (count: number): string => {
  if (Intl?.PluralRules) {
    let pr = new Intl.PluralRules("pl");
    return strings[pr.select(count)];
  }
  return "";
};

import * as React from "react";
import styled from "styled-components";
import { Card } from "../Card";
import ReactPaginate from "react-paginate";
const StyledTable = styled.table`
  width: 100%;
`;

const THead = styled.thead`
  background-color: #ebf2f4;
  & > tr > th {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding: 13px;
    color: #1b607b;
    text-align: left;
  }
`;

const TBody = styled.tbody`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #1b607b;
  & > tr > td > div {
    padding: 0px 15px;
    border-bottom: 2px solid #ebf2f4;
    border-left: 2px solid #ebf2f4;
    box-sizing: border-box;
    height: 45px;
    display: flex;
    align-items: center;
  }

  & > tr > td:first-child {
    padding-left: 13px;
  }

  & > tr > td:last-child {
    padding-right: 13px;
  }

  & > tr > td {
    height: 45px;
  }
`;

const Brak = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #1b607b;
`;

const PaginWrapper = styled.div`
  height: 55px;
  border-top: 1px solid #d1e0e7;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
  box-sizing: border-box;
  margin-top: auto;
`;

export function Table({
  header,
  list = false,
  body,
  onPageChange = () => {},
  page,
  totalPages,
}: ITable) {
  const changePage = ({ selected }: any) => {
    onPageChange(selected + 1);
  };

  return (
    <>
      {list ? (
        <>
          {(!body || body.length === 0) && (
            <Brak style={{ padding: "5px 10px" }}>Brak elementów</Brak>
          )}
          {body}
        </>
      ) : (
        <StyledTable>
          <THead>
            <tr>
              {header && header.map((h, i) => <th key={"th" + i}>{h}</th>)}
            </tr>
          </THead>
          <TBody>
            {(!body || body.length === 0) && (
              <div style={{ padding: "5px 10px" }}>Brak elementów</div>
            )}
            {body &&
              body.map((b: any, i: number) => (
                <tr key={"tb" + i}>
                  {b.map((data: any, j: number) => (
                    <td key={`tbd${i}${j}`}>
                      <div style={data.style}>{data.content}</div>
                    </td>
                  ))}
                </tr>
              ))}
          </TBody>
        </StyledTable>
      )}
      {!!totalPages && totalPages !== 1 && (
        <PaginWrapper>
          <ReactPaginate
            forcePage={(page || 1) - 1}
            onPageChange={changePage}
            pageCount={totalPages}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            previousLabel={"<"}
            nextLabel={">"}
            containerClassName={"p-container"}
            previousClassName={"p-label"}
            nextClassName={"p-label"}
            activeClassName={"p-active"}
          />
        </PaginWrapper>
      )}
    </>
  );
}

export interface ITable {
  header?: string[];
  body: ITableBody[][] | undefined | null | any;
  page?: number;
  totalPages?: number;
  onPageChange?: (page: number) => void;
  list?: boolean;
}

export interface ITableBody {
  style?: React.CSSProperties;
  content: any;
}

import * as React from "react";
import styled from "styled-components";
import { Button } from "../../Common";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import { useDesktop } from "../../Responsive";

const Wrapper = styled.div`
  width: 100%;
  height: 143px;
  padding: 16px 36px 22px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background: white;
  @media (max-width: 1024px) {
    padding: 16px 13px 22px;
    height: 80px;
  }
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding-top: 6px;
  color: #1b617b;
`;

const Triangle = styled.div`
  position: absolute;
  left: 0px;
  z-index: 1400;
  top: 72px;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;

  border-left: 16px solid #1b607b;
`;

export function Header({ userName }: IHeader) {
  const location = useLocation();
  const desktop = useDesktop();
  return (
    <Wrapper>
      {/*{location.pathname.includes("dashboard") && desktop && <Triangle />}*/}
      <Label>
        <span style={{ fontWeight: 700 }}>Zalogowano jako:</span> {userName}
      </Label>
      {desktop && (
        <div>
          <Button component={NavLink} to={"/logout"}>
            Wyloguj
          </Button>
        </div>
      )}
    </Wrapper>
  );
}

export interface IHeader {
  userName: string;
}

import { configureStore } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "./hooks";
import faqReducer from "./reducers/faqReducer";
import authReducer from "./reducers/authReducer";
import companyReducer from "./reducers/companyReducer";
import publicReducer from "./reducers/publicReducer";
import userReducer from "./reducers/userReducer";
import orderReducer from "./reducers/orderReducer";
import invoiceReducer from "./reducers/invoiceReducer";
import certReducer from "./reducers/certReducer";
import groupReducer from "./reducers/groupReducer";

export const store = configureStore({
  reducer: {
    faq: faqReducer,
    auth: authReducer,
    company: companyReducer,
    public: publicReducer,
    user: userReducer,
    order: orderReducer,
    invoice: invoiceReducer,
    cert: certReducer,
    group: groupReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { useAppDispatch, useAppSelector };

import React, { useState } from "react";
import styled from "styled-components";
import { IGroup, updateGroup } from "../../store/reducers/groupReducer";
import { Controls, EditableCell } from "../../pages/Group/GroupList";
import { Button, TextAreaInput } from "../Common";
import { outlinedStyle } from "../Common/Button";
import { setOrderModal } from "../../store/reducers/orderReducer";
import { useAppDispatch } from "../../store";
import { format } from "date-fns";
import { IconButton } from "@material-ui/core";

const Wrapper = styled.div`
  margin-bottom: 15px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 50px;
  border: 1px solid #d1e0e7;
`;

const HeaderItem = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  cursor: pointer;
  color: #1b607b;
  width: 50%;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  box-sizing: border-box;
  /* or 17px */

  display: flex;
  align-items: center;

  color: #1b607b;
`;

const ContentWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #d1e0e7;
  box-sizing: border-box;
`;

const ContentItem = styled.div`
  width: 50%;
  display: flex;
  box-sizing: border-box;
`;

const WrapperGrid = styled.div<any>`
  height: fit-content;
  display: grid;
  grid-template-columns: ${(props) => props.ecommerce? '1fr 1fr 1fr':'1fr 1fr 1fr 1fr'};
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #ebf2f4;
  min-height: 90px;
`;

const HeaderGrid = styled.div`
  height: 45px;
  border: 1px solid #ebf2f4;
  border-top: unset;
  border-left: unset;
  padding: 0px 15px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  box-sizing: border-box;
  /* or 17px */

  display: flex;
  align-items: center;

  color: #1b607b;
`;

const Desc = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #1b607b;
  flex-grow: 1;
`;

function  GroupRow({ group, ecommerce = false }: IGroupRow) {
  const [expanded, setExpanded] = useState(false);
  const [edit, setEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(group?.description);
  const [name, setName] = useState(group?.name);

  const addTree = (id: any) => (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setOrderModal({ open: true, group: id }));
  };

  const handleChange = (e: any) => {
    if (e.target.value && e.target.value.length > 250) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    setValue(e.target.value);
  };

  const cancelEdit = () => {
    setEdit(false);
    setValue(group?.description);
  };

  const update = () => {
    dispatch(
      updateGroup({
        description: value,
        name: name,
        id: group?.id,
      } as any)
    );
    setEdit(false);
  };

  const afterEdit = (e: any) => {
    setName(e.payload.name);
  };

  return (
    <Wrapper>
      <Header>
        <HeaderItem
          style={{
            background: "#EBF2F4",
            borderRight: "1px solid #D1E0E7",
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <img
            src={`/static/icon/chevron_${expanded ? "up" : "down"}.png`}
            alt={"chevron"}
            style={{ marginRight: "5px" }}
          />
          <EditableCell
            desc={value}
            afterEdit={afterEdit}
            full={true}
            group={group}
          />
        </HeaderItem>
        <HeaderItem
          onClick={() => setExpanded(!expanded)}
          style={{ justifyContent: "space-between" }}
        >
          <Label>
            Liczba wszystkich drzew:
            <span style={{ fontWeight: "normal", marginLeft: "4px" }}>
              {group.countBoughtTrees}
            </span>
          </Label>
          {!ecommerce && (
            <Button
              style={{
                minHeight: "33px",
                maxHeight: "33px",
              }}
              styles={outlinedStyle}
              variant={"outlined"}
              onClick={addTree(group.id)}
            >
              DODAJ DRZEWA
            </Button>
          )}
        </HeaderItem>
      </Header>
      {expanded && (
        <ContentWrapper>
          <ContentItem
            style={{
              borderRight: "1px solid #d1e0e7",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "45px",
                padding: "0 15px",
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #D1E0E7",
              }}
            >
              <Label>Opis</Label>
            </div>
            <div
              style={{
                padding: "12px 15px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              {edit ? (
                <TextAreaInput
                  wrapperStyle={{ marginTop: "0" }}
                  name={"description"}
                  placeholder={"Dodaj opis"}
                  value={value}
                  onChange={handleChange}
                />
              ) : (
                <Desc>
                  {value !== "" && value !== null
                    ? value
                    : "Kliknij w ikonke plusa by dodać opis."}
                </Desc>
              )}
              {edit ? (
                <Controls
                  style={{ marginLeft: "15px" }}
                  cancelEdit={cancelEdit}
                  updateGroup={update}
                />
              ) : value ? (
                <IconButton
                  style={{ marginLeft: "15px", padding: "3" }}
                  onClick={() => setEdit(true)}
                >
                  <img alt={"icon edit2"} src={"/static/icon/edit2.png"} />
                </IconButton>
              ) : (
                <IconButton
                  style={{ marginLeft: "15px", padding: "0" }}
                  onClick={() => setEdit(true)}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.35 17C8.35 17.359 8.64101 17.65 9 17.65C9.35899 17.65 9.65 17.359 9.65 17H8.35ZM9.65 1C9.65 0.641014 9.35899 0.349998 9 0.349998C8.64101 0.349998 8.35 0.641014 8.35 1H9.65ZM9.65 17L9.65 1H8.35L8.35 17H9.65Z"
                      fill="#33ADDC"
                    />
                    <path
                      d="M1 8.35C0.641015 8.35 0.35 8.64101 0.35 9C0.35 9.35899 0.641015 9.65 1 9.65V8.35ZM17 9.65C17.359 9.65 17.65 9.35899 17.65 9C17.65 8.64101 17.359 8.35 17 8.35V9.65ZM1 9.65H17V8.35H1V9.65Z"
                      fill="#33ADDC"
                    />
                  </svg>
                </IconButton>
              )}
            </div>
          </ContentItem>
          <ContentItem>
            <WrapperGrid
              ecommerce={ecommerce}
              style={
                !edit && group.orders.length <= 1
                  ? { borderBottom: "unset" }
                  : {}
              }
            >
              <HeaderGrid>Liczba drzew</HeaderGrid>
              <HeaderGrid>Data zakupu</HeaderGrid>
              <HeaderGrid>{ecommerce ? "Godzina zakupu" : "Status"}</HeaderGrid>
              {!ecommerce && <HeaderGrid />}
              {(ecommerce? group.eCommerceOrders:group.orders).map((d:any, i: number) => {
                const date = new Date(d.createdAt.date.replace(" ", "T"));

                return (
                  <>
                    <Label
                      style={{
                        fontWeight: "normal",
                        padding: "5px 15px",
                        paddingTop: i === 0 ? "10px" : "5px",
                        paddingBottom:
                          i === group.orders.length - 1 ? "10px" : "5px",
                      }}
                    >
                      {d.treeQuantity}
                    </Label>
                    <Label
                      style={{
                        fontWeight: "normal",
                        padding: "5px 15px",
                        paddingTop: i === 0 ? "10px" : "5px",
                        paddingBottom:
                          i === group.orders.length - 1 ? "10px" : "5px",
                        borderLeft: "1px solid #EBF2F4",
                      }}
                    >
                      {format(date, "dd/MM/yyyy")}
                    </Label>
                    <Label
                      style={{
                        fontWeight: "normal",
                        textAlign: ecommerce ? "right" : "left",
                        padding: "5px 15px",
                        paddingTop: i === 0 ? "10px" : "5px",
                        paddingBottom:
                          i === group.orders.length - 1 ? "10px" : "5px",
                        borderLeft: "1px solid #EBF2F4",
                      }}
                    >
                      {ecommerce
                        ? format(
                            new Date(d.createdAt.date.replace(" ", "T")),
                            "HH:mm:ss"
                          )
                        : d.paymentStatus == 2
                        ? "opublikowane"
                        : "oczekuje na płatność"}
                    </Label>
                    {!ecommerce && (
                      <Label
                        style={{
                          fontWeight: "normal",
                          padding: "5px 15px",
                          paddingTop: i === 0 ? "10px" : "5px",
                          paddingBottom:
                            i === group.orders.length - 1 ? "10px" : "5px",
                          borderLeft: "1px solid #EBF2F4",
                        }}
                      />
                    )}
                  </>
                );
              })}
            </WrapperGrid>
          </ContentItem>
        </ContentWrapper>
      )}
    </Wrapper>
  );
}

export interface IGroupRow {
  group: IGroup;
  ecommerce?: boolean;
}

export default GroupRow;

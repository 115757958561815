import * as React from "react";
import styled from "styled-components";
import { Header } from "./Header";
import { useAppSelector } from "../../../store";
import Modal from "react-modal";
import { PurchaseWidget } from "../../../containers/Widgets/PurchaseWidget";
import { useDesktop } from "../../Responsive";
import { use100vh } from "react-div-100vh";
import { useLocation } from "react-router";
const MainWrapper = styled.div<any>`
  width: calc(100% - 200px);
  height: 100vh;
  position: relative;
  left: 200px;
  overflow: hidden;
  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
    height: calc(
      ${(props) => (props.height ? props.height + "px" : "100vh")} - 50px
    );
  }
`;

const BgImg = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  //height: calc(100% - 143px);
  @media (max-width: 1024px) {
    height: calc(100% - 80px);
  }
`;

const customStyles = (desktop: boolean) => ({
  overlay: {
    backgroundColor: "rgba(27, 96, 123, 0.5)",
  },
  content: {
    top: desktop ? "58px" : "98px",
    left: desktop ? "221px" : "13px",
    right: desktop ? "auto" : "13px",
    bottom: "auto",
    boxShadow: `0px 4px 18px rgba(0, 0, 0, 0.25)`,
    borderRadius: "5px",
    border: "unset",
    padding: "0",
  },
});

export function Main({ children }: IMain) {
  const userName = useAppSelector((state) => state.auth.user);
  const open = useAppSelector((state) => state.order.orderModal);
  const desktop = useDesktop();
  const height = use100vh();

  const location = useLocation();

  const ecommerce = location.pathname.includes("/ecommerce");

  return (
    <MainWrapper height={height}>
      <Header userName={userName} />
      <BgImg
        src={
          ecommerce ? "/static/img/bg_ecommerce.jpg" : "/static/img/bg_img.jpg"
        }
      />
      {children}
      <Modal style={customStyles(desktop)} isOpen={open}>
        <PurchaseWidget modal={true} />
      </Modal>
    </MainWrapper>
  );
}

export interface IMain {
  children: React.ReactNode;
}

import * as React from "react";
import styled from "styled-components";
import { IInvoice } from "../../store/reducers/invoiceReducer";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;

  color: #1b607b;
`;

const Status = styled.div<{ bold?: boolean }>`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "500" : "normal")};
  font-size: 15px;
  display: flex;
  align-items: center;
  text-align: right;
  line-height: 150%;

  color: #1b607b;
`;

const mapInvoiceStatus = (status: boolean) => {
  switch (status) {
    case false:
      return <Status bold={true}>Oczekuje na płatność.</Status>;
    case true:
      return <Status>Opłacona</Status>;
  }
};

export function InvoiceWidgetItem({ invoice }: IInvoiceWidgetItem) {
  return (
    <Wrapper>
      <Label>{invoice.invoiceRealNumber || invoice.invoiceNumber}</Label>
      {mapInvoiceStatus(invoice.isPaid)}
    </Wrapper>
  );
}

export interface IInvoiceWidgetItem {
  invoice: IInvoice;
}

import React, { useEffect, useState } from "react";
import { ColLayout } from "../../components/Layout/ColLayout";
import { Card } from "../../components/Card";
import { CompanyInfoForm, LogoForm } from "../../containers/Forms";
import styled from "styled-components";
import { Alert, Button } from "../../components/Common";
import { outlinedStyle } from "../../components/Common/Button";
import ContactForm from "../../containers/Forms/ContactForm";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  fetchCompany,
  updateCompany,
  ICompany,
} from "../../store/reducers/companyReducer";
import {
  fetchPublic,
  IPublic,
  updatePublic,
} from "../../store/reducers/publicReducer";
import { useDesktop } from "../../components/Responsive";
import CompanyNameForm from "../../components/Forms/CompanyNameForm";
import { log } from "util";

const Wrapper = styled.div`
  padding: 12px 25px;
  max-width: 300px;
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
`;

function CompanyInfo({}: ICompanyInfo) {
  const [loading, setLoading] = useState({
    info: false,
    billing: false,
    contact: false,
  });
  const [saved, setSaved] = useState({
    info: false,
    billing: false,
    contact: false,
  });

  const [error, setError] = useState("");

  const companyId = useAppSelector((state) => state.auth.companyId);
  const company = useAppSelector((state) => state.company.company);
  const publicCompany = useAppSelector((state) => state.public.public);
  const dispatch = useAppDispatch();

  const desktop = useDesktop();

  useEffect(() => {
    if (companyId) {
      dispatch(fetchCompany({}));
      dispatch(fetchPublic({}));
    }
  }, [companyId]);

  const onUpdateCompany = (type: string) => (values: Partial<ICompany>) => {
    setLoading({ ...loading, [type]: true });
    dispatch(updateCompany(Object.assign({}, company, {...values, companyBillingVATNumber: values.companyBillingVATNumber?.replaceAll(' ', '')?.replaceAll('-', '')?.replaceAll('PL', '')}))).then((r) => {
      if (r.payload.r.status == 422) {
        r.payload.r.json().then((e: any) => {
          setError(e.message.includes('Billing')? 'VAT': 'Kod pocztowy');
          setLoading({ ...loading, [type]: false });
          setSaved({ ...saved, [type]: false });
        });
      } else {
        setError("");
        dispatch(fetchCompany({})).then(() => {
          setLoading({ ...loading, [type]: false });
          setSaved({ ...saved, [type]: true });
        });
      }
    });
  };

  const submitPublic = (values: Partial<IPublic>) => {
    const update = { ...publicCompany, ...values };
    setLoading({ ...loading, info: true });
    if (publicCompany?.companyAvatar) {
      dispatch(
        updatePublic({ ...update, logo: publicCompany.companyAvatar.id } as any)
      ).then((r) =>
        dispatch(fetchPublic({})).then(() => {
          setLoading({ ...loading, info: false });
          setSaved({ ...saved, info: true });
        })
      );
    } else {
      delete update.companyAvatar;
      dispatch(updatePublic(update as any)).then((r) =>
        dispatch(fetchPublic({})).then(() => {
          setLoading({ ...loading, info: false });
          setSaved({ ...saved, info: true });
        })
      );
    }
  };

  const logoLabel = `Logo  widoczne jest na Twojej stronie firmowej, oraz na generowanych przez Ciebie certyfikatach. <span style="font-weight: 700;">Jeśli nie dodasz logo, zastąpiony zostanie on nazwą firmy.</span>`;
  return (
    <ColLayout
      withoutSpacing={true}
      colCount={3}
      cols={[
        <Card
          wrapperStyle={{
            borderRight: `1px solid #EBF2F4`,
            borderRadius: "5px 5px 0px 5px",
            minHeight: desktop ? "630px" : "unset",
          }}
          withoutElevation={true}
          HeaderProps={{
            variant: "filled",
            label: "Dane na stronę i certyfikat",
            center: !desktop,
            style: {
              borderRadius: "0",
            },
          }}
        >
          <Wrapper>
            {(loading.info || saved.info) && (
              <Alert
                loading={loading.info}
                style={{ marginTop: "5px", marginBottom: "10px" }}
                color={"#62A30D"}
                label={"Zmiany zostały zapisane poprawnie"}
              />
            )}
            <CompanyNameForm initial={publicCompany} onSubmit={submitPublic}>
              <Button
                style={{
                  width: desktop ? "85px" : "100%",
                  maxHeight: "33px",
                  marginBottom: "50px",
                  minHeight: "33px",
                }}
                styles={outlinedStyle}
                variant={"outlined"}
                type={"submit"}
              >
                ZAPISZ
              </Button>
            </CompanyNameForm>
            <LogoForm label={logoLabel} />
          </Wrapper>
        </Card>,
        <Card
          withoutElevation={true}
          wrapperStyle={{
            borderRight: `1px solid #EBF2F4`,
            borderRadius: "0px 0px 0px 0px",
            minHeight: desktop ? "630px" : "unset",
          }}
          HeaderProps={{
            variant: "filled",
            label: "Dane do faktury",
            center: !desktop,
            style: {
              borderRadius: "0",
              borderRight: `1px solid #EBF2F4`,
            },
          }}
        >
          <Wrapper>
            {!company?.companyBillingAddress && (
              <Alert
                style={{ marginTop: "5px", marginBottom: "10px" }}
                label={"Aby kupić drzewa uzupełnij dane swojej firmy."}
              />
            )}
            {(loading.billing || saved.billing || error) && (
              <Alert
                loading={loading.billing}
                style={{ marginTop: "5px", marginBottom: "10px" }}
                color={error? undefined:"#62A30D"}
                label={error? "Sprawdź ponownie poprawność danych":"Zmiany zostały zapisane poprawnie"}
              />
            )}
            <CompanyInfoForm
              initial={company}
              onSubmit={onUpdateCompany("billing")}
              externalErrors={error}
            >
              <Button
                style={{
                  width: desktop ? "85px" : "100%",
                  maxHeight: "33px",
                  minHeight: "33px",
                  marginBottom: desktop ? "0px" : "50px",
                }}
                styles={outlinedStyle}
                variant={"outlined"}
                type={"submit"}
              >
                ZAPISZ
              </Button>
            </CompanyInfoForm>
          </Wrapper>
        </Card>,
        <Card
          withoutElevation={true}
          wrapperStyle={{
            borderRight: `1px solid #EBF2F4`,
            borderRadius: "0px 0px 5px 0px",
            minHeight: desktop ? "630px" : "unset",
          }}
          HeaderProps={{
            variant: "filled",
            label: "Dane kontaktowe",
            center: !desktop,
            style: {
              borderRadius: "0",
              borderRight: `1px solid #EBF2F4`,
            },
          }}
        >
          <Wrapper>
            {(loading.contact || saved.contact) && (
              <Alert
                loading={loading.contact}
                style={{ marginTop: "5px", marginBottom: "10px" }}
                color={"#62A30D"}
                label={"Zmiany zostały zapisane poprawnie"}
              />
            )}
            <ContactForm
              initial={company}
              onSubmit={onUpdateCompany("contact")}
            >
              <Button
                style={{
                  width: desktop ? "85px" : "100%",
                  maxHeight: "33px",
                  marginBottom: desktop ? "0px" : "50px",
                  minHeight: "33px",
                }}
                styles={outlinedStyle}
                variant={"outlined"}
                type={"submit"}
              >
                ZAPISZ
              </Button>
            </ContactForm>
          </Wrapper>
        </Card>,
      ]}
    />
  );
}

export interface ICompanyInfo {}

export default CompanyInfo;

import React, { useEffect } from "react";
import { Main } from "./Main/Main";
import Sidebar from "./Sidebar/Sidebar";
import { useAppDispatch, useAppSelector } from "../../store";
import { useLocation } from "react-router-dom";
import { fetchStats } from "../../store/reducers/userReducer";
import { setOrderModal } from "../../store/reducers/orderReducer";
import { useDesktop } from "../Responsive";
import styled from "styled-components";
import Menu from "./Menu/Menu";
import { motion } from 'framer-motion';
const Flex = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

function Layout({ children }: ILayout) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const auth = useAppSelector((state) => state.auth.auth);

  useEffect(() => {
    if (auth) {
      dispatch(fetchStats({}));
      dispatch(setOrderModal({ open: false, group: null }));
    }
  }, [location.key, auth]);

  const desktop = useDesktop();

  return (
    <Flex>
      {desktop ? <Sidebar /> : <Menu />}
      <Main>{children}</Main>
    </Flex>
  );
}

export interface ILayout {
  children: React.ReactNode;
}

export default Layout;

import * as React from "react";
import { useEffect } from "react";
import { ColLayout } from "../../components/Layout/ColLayout";
import { Card } from "../../components/Card";
import {
  InvoiceStatus,
  PaymentType,
} from "../../containers/Widgets/InvoicesWidget";
import { addDays, format } from "date-fns";
import { Button } from "../../components/Common";
import { defaultButton, outlinedStyle } from "../../components/Common/Button";
import { Table } from "../../components/Table";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  fetchInvoice,
  getInvoicePdf,
  IInvoice,
} from "../../store/reducers/invoiceReducer";
import { retryPayment, setOrderModal } from "../../store/reducers/orderReducer";
import { useDesktop } from "../../components/Responsive";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import styled, { css } from "styled-components";
import API from "../../API";

const mapStatusToString = (status: boolean) => {
  switch (status) {
    case false:
      return (
        <span>
          Oczekuje na płatność.{" "}
          <span style={{ fontWeight: 700 }}>Prosimy o terminową wpłatę.</span>
        </span>
      );
    case true:
      return "Opłacona.";
  }
};

const AccLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  /* or 22px */

  display: flex;
  align-items: center;

  color: #1b607b;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const GLabel = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: column;

  font-size: 14px;
  line-height: 150%;
  padding: 10px 0px;
  /* or 21px */

  border-bottom: 1px solid #ebf2f4;
  color: #1b607b;
`;

const AccSummary = styled(AccordionSummary)`
  background-color: #ebf2f4 !important;
`;

export function PaymentList({}) {
  const invoices = useAppSelector((state) => state.invoice.invoices);
  const total = useAppSelector((state) => state.invoice.total);
  const page = useAppSelector((state) => state.invoice.page);
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth.auth);
  const requestPDF = (invoice: IInvoice) => () => {
    dispatch(getInvoicePdf(invoice));
  };
  const paymentRedirect = useAppSelector(
    (state) => state.order.paymentRedirectURL
  );
  const retry = (invoice: IInvoice) => () => {
    API.getOrder(invoice.orderID).then((res) =>
      res.json().then((data) => {
        if (data?.data?.item) {
          const { treeQuantity, type } = data.data.item;
          dispatch(
            retryPayment({
              invoice,
              returnUrl:
                window.location.origin +
                `/thank-you?v=v&orderType=${type}&trees=${treeQuantity}`,
            })
          );
        } else {
          dispatch(
            retryPayment({
              invoice,
              returnUrl: window.location.origin + `/thank-you?v=v`,
            })
          );
        }
      })
    );
  };
  useEffect(() => {
    if (paymentRedirect && !paymentRedirect.includes(window.location.origin)) {
      window.location.href = paymentRedirect;
    }
  }, [paymentRedirect]);
  useEffect(() => {
    if (auth) {
      dispatch(fetchInvoice({}));
    }
  }, [auth]);

  const onPageChange = (page: number) => {
    dispatch(fetchInvoice({ page }));
  };

  const desktop = useDesktop();

  const formatMaturity = (invoice: any) => {
    try {
      return format(
        new Date(invoice.maturity?.date?.replace(" ", "T")),
        "dd/MM/yyyy"
      );
    } catch (e) {
      return format(
        new Date(invoice.createdAt?.date?.replace(" ", "T")),
        "dd/MM/yyyy"
      );
    }
  };

  return (
    <ColLayout
      colCount={1}
      cols={[
        <Card
          full={desktop}
          HeaderProps={{
            variant: "filled",
            label: "Płatności",
            center: !desktop,
            action: desktop
              ? {
                  name: "KUP NOWE DRZEWA",
                  invoke: () => {
                    dispatch(setOrderModal({ open: true }));
                  },
                }
              : undefined,
            style: {
              borderRadius: "0",
            },
          }}
        >
          {desktop ? (
            <Table
              onPageChange={onPageChange}
              totalPages={total}
              page={page}
              header={["Numer faktury", "Termin płatności", "Status"]}
              body={invoices?.map((invoice) => [
                {
                  style: { borderLeft: "unset", paddingLeft: "0" },
                  content: invoice.invoiceRealNumber || invoice.invoiceNumber,
                },
                {
                  content: formatMaturity(invoice),
                },
                {
                  style: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  },
                  content: (
                    <>
                      {mapStatusToString(invoice.isPaid)}

                      {invoice.isPaid ? (
                        <>
                          <Button
                            style={{
                              padding: "1px 15px",
                              minHeight: "23px",
                              maxHeight: "23px",
                            }}
                            styles={outlinedStyle}
                            variant={"outlined"}
                            onClick={requestPDF(invoice)}
                          >
                            POBIERZ
                          </Button>
                        </>
                      ) : invoice.invoiceType === PaymentType.Online ? (
                        <>
                          <Button
                            style={{
                              padding: "1px 15px",
                              minHeight: "23px",
                              maxHeight: "23px",
                            }}
                            styles={outlinedStyle}
                            variant={"outlined"}
                            onClick={retry(invoice)}
                          >
                            PONÓW
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            style={{
                              padding: "1px 15px",
                              minHeight: "23px",
                              maxHeight: "23px",
                            }}
                            styles={outlinedStyle}
                            variant={"outlined"}
                            onClick={requestPDF(invoice)}
                          >
                            POBIERZ
                          </Button>
                        </>
                      )}
                    </>
                  ),
                },
              ])}
            />
          ) : (
            <div>
              {!!total && page !== 1 && (
                <div
                  onClick={() => onPageChange(page - 1)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50px",
                  }}
                >
                  <img
                    style={{ transform: "rotate(180deg)" }}
                    alt={"expand"}
                    src={"/static/icon/expand.png"}
                  />
                </div>
              )}
              {invoices &&
                invoices.map((invoice) => (
                  <Accordion elevation={0}>
                    <AccSummary
                      expandIcon={
                        <img alt={"expand"} src={"/static/icon/expand.png"} />
                      }
                    >
                      <AccLabel>
                        {invoice.invoiceRealNumber || invoice.id}
                      </AccLabel>
                    </AccSummary>
                    <AccordionDetails>
                      <Grid>
                        <GLabel>Termin płatności</GLabel>
                        <GLabel style={{ fontWeight: 400 }}>
                          {formatMaturity(invoice)}
                        </GLabel>
                        <GLabel style={{ borderBottom: "unset" }}>
                          Status
                        </GLabel>
                        <GLabel
                          style={{ fontWeight: 400, borderBottom: "unset" }}
                        >
                          {mapStatusToString(invoice.isPaid)}

                          {invoice.isPaid ? (
                            <div>
                              <Button
                                styles={css`
                                  & .MuiButton-label {
                                    font-weight: 500;
                                    font-size: 14px;
                                  }
                                `}
                                color={"#33addc"}
                                style={{
                                  marginLeft: "-8px",
                                  minHeight: "23px",
                                  maxHeight: "23px",
                                }}
                                onClick={requestPDF(invoice)}
                              >
                                POBIERZ
                              </Button>
                            </div>
                          ) : invoice.invoiceType === PaymentType.Online ? (
                            <div>
                              <Button
                                style={{
                                  marginLeft: "-8px",
                                  minHeight: "23px",
                                  maxHeight: "23px",
                                }}
                                styles={css`
                                  & .MuiButton-label {
                                    font-weight: 500;
                                    font-size: 14px;
                                  }
                                `}
                                color={"#33addc"}
                                onClick={retry(invoice)}
                              >
                                PONÓW
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button
                                styles={css`
                                  & .MuiButton-label {
                                    font-weight: 500;
                                    font-size: 14px;
                                  }
                                `}
                                color={"#33addc"}
                                style={{
                                  marginLeft: "-8px",
                                  minHeight: "23px",
                                  maxHeight: "23px",
                                }}
                                onClick={requestPDF(invoice)}
                              >
                                POBIERZ
                              </Button>
                            </div>
                          )}
                        </GLabel>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              {!!total && page !== total && (
                <div
                  onClick={() => onPageChange(page + 1)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50px",
                  }}
                >
                  <img alt={"expand"} src={"/static/icon/expand.png"} />
                </div>
              )}
            </div>
          )}
        </Card>,
        desktop ? undefined : (
          <Button
            style={{
              maxHeight: "40px",
              minHeight: "40px",
              width: "100%",
            }}
            styles={defaultButton}
            variant={"contained"}
            onClick={() => dispatch(setOrderModal({ open: true }))}
          >
            KUP NOWE DRZEWA
          </Button>
        ),
      ]}
    />
  );
}

import * as React from "react";
import styled, { css } from "styled-components";
import { CardHeader, ICardHeader } from "./CardHeader";
import { TabbedCardHeader } from "./TabbedCardHeader";

const CardWrapper = styled.div<ICardStyle>`
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
  background: #ffffff;
  box-shadow: ${(props) =>
    props.withoutElevation ? "0" : "0px 4px 18px rgba(0, 0, 0, 0.25)"};
  border-radius: 5px;
  margin-bottom: 15px;
  ${(props) =>
    props.full
      ? css`
          height: calc(100vh - 80px);
          overflow-y: auto;
          padding: 1px 0px;
        `
      : ""};
`;

export function TabbedCard({
  HeaderProps,
  wrapperStyle,
  children,
  activeTab,
  full,
  tabs,
  withoutElevation,
  horizontal = false,
}: ICard) {
  return (
    <CardWrapper
      horizontal={horizontal}
      style={wrapperStyle}
      withoutElevation={withoutElevation}
      full={full}
    >
      <TabbedCardHeader
        tabs={tabs}
        activeTab={activeTab}
        horizontal={horizontal}
        {...HeaderProps}
      />
      {React.Children.toArray(children)[activeTab ?? 0]}
    </CardWrapper>
  );
}

export interface ICardStyle {
  full?: boolean;
  withoutElevation?: boolean;
  horizontal?: boolean;
}

export interface ICard extends ICardStyle {
  children: React.ReactNode;
  tabs: Array<{ label: string; active: boolean; onClick: any; action: any }>;
  activeTab?: number;
  HeaderProps: ICardHeader;
  wrapperStyle?: React.CSSProperties;
}

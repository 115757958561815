import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 170px;
  padding: 23px 25px;
  border-top: 1px solid #ebf2f4;
  @media (max-width: 1024px) {
    margin-top: 15px;
    padding: 15px 0 0;
    height: auto;
  }
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #1b607b;
`;

const Label2 = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  padding-top: 10px;
  color: #1b607b;
`;

export function CompanyContactFooter({}) {
  return (
    <Wrapper>
      <Label>Skontaktuj się</Label>
      <Label2>
        <a href={"mailto:agata@posadzimy.pl"}>agata@posadzimy.pl</a>
      </Label2>
      <Label2 style={{ textDecoration: "none" }}>+48 574 588 737</Label2>
    </Wrapper>
  );
}

import * as React from "react";
import styled, { css } from "styled-components";
import { Button } from "../Common";
import { defaultButton } from "../Common/Button";
import { useDesktop } from "../Responsive";

const Wrapper = styled.div<ICardHeaderStyle<boolean>>`
  height: ${(props) =>
    props.horizontal ? "100%" : props.subLabelHeight ? "90px" : "45px"};
  min-height: ${(props) =>
    props.horizontal ? "100%" : props.subLabelHeight ? "90px" : "45px"};
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: ${(props) => (props.horizontal ? "unset" : "center")};
  flex-direction: ${(props) => (props.horizontal ? "column" : "row")};
  border-radius: 5px 5px 0px 0px;

  ${(props) => {
    if (props.variant === "filled")
      return css`
        color: #ffffff;
        padding-top: ${props.horizontal ? "10px" : "0"};
        ${props.horizontal
          ? css`
              border-radius: 5px 0px 0px 5px;
            `
          : ""};
        box-sizing: border-box;
      `;
    if (props.variant === "plain")
      return css`
        color: #1b607b;
        justify-content: ${props.centerLabel ? "center" : "unset"};
      `;
  }};

  @media (max-width: 1024px) {
    flex-direction: row;
  }
`;

const Icon = styled.img`
  margin-left: 10px;
  margin-right: 14px;
`;

const Divider = styled.div`
  border-bottom: 1px solid #ebf2f4;
  margin: 0px 10px;
`;

const TabWrapper = styled.div<{ active: boolean }>`
  width: 260px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  height: 44px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */
  color: #ffffff;

  cursor: pointer;

  &:hover {
    background: #1b607b;
  }

  @media (max-width: 1024px) {
    flex-direction: row;
    margin-top: -1px;
    width: unset;
    flex-grow: 1;
  }

  ${(props) =>
    props.active
      ? css`
          background: #1b607b;
        `
      : css`
          background: #a4c3cb;
        `}
`;

export function TabbedCardHeader({
  variant,
  color = "#1B607B",
  label,
  icon,
  center = true,
  action,
  tabs,
  activeTab = 0,
  style,
  horizontal,
  subLabel,
  subLabelHeight,
  centerLabel,
}: ICardHeader) {
  const desktop = useDesktop();
  return (
    <>
      <Wrapper
        action={!!action}
        center={center}
        color={color}
        variant={variant}
        centerLabel={centerLabel}
        style={style}
        horizontal={horizontal}
        subLabelHeight={subLabelHeight}
      >
        {tabs.map((t, i) => (
          <TabWrapper
            style={
              i === 0
                ? { borderRadius: "6px 0px 0px 0px" }
                : i === tabs.length - 1
                ? { borderRadius: "0px 6px 0px 0px" }
                : {}
            }
            active={t.active}
            onClick={t.onClick}
          >
            {t.label}
          </TabWrapper>
        ))}
        {typeof tabs[activeTab].action === "object" && (
          <Button
            style={{ minWidth: "250px", borderRadius: "0", marginLeft: "auto" }}
            onClick={tabs[activeTab].action.invoke}
            styles={defaultButton}
            elevation={0}
            variant={"contained"}
          >
            {tabs[activeTab].action.name}
          </Button>
        )}
      </Wrapper>
      {variant === "plain" && <Divider />}
    </>
  );
}

export interface Action<T> {
  action?: T;
}

export interface ICardHeaderStyle<T> extends Action<T | boolean> {
  variant: "filled" | "plain";
  color?: string;
  center?: boolean;
  horizontal?: boolean;
  subLabelHeight?: string | React.ReactNode;
  centerLabel?: boolean;
}

export interface ICardHeader
  extends ICardHeaderStyle<{ name: string; invoke: Function }> {
  label: string | React.ReactNode;
  subLabel?: string | React.ReactNode;
  icon?: string;
  tabs: Array<{ label: string; active: boolean; onClick: any; action: any }>;
  activeTab?: number;
  style?: React.CSSProperties;
}

import { createSlice } from "@reduxjs/toolkit";
import API, { IResponse } from "../../API";
import { BDate } from "./invoiceReducer";

export interface IOrder {
  id: number;
  paymentStatus: number;
  treeQuantity: number;
  createdAt: BDate;
}

export interface IGroup {
  id: 0;
  name: string;
  description: string;
  countBoughtTrees: 0;
  orders: IOrder[];
  APIID: string;
  eCommerceStatus: boolean;
  eCommerceOrders: any;
}

export interface ITreesLog {
  id: number;
  createdAt: BDate;
  customerName: string;
  certificateCustomText: string;
  treeQuantity: number;
}

export const fetchGroups = API.getRequestThunk<IResponse<IGroup>>(
  "group/fetchGroup",
  "/user/{id}/tree_groups",
  (state) => state.auth.userId || ""
);

export const fetchPlant = API.getRequestThunk<IResponse<IPlant>>(
  "group/fetchPlant",
  "/plant/current_active"
);

export const fetchTreesLogs = API.getRequestThunk<IResponse<ITreesLog>>(
  "group/fetchTreesLogs",
  "/user/{id}/ecommerce_tree_order_logs",
  (state) => state.auth.userId || ""
);

export const updateGroup = API.updateRequestThunk<IGroup>(
  "group/updateGroup",
  "/tree_group/"
);

export const createGroup = API.createRequestThunk(
  "group/createGroup",
  "/tree_group/user_create"
);

export const fetchGroupsEcommerce = API.getRequestThunk<IResponse<IGroup>>(
  "group/fetchGroupEcommerce",
  "/user/{id}/tree_groups?type=ecommerce",
  (state) => state.auth.userId || ""
);

export const updateGroupStatus = API.updateGroupStatus();

export interface IPlant {
  name: string;
  description: string;
  plantImage: {
    id: number;
    name: string;
    file: string;
    type: string;
  } | null;
  countBoughtTrees: number;
}

interface GroupStore {
  groups: IGroup[] | null | undefined;
  groupsEcommerce: IGroup[] | null | undefined;
  loading: boolean;
  requested: boolean;
  page: number;
  total: number;
  plant: IPlant | null;
}

export const groupSlice = createSlice({
  name: "group",
  initialState: {
    groups: null,
    page: 1,
    total: 1,
    loading: false,
    requested: false,
    plant: null,
  } as GroupStore,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      state.groups = action.payload.data.items;
      state.total = action.payload.data.totalPages;
      state.page = action.payload.data.currentPage;
    });
    builder.addCase(fetchGroupsEcommerce.fulfilled, (state, action) => {
      state.groupsEcommerce = action.payload.data.items;
      state.total = action.payload.data.totalPages;
      state.page = action.payload.data.currentPage;
    });
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      state.groups = state.groups?.map((g) =>
        g.id == action.payload.id ? { ...g, name: action.payload.name } : g
      );
    });
    builder.addCase(fetchPlant.fulfilled, (state, action) => {
      state.plant = action.payload?.data?.item;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = groupSlice.actions;

export default groupSlice.reducer;

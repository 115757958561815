import React, { useEffect } from "react";
import { ColLayout } from "../../components/Layout/ColLayout";
import EcommerceActivateWidget from "../../containers/Widgets/Ecommerce/EcommerceActivateWidget";
import EcommerceInfoWidget from "../../containers/Widgets/Ecommerce/EcommerceInfoWidget";
import { FAQWidget } from "../../containers/Widgets/FAQWidget";
import { useAppDispatch, useAppSelector } from "../../store";
import { fetchUser } from "../../store/reducers/userReducer";
import { fetchCompany } from "../../store/reducers/companyReducer";
import EcommerceActionWidget from "../../containers/Widgets/Ecommerce/EcommerceActionWidget";
import EcommerceStatsWidget from "../../containers/Widgets/Ecommerce/EcommerceStatsWidget";
import EcommerceLastWidget from "../../containers/Widgets/Ecommerce/EcommerceLastWidget";
import EcommerceGroupsWidget from "../../containers/Widgets/Ecommerce/EcommerceGroupsWidget";
import { EcommmercePurchaseWidget } from "../../containers/Widgets/Ecommerce/EcommercePurchaseWidget";
import { CircularProgress, useMediaQuery } from "@material-ui/core";
import { useDesktop } from "../../components/Responsive";

function Ecommerce({}: IEcommerce) {
  const dispatch = useAppDispatch();
  const company = useAppSelector((state) => state.company.company);
  const companyId = useAppSelector((state) => state.auth.companyId);
  const userId = useAppSelector((state) => state.auth.userId);

  const user = useAppSelector((state) => state.user.userData);
  const userLoading = useAppSelector((state) => state.user.loading);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchCompany({}));
    }
  }, [companyId]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUser({}));
    }
  }, [userId]);

  const tablet = useMediaQuery(
    "(max-width:1300px) and (orientation: landscape)"
  );

  const desktop = useDesktop();

  return userLoading || user == null ? (
    <CircularProgress size={36} />
  ) : user?.isECommerceModuleEnable ? (
    <ColLayout
      colCount={tablet ? 1 : 2}
      wrapperStyle={!desktop ? {} : { paddingTop: "10px", marginTop: "-10px" }}
      overrideCol={tablet ? "1fr" : "1fr 2fr"}
      cols={
        tablet
          ? [
              <>
                <EcommmercePurchaseWidget />
                <EcommerceGroupsWidget />
                <ColLayout
                  nested={true}
                  disableWidget={true}
                  colCount={2}
                  cols={[
                    <>
                      <EcommerceLastWidget />
                    </>,
                    <>
                      <EcommerceStatsWidget />
                      <EcommerceActionWidget />
                    </>,
                  ]}
                />
              </>,
            ]
          : [
              <>
                <EcommmercePurchaseWidget />
              </>,
              <>
                <EcommerceGroupsWidget />
                <ColLayout
                  nested={true}
                  disableWidget={true}
                  colCount={2}
                  cols={[
                    <>
                      <EcommerceLastWidget />
                    </>,
                    <>
                      <EcommerceStatsWidget />
                      <EcommerceActionWidget />
                    </>,
                  ]}
                />
              </>,
            ]
      }
    />
  ) : (
    <ColLayout
      wrapperStyle={!desktop ? {} : { paddingTop: "10px", marginTop: "-10px" }}
      colCount={3}
      cols={[
        <EcommerceActivateWidget />,
        // <EcommerceInfoWidget />,
        <FAQWidget defaultOpen={false} id={3} />,
      ]}
    />
  );
}

export interface IEcommerce {}

export default Ecommerce;

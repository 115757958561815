import * as React from "react";
import { Card } from "../../components/Card";
import { PurchaseStepper } from "../../components/Widgets/PurchaseStepper";
import styled from "styled-components";
import { useState } from "react";
import { PurchaseFirstStep } from "../../components/Widgets/PurchaseFirstStep";
import { PurchaseSecondStep } from "../../components/Widgets/PurchaseSecondStep";
import { useAppDispatch, useAppSelector } from "../../store";
import { setOrderModal } from "../../store/reducers/orderReducer";
import { useDesktop } from "../../components/Responsive";

const Wrapper = styled.div`
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
`;

const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  color: #e30613;
`;

export function PurchaseWidget({ modal }: any) {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useAppDispatch();
  const company = useAppSelector((state) => state.company.company);
  const groups = useAppSelector((state) => state.group.groups);
  const groupId = useAppSelector((state) => state.order.orderGroup);

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const closeModal = () => {
    dispatch(setOrderModal({ open: false }));
  };

  const desktop = useDesktop();

  const isModal = company?.companyBillingAddress && company?.companyBillingCity;

  return (
    <Card
      wrapperStyle={
        modal ? { marginBottom: "0", width: desktop ? "385px" : "100%" } : {}
      }
      HeaderProps={{
        variant: "filled",
        label: "Posadź drzewa i otrzymaj certyfikat",
        subLabel: modal && (
          <img
            onClick={closeModal}
            style={{ right: "10px", cursor: "pointer", position: "absolute" }}
            src={"/static/icon/cancel.svg"}
            alt={"x button"}
          />
        ),
        subLabelHeight: false,
        style: {},
      }}
    >
      {!isModal && <PurchaseStepper activeStep={activeStep} />}
      <Wrapper>
        {isModal && groupId && (
          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              marginTop: "-10px",
              padding: "0 40px",
            }}
          >
            <img
              style={{
                marginRight: "6px",
                minWidth: "15px",
                minHeight: "15px",
                height: "15px",
                width: "15px",
              }}
              src={"/static/icon/alert2.png"}
              alt={"alert icon"}
            />
            <Label>
              Dodajesz drzewa do sadzenia:{" "}
              <span>“{groups?.find((g) => g.id == groupId)?.name}”</span>
            </Label>
          </div>
        )}
        {activeStep === 0 ? (
          <PurchaseFirstStep modal={isModal} nextStep={nextStep} />
        ) : (
          <PurchaseSecondStep />
        )}
      </Wrapper>
    </Card>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API, { IResponse } from "../../API";
import { RootState } from "../index";

export interface ICompany {
  id: number;
  companyBillingName: string;
  companyBillingAddress: string;
  companyBillingZipCode: string;
  companyBillingCity: string;
  companyBillingVATNumber: string;
  companyContactName: string;
  companyContactEmail: string;
  companyContactPhone: string;
}

export const fetchCompany = API.getRequestThunk<IResponse<ICompany>>(
  "company/fetchCompany",
  "/user_company/billing/",
  (state: RootState) => state.auth.companyId || ""
);

export const updateCompany = API.updateRequestThunk<ICompany>(
  "company/updateCompany",
  "/user_company/billing/"
);

interface CompanyState {
  company: ICompany | null;
  loading: boolean;
  requested: boolean;
}

export const companySlice = createSlice({
  name: "company",
  initialState: {
    company: null,
    loading: false,
    requested: false,
  } as CompanyState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCompany.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateCompany.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchCompany.fulfilled, (state, action) => {
      state.loading = false;
      state.company = action.payload.data.item;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = companySlice.actions;

export default companySlice.reducer;

import * as React from "react";
import styled, { css } from "styled-components";
import { Button } from "../Common";
import { defaultButton } from "../Common/Button";

const Wrapper = styled.div<ICardHeaderStyle<boolean>>`
  height: ${(props) =>
    props.horizontal ? "100%" : props.subLabelHeight ? "90px" : "45px"};
  min-height: ${(props) =>
    props.horizontal ? "100%" : props.subLabelHeight ? "90px" : "45px"};
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: ${(props) => (props.horizontal ? "unset" : "center")};
  flex-direction: ${(props) => (props.horizontal ? "column" : "row")};
  border-radius: 5px 5px 0px 0px;

  ${(props) => {
    if (props.variant === "filled")
      return css`
        background-color: ${props.color};
        color: #ffffff;
        justify-content: ${props.center ? "center" : "space-between"};
        padding: ${props.center ? "0" : "0 26px"};
        padding-top: ${props.horizontal ? "10px" : "0"};
        ${props.horizontal
          ? css`
              border-radius: 5px 0px 0px 5px;
            `
          : ""};
        box-sizing: border-box;
      `;
    if (props.variant === "plain")
      return css`
        color: #1b607b;
        justify-content: ${props.centerLabel ? "center" : "unset"};
      `;
  }};

  @media (max-width: 1024px) {
    flex-direction: ${(props) =>
      props.variant === "filled" ? "column" : "row"};
  }
`;

const Icon = styled.img`
  margin-left: 10px;
  margin-right: 14px;
`;

const Divider = styled.div`
  border-bottom: 1px solid #ebf2f4;
  margin: 0px 10px;
`;

export function CardHeader({
  variant,
  color = "#1B607B",
  label,
  icon,
  center = true,
  action,
  style,
  horizontal,
  subLabel,
  subLabelHeight,
  centerLabel,
}: ICardHeader) {
  return (
    <>
      <Wrapper
        action={!!action}
        center={center}
        color={color}
        variant={variant}
        centerLabel={centerLabel}
        style={style}
        horizontal={horizontal}
        subLabelHeight={subLabelHeight}
      >
        {icon && <Icon src={`/static/icon/${icon}.svg`} alt={"icon " + icon} />}
        {label}
        {subLabel && subLabel}
        {typeof action === "object" && (
          <Button
            style={{ maxHeight: "33px", minHeight: "33px" }}
            onClick={action.invoke}
            styles={defaultButton}
            variant={"contained"}
          >
            {action.name}
          </Button>
        )}
      </Wrapper>
      {variant === "plain" && <Divider />}
    </>
  );
}

export interface Action<T> {
  action?: T;
}

export interface ICardHeaderStyle<T> extends Action<T | boolean> {
  variant: "filled" | "plain";
  color?: string;
  center?: boolean;
  horizontal?: boolean;
  subLabelHeight?: string | React.ReactNode;
  centerLabel?: boolean;
}

export interface ICardHeader
  extends ICardHeaderStyle<{ name: string; invoke: Function }> {
  label: string | React.ReactNode;
  subLabel?: string | React.ReactNode;
  icon?: string;
  style?: React.CSSProperties;
}

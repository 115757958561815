import React from "react";
import { PlainLayout } from "../../components/Layout";
import styled from "styled-components";
import { RegisterForm } from "../../containers/Forms/RegisterForm";
import { useAppDispatch } from "../../store";
import { registerUser } from "../../store/reducers/authReducer";
import { useMediaQuery } from "@material-ui/core";

export const Card = styled.div`
  background: #ffffff;
  display: flex;
  padding: 55px 76px 68px 69px;
  @media (max-width: 1024px) {
    padding: 55px 20px 68px 20px;
  }
`;

const DesktopFormWrapper = styled.div`
  border: 1px solid #d1dfe5;
  box-sizing: border-box;
  padding: 25px 70px 50px;
`;

const Label3 = styled.div`
  font-family: "Baloo 2", sans-serif;
  font-style: normal;
  font-size: 30px;
  line-height: 120%;
  font-weight: 600;
  max-width: 600px;
  /* or 36px */

  color: #1b607b;
`;

const Label4 = styled.div`
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  margin-top: 35px;
  color: #0b4560;
  max-width: 474px;
`;

const Ul = styled.ul`
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  max-width: 550px;
  color: #1b607b;
  margin-top: 20px;
  list-style-type: disc;
  margin-left: 16px;
`;

const Img = styled.img`
  margin-top: 20px;
`;

function Register({}: IRegister) {
    const dispatch = useAppDispatch();
    const onSubmit = (values: any) => {
        dispatch(registerUser({ ...values }));
    };

    const desktop = useMediaQuery(
        "(min-width:1300px) and (orientation: landscape)"
    );

    return (
        <PlainLayout>
            <Card>
                { desktop && (
                    <div
                        style={ {
                            marginRight: "90px",
                        } }
                    >
                        <Label3>
                            Zarejestruj się jako firma i korzystaj z benefitów jakie oferuje
                            nasz panel.
                        </Label3>
                        <Label4>Po rejestracji:</Label4>
                        <Ul>
                            <li>Łatwo i szybko dokonasz sadzenia drzewa</li>
                            <li>
                                Wygenerujesz spersonalizowany certyfikat (na przykład dla swoich
                                Klientów lub pracowników)
                            </li>
                            <li>
                                Wybierzesz odpowiedni dla siebie sposób płatności (wygenerować
                                proformę lub zapłacić szybkim przelewem)
                            </li>
                            <li>
                                Otrzymasz swoją stronę firmową i w łatwy sposób będziesz nią
                                zarządzać
                            </li>
                            <li>
                                Rejestracja w systemie jest całkowicie bezpłatna.
                                Sprawdź jak to wszystko wygląda i zdecyduj, jak chcesz sadzić drzewa dla swojej firmy.
                                W Posadzimy.pl płacisz tylko za sadzone przez siebie drzewa - nie ma tu żadnych ukrytych kosztów!
                                <br />
                                Masz pytania? Daj znać: <a href="mailto:kontakt@posadzimy.pl">kontakt@posadzimy.pl</a>
                            </li>
                        </Ul>
                        <Img alt={ "register" } src={ "/static/img/register.png" }/>
                    </div>
                ) }
                { desktop ? (
                    <DesktopFormWrapper>
                        <RegisterForm onSubmit={ onSubmit } />
                    </DesktopFormWrapper>
                ) : (
                    <RegisterForm onSubmit={ onSubmit } />
                ) }
            </Card>
        </PlainLayout>
    );
}

export interface IRegister {
}

export default Register;

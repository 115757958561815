import * as React from "react";
import { ColLayout } from "../../components/Layout/ColLayout";
import { Card } from "../../components/Card";
import { Alert, Button } from "../../components/Common";
import styled from "styled-components";
import { LogoForm } from "../../containers/Forms";
import { defaultButton, outlinedStyle } from "../../components/Common/Button";
import { CompanySiteForm } from "../../containers/Forms/CompanySiteForm";
import { useAppDispatch, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import { fetchCompany } from "../../store/reducers/companyReducer";
import {
  fetchPublic,
  IPublic,
  updatePublic,
} from "../../store/reducers/publicReducer";
import { useDesktop } from "../../components/Responsive";

const Wrapper = styled.div`
  padding: 20px 25px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

const ShardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 515px;
  padding: 0px 40px;
  border-left: 1px solid #ebf2f4;

  @media (max-width: 1024px) {
    border-left: unset;
    padding: 0 24px;
    box-sizing: border-box;
    min-width: unset;
    width: 100%;
  }
`;

const Img = styled.img`
  @media (max-width: 1400px) {
    width: 360px;
  }
  @media (max-width: 1024px) {
    width: 360px;
  }
`;

export function CompanySite({}) {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.auth.companyId);
  const publicCompany = useAppSelector((state) => state.public.public);
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchPublic({}));
    }
  }, [companyId]);

  const alert = `Uzupełnij informacje o swojej firmie by w pełni korzystać z jej możliwości i <span style="font-weight: 700">pochwal się swoimi posadzonymi drzewami.</span>
`;

  const logoLabel = `<span style="font-weight: 700">Logo</span> (widoczne jest na Twojej stronie firmowej, oraz na generowanych przez Ciebie certyfikatach. <span style="font-weight: 700">Jeśli nie dodasz logo, zastąpiony zostanie on nazwą firmy.</span>)`;

  const submit = (values: Partial<IPublic>) => {
    const update = { ...publicCompany, ...values };
    setLoading(true);
    if (publicCompany?.companyAvatar) {
      dispatch(
        updatePublic({ ...update, logo: publicCompany.companyAvatar.id } as any)
      ).then((r) =>
        dispatch(fetchPublic({})).then(() => {
          setLoading(false);
          setSaved(true);
        })
      );
    } else {
      delete update.companyAvatar;
      dispatch(updatePublic(update as any)).then((r) =>
        dispatch(fetchPublic({})).then(() => {
          setLoading(false);
          setSaved(true);
        })
      );
    }
  };

  const desktop = useDesktop();

  return (
    <ColLayout
      colCount={1}
      cols={[
        <Card
          full={true}
          wrapperStyle={
            desktop
              ? {
                  borderRadius: "5px 5px 0px 0px",
                }
              : {
                  height: "calc(100% + 50px)",
                }
          }
          HeaderProps={{
            variant: "filled",
            label: "Twoja firmowa wizytówka na posadzimy.pl",
            center: !desktop,
            style: {
              borderRadius: "0",
            },
          }}
        >
          {!publicCompany?.companyName && (
            <Alert
              iconStyle={{
                padding: "1px 18px",
              }}
              labelStyle={{ fontSize: "16px" }}
              color={"#1B607B"}
              label={alert}
              style={{
                marginBottom: "5px",
              }}
            />
          )}
          <Wrapper>
            <div
              style={{
                paddingRight: desktop ? "25px" : "0",
                maxWidth: "800px",
              }}
            >
              {(loading || saved) && (
                <Alert
                  loading={loading}
                  style={{
                    marginTop: "5px",
                    marginBottom: "10px",
                    maxWidth: desktop ? "280px" : "100%",
                  }}
                  color={"#62A30D"}
                  label={"Zmiany zostały zapisane poprawnie"}
                />
              )}
              <LogoForm setLoading={setLoading} label={logoLabel} />
              <div
                style={{
                  marginTop: "25px",
                  maxWidth: desktop ? "450px" : "100%",
                }}
              >
                <CompanySiteForm
                  key={JSON.stringify(publicCompany)}
                  initial={publicCompany}
                  onSubmit={submit}
                >
                  <Button
                    style={{
                      width: desktop ? "140px" : "100%",
                      maxHeight: "33px",
                      minHeight: "33px",
                      marginBottom: desktop ? "0" : "25px",
                    }}
                    styles={outlinedStyle}
                    variant={"outlined"}
                    type={"submit"}
                    disabled={loading}
                  >
                    ZAKTUALIZUJ
                  </Button>
                </CompanySiteForm>
              </div>
            </div>
            <ShardWrapper>
              <Img
                src={"/static/img/mockup_twoja_strona.png"}
                alt={"img mock up"}
              />
              {publicCompany?.companyWordpressURL && (
                <Button
                  component={"a"}
                  href={publicCompany?.companyWordpressURL}
                  style={{
                    marginTop: "35px",
                    width: desktop ? "245px" : "100%",
                    marginBottom: desktop ? "0" : "25px",
                  }}
                  styles={defaultButton}
                  variant={"contained"}
                  target={"_blank"}
                >
                  ZOBACZ SWOJĄ STRONĘ
                </Button>
              )}
            </ShardWrapper>
          </Wrapper>
        </Card>,
      ]}
    />
  );
}

import * as React from "react";
import styled, { css } from "styled-components";
import { Card } from "../../components/Card";
import { Button } from "../../components/Common";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  padding: 15px;

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Img = styled.img``;
const Label = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  margin-left: 10px;
  margin-top: 6px;
  color: #1b607b;
`;
const Bold = styled.span`
  font-weight: bold;
`;
const SideWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ButtonWrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    justify-content: flex-start;
    margin-top: 10px;
  }
`;

export function WebsiteWidget({}) {
  return (
    <Card
      HeaderProps={{
        variant: "plain",
        label: "Twoja strona www",
        icon: "www",
      }}
    >
      <Wrapper>
        <Img src={"/static/img/www_preview.png"} alt={"img www preview"} />
        <SideWrapper>
          <Label>
            <Bold>Uzupełnij dane na swojej stronie</Bold> by w pełni korzystać z
            jej możliwości
          </Label>
          <ButtonWrapper>
            <Button
              component={NavLink}
              to={"/panel/company"}
              styles={css`
                & .MuiButton-label {
                  font-weight: 500;
                  font-size: 14px;
                }
              `}
              color={"#33addc"}
            >
              UZUPEŁNIJ DANE
            </Button>
          </ButtonWrapper>
        </SideWrapper>
      </Wrapper>
    </Card>
  );
}
